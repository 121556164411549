import { ALL_DATA_LIMIT } from '@/api/constants'
import { useDomains } from '@/api/domains/get'
import CywarenessIcon from '@/assets/images/logo-clean-blue.svg'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'
import { SortOrder } from '@/types/common'
import { DomainQuerySort } from '@/types/domains'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faClapperboardPlay, faFishingRod, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Backdrop, SpeedDial, SpeedDialAction, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const INITIAL_QUERY_FILTERS = {
  sort_by: DomainQuerySort.domain,
  sort_order: SortOrder.asc,
  skip: 0,
  limit: ALL_DATA_LIMIT.limit,
}

export default function QuickActions() {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigateWithLayoutBlocker()
  const { data: domainsData } = useDomains(INITIAL_QUERY_FILTERS)
  const hasVerifiedDomains = domainsData?.results.filter((domain) => domain.verified).length
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const actions = [
    {
      icon: <FontAwesomeIcon icon={faUser as IconProp} fontSize={20} />,
      name: t('quickActions.addUser'),
      action: () => {
        handleClose()
        navigateTo('/recipients/members', { addUser: true })
      },
      disabled: false,
    },
    {
      icon: <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={20} />,
      name: t('quickActions.createCampaign'),
      action: () => {
        handleClose()
        navigateTo('/content-library/simulations')
      },
      disabled: !hasVerifiedDomains,
    },
    {
      icon: <FontAwesomeIcon icon={faClapperboardPlay as IconProp} fontSize={20} />,
      name: t('quickActions.createTrainingCampaign'),
      action: () => {
        handleClose()
        navigateTo('/content-library/videos')
      },
      disabled: !hasVerifiedDomains,
    },
  ]

  return (
    <div>
      <Backdrop className={classes.root} open={open} />
      <SpeedDial
        className={classes.speedDial}
        ariaLabel="SpeedDial tooltip example"
        icon={<img alt={'quick actions menu icon'} src={CywarenessIcon} height={30} width={30} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}>
        {actions
          .filter((action) => !action.disabled)
          .map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              classes={{
                staticTooltipLabel: classes.tooltipLabel,
                fab: classes.fab,
              }}
              tooltipTitle={<Typography noWrap>{action.name}</Typography>}
              tooltipOpen
              onClick={action.action}
              FabProps={{ size: 'medium' }}
            />
          ))}
      </SpeedDial>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100vh',
      transform: 'translateZ(0px)',
      flexGrow: 1,
      zIndex: 100,
    },
    speedDial: { position: 'absolute', bottom: 16, right: 16 },
    tooltipLabel: { color: theme.palette.black },
    fab: { color: theme.palette.black },
  })
)
