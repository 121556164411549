import { Box, Checkbox, Link, Paper, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { EducationModule } from '@/api/education-modules/modules'
import { useOffices } from '@/api/offices/get'
import { languages } from '@/assets/locales/languages'
import noOfficesAnimation from '@/assets/lottie/suspicion.json'
import useQueryParams from '@/common/hooks/useQueryParams'
import { theme } from '@/theme/theme'
import { Targets } from '@/types/campaigns'
import { RowDataItem, SortOrder } from '@/types/common'
import { Office, OfficeWorkingHours } from '@/types/offices'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@mui/system'
import { NavLink } from 'react-router-dom'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '../StatusFilter/StatusFilter'
import Table, { TalbeColumn } from '../Table'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'

type LaunchWizardOfficesTableProps = {
  handleAddOffices: (officeIds: string[], offices: Office[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  clientEnforceDomain: boolean
  selectedOffices: string[]
}

const INITIAL_QUERY_FILTERS = {
  status: ['active'],
  sort_by: 'name',
  sort_order: SortOrder.asc,
  skip: 0,
  limit: 10,
}

const LaunchWizardOfficesTable: FC<LaunchWizardOfficesTableProps> = ({
  handleAddOffices,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  selectedOffices,
  assets,
  clientEnforceDomain,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const { data: queryData, isFetching } = useOffices({
    ...queryParams,
    for_campaign_creation: true,
  })
  const { results: offices, total } = queryData || { total: 0, results: [] }

  const isPackage = assets && assets.length > 1

  const onlyAssetsWithPhone = assets?.every((a) =>
    a.vectors?.every((vector) => vector === 'sms' || vector === 'whatsapp')
  )

  const handleFilterChange = useCallback((value: string) => {
    updateQueryParam({ search: value })
  }, [])

  const handleStatusChange = useCallback((value: string[]) => {
    updateQueryParam({ status: value })
  }, [])

  //TODO move this to BE
  const isDisabled = (row: RowDataItem) => {
    return row.number_of_users === 0
  }

  const formatWorkingHours = useCallback(
    ({ days, opening, closing }: OfficeWorkingHours): string => {
      let daysString = ''
      const daysArray = Object.keys(days)
      let i
      for (i = 0; i < daysArray.length; i++) {
        if (days[daysArray[i]]) {
          if (days[daysArray[i]] && !daysString) {
            daysString = t(`createOfficeModal.days.${daysArray[i]}`)
          } else {
            if (daysString && days[daysArray[i - 1]]) {
              if (days[daysArray[i + 1]]) {
              } else {
                daysString = daysString + ` - ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            } else {
              if (daysString && !days[daysArray[i - 1]]) {
                daysString = daysString + `, ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            }
          }
        }
      }

      return daysString
    },
    [t]
  )

  const handleSelecctAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = offices.filter((o) => !isDisabled(o))
      handleAddOffices(
        newSelected.map((o) => o._id),
        newSelected
      )
    } else {
      handleRemoveFromList(offices.map((o) => o._id))
    }
  }

  const rowClick = (row: Office) => {
    if (isDisabled(row)) return

    if (selectedOffices.includes(row._id)) {
      handleRemoveFromList(row._id)
    } else {
      handleAddOffices([row._id], [{ ...(row as Office) }])
    }
  }

  const tableColumns = useMemo<TalbeColumn[]>(() => {
    const columns = [
      {
        id: 'custom_checkbox',
        label: '',
        headerComponent: () => {
          const isAnyMemberInactive = offices.some((office: Office) => office.has_inactive_member)
          const isAnyUnverifiedDomain = offices.some(
            (office: Office) => office.has_unverified_domain && clientEnforceDomain
          )

          return (
            <Tooltip
              title={t('launchWizard.officesTable.warnings.containsWarnings')}
              placement={'right'}
              disableHoverListener={!(isAnyMemberInactive || isAnyUnverifiedDomain)}>
              <Checkbox
                color="primary"
                checked={offices.every((g) => selectedOffices.includes(g._id))}
                onChange={handleSelecctAll}
              />
            </Tooltip>
          )
        },
        component: (row: Office) => (
          <Tooltip
            title={<TableBodyTooltip office={row} />}
            placement={'right'}
            disableHoverListener={!isDisabled(row)}>
            <Checkbox
              color="primary"
              checked={selectedOffices.includes(row._id)}
              inputProps={{
                'aria-labelledby': `table-checkbox-${row._id}`,
              }}
            />
          </Tooltip>
        ),
      },
      {
        id: 'name',
        label: t('officesTable.office'),
        sortable: true,
        component: (row: Office) => {
          const hasEmailSimulation = assets && assets.some((asset) => asset?.vectors?.includes('email'))

          row.warnings = {}

          if (row.has_inactive_member) {
            row.warnings.has_inactive_member = true
          }
          if (row.number_of_users === 0) {
            row.warnings.has_no_members = true
          }
          if (row.has_unverified_domain && (hasEmailSimulation || clientEnforceDomain)) {
            row.warnings.has_unverified_domain = true
          }
          if (row.has_member_with_missing_phone && onlyAssetsWithPhone) {
            row.warnings.has_missing_phone = true
          }

          return <TableCellNameWithWarnings row={row} />
        },
      },
      {
        id: 'number_of_users',
        label: t('officesTable.userCount'),
        sortable: true,
      },
      {
        id: 'language',
        label: t('officesTable.language'),
        sortable: true,
        component: (row: Office) => languages[row.language]?.name || '-',
      },
      {
        id: 'working_hours',
        label: t('officesTable.workingDays'),
        format: (row: Office) => (row.working_hours ? formatWorkingHours(row.working_hours) : '-'),
      },
      {
        id: 'awareness_score',
        label: t('officesTable.score'),
        sortable: true,
        component: (row: Office) => <HumanizedScore score={row.awareness_score} />,
      },
    ]

    if (isPackage) {
      columns.splice(3, 0, {
        id: 'distribution',
        label: t('officesTable.distribution'),
        sortable: false,
        component: (row) => (
          <DistributionSelect
            type="offices"
            onChange={handleDistributionTargetsChange}
            itemId={row._id}
            selectedItems={distributionTargets.offices}
            assets={assets}
            disabled={row.disabled}
            disableUnverifiedDomain={row.has_unverified_domain && clientEnforceDomain}
          />
        ),
      })
    }

    return columns
  }, [t, selectedOffices, offices])

  return (
    <>
      <div style={{ marginBottom: 1, display: 'flex' }}>
        <SimpleSearchBar onChange={handleFilterChange} />
        <StatusFilter onChange={handleStatusChange} values={queryParams.status} label="Status" />
      </div>
      <Paper sx={{ overflowX: 'scroll', 'scrollbar-width': 'thin' }}>
        <Table
          loading={isFetching}
          data={offices}
          total={total}
          inactiveRowCondition={isDisabled}
          customSelect
          queryParams={queryParams}
          updateQueryParam={updateQueryParam}
          columns={tableColumns}
          rowClick={rowClick}
          noResultsComponent={<NoResults />}
        />
      </Paper>
    </>
  )
}

const StyledFontAwesome = styled(FontAwesomeIcon)({
  color: theme.palette.orange[900],
  height: 18,
  display: 'inline-flex',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
})

const NoResults: FC = () => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation animation={noOfficesAnimation}>
      <p>{t('noResults.emptyOffice')}</p>
      <p>
        <Trans i18nKey={'noResults.goToOffice'} components={{ 1: <NavLink to={'/recipients/offices'} /> }} />
      </p>
    </InTableMessageWithAnimation>
  )
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

const TableCellNameWithWarnings: FC<{ row: Office }> = ({ row }) => {
  const warningLink = {
    has_inactive_member: '../../recipients/members',
    has_unverified_domain: '../../settings/allowlisting',
    has_no_members: '../../recipients/groups',
    has_missing_phone: '../../recipients/members',
  }

  const warningIcons = {
    has_missing_phone: byPrefixAndName.fak['light-mobile-circle-xmark'],
  }

  return (
    <Box display="flex" textAlign="left" alignItems="center">
      {row.name}

      {Object.keys(row?.warnings).map((key) => (
        <Tooltip
          title={
            <Trans
              i18nKey={`launchWizard.officesTable.warnings.${key}`}
              components={{
                1: (
                  <Link
                    href={warningLink[key]}
                    target="_blank"
                    style={{
                      textDecoration: 'underline #D1F6FF',
                      color: '#D1F6FF',
                    }}
                  />
                ),
              }}
            />
          }
          placement={'top'}>
          {warningIcons[key] ? (
            <StyledFontAwesome icon={byPrefixAndName.fak['light-mobile-circle-xmark'] as IconProp} />
          ) : (
            <StyledFontAwesome icon={faTriangleExclamation as IconProp} />
          )}
        </Tooltip>
      ))}
    </Box>
  )
}

// TODO Refactor This behaviour to be able to show more than one warning
const TableBodyTooltip: FC<{ office: Office }> = ({ office }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={
          office.has_inactive_member
            ? 'launchWizard.officesTable.warnings.has_inactive_member'
            : office.has_unverified_domain
            ? 'launchWizard.officesTable.warnings.has_unverified_domain'
            : office.number_of_users === 0
            ? 'launchWizard.officesTable.warnings.has_no_members'
            : ''
        }
        components={{
          1: (
            <Link
              href={
                office.has_inactive_member
                  ? '../../recipients/members'
                  : office.has_unverified_domain
                  ? '../../settings/allowlisting'
                  : office.number_of_users === 0
                  ? '../../recipients/offices'
                  : ''
              }
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default LaunchWizardOfficesTable
