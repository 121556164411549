import { MuiAlert, MuiAlertTitle } from './MuiAlert'
import { MuiButton } from './MuiButton'
import { MuiCheckbox } from './MuiCheckbox'
import { MuiChip } from './MuiChip'
import { MuiIconButton } from './MuiIconButton'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiPaper } from './MuiPaper'
import { MuiRadio } from './MuiRadio'
import { MuiTab, MuiTabs } from './MuiTab'
import { MuiTable, MuiTableCell, MuiTableRow } from './MuiTable'
import { MuiSwitch } from './MuiSwitch'
import { MuiCard } from './MuiCard'
// import { MuiTextField } from './MuiTextField'

export const components = {
  MuiPaper,
  MuiTable,
  MuiTableRow,
  MuiTableCell,
  MuiChip,
  MuiButton,
  MuiCheckbox,
  MuiTabs,
  MuiTab,
  MuiAlert,
  MuiAlertTitle,
  MuiRadio,
  MuiIconButton,
  MuiMenuItem,
  MuiSwitch,
  MuiCard,
  // MuiTextField, Dont import this coomponent befor migrating all the textfields to use custom TextField TextFieldV2
}
