import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DomainsTable from '@/common/components/DomainsTable'
import { theme } from '@/theme/theme'
const Domains = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontWeight={theme.typography.fontWeightSemiBold}>{t('settings.whitelisting.domains')}</Typography>
      <Typography>{t('settings.whitelisting.domainInstructions')}</Typography>
      <Alert severity="info" sx={{ padding: theme.spacing(1, 2, 0), marginBottom: theme.spacing(2) }}>
        <AlertTitle>{t('onboarding.domainVerification.info.title')}</AlertTitle>
        {t('onboarding.domainVerification.info.content')}
      </Alert>
      <DomainsTable />
    </Box>
  )
}

export default Domains
