import { theme } from '@/theme/theme'
import styled from '@emotion/styled'
import { Box, Grid, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

export const TwoColunmsLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledTwoColunmsLayout container spacing={4}>
    {children}
  </StyledTwoColunmsLayout>
)

// Note: Still considering what will be the best form componetys/building block
// curranly have wrapper Item with option (fullCol, and offsetLeft, offsetRight)
// Form buttons is king of eception, will need styled compoents

type ColumnItemPros = {
  fullWidth?: boolean
  offsetLeft?: boolean
  offsetRight?: boolean
  children: ReactNode
}

export const ColunmsItem: FC<ColumnItemPros> = ({
  fullWidth = false,
  offsetLeft = false,
  offsetRight = false,
  children,
}) => (
  <StyledColunmsItem item xs={fullWidth || offsetRight ? 12 : 6} offsetRight={offsetRight} offsetLeft={offsetLeft}>
    {children}
  </StyledColunmsItem>
)

const StyledTwoColunmsLayout = styled(Grid)(() => ({
  spacing: theme.spacing(4),
}))

const StyledColunmsItem = styled(Grid)(
  ({ offsetRight, offsetLeft }: { offsetRight?: boolean; offsetLeft?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: theme.spacing(2),
    marginRight: offsetRight ? '50%' : 0,
    marginLeft: offsetLeft ? '50%' : 0,

    // border: '1px solid orange',
  })
)

export const FormButtons = styled(Box)(() => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
}))

export const FormSection = styled(Typography)(() => ({
  fontWeight: theme.typography.fontWeightSemiBold,
}))
