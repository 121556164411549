import { Card, Grid } from '@mui/material'
import { useState } from 'react'

import { useOrganizationOnbordingStatus } from '@/api/organizations/getStatus'
import CardHeader from '@/common/components/Card/CardHeader'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import AllowlistingStep from './components/AllowlistingStep'
import DomainVerificationStep from './components/DomainVerificationStep'
import OnboardingProgressComponent from './components/OnboardingProgress'
import OrganizationDetailsStep from './components/OrganizationDetailsStep'
import RecipientMangementStep from './components/RecipientMangementStep'

type OpenStates = {
  1: boolean
  2: boolean
  3: boolean
  4: boolean
}
export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}
const NewOnboarding = () => {
  const { data: onbordingStatusData } = useOrganizationOnbordingStatus()

  const [openStates, setOpenStates] = useState<OpenStates>({ 1: true, 2: false, 3: false, 4: false })
  const toStep = (stepIndex?: keyof OpenStates) => {
    const state = { 1: false, 2: false, 3: false, 4: false }
    if (stepIndex) state[stepIndex] = true
    setOpenStates(state)
  }

  return (
    <LayoutPageContent>
      <PageHeader />
      <Grid item xs={12} xl={12}>
        <Card>
          <CardHeader title="Onboarding Process" />
          {/* need to fix it by defination progress bar */}
          <OnboardingProgressComponent onbordingStatusData={onbordingStatusData} openStates={openStates} />
        </Card>
      </Grid>

      <OrganizationDetailsStep
        isDone={onbordingStatusData?.organization_details_completed}
        open={openStates[1]}
        nextStep={() => toStep(2)}
        toggleOpen={() => setOpenStates((prev) => ({ ...prev, 1: !prev[1] }))}
      />

      <DomainVerificationStep
        isDone={onbordingStatusData?.domain_verification_completed}
        open={openStates[2]}
        nextStep={() => toStep(3)}
        prevStep={() => toStep(1)}
        toggleOpen={() => setOpenStates((prev) => ({ ...prev, 2: !prev[2] }))}
      />

      <AllowlistingStep
        isDone={onbordingStatusData?.allowlisting_completed}
        open={openStates[3]}
        nextStep={() => toStep(4)}
        prevStep={() => toStep(2)}
        toggleOpen={() => setOpenStates((prev) => ({ ...prev, 3: !prev[3] }))}
      />

      <RecipientMangementStep
        onboardingStatusData={onbordingStatusData}
        open={openStates[4]}
        prevStep={() => toStep(3)}
        toggleOpen={() => {
          setOpenStates((prev) => ({ ...prev, 4: !prev[4] }))
        }}
        closeSteps={() => toStep()}
      />
    </LayoutPageContent>
  )
}

export default NewOnboarding
