import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, styled } from '@mui/material'
import Modal from '@mui/material/Modal'
import { FC, ReactNode, useState } from 'react'
import Lottie from 'react-lottie'

import { theme } from '@/theme/theme'
import { ImageWrapper, StyledTypography } from '../AccountCenter/PopupsStyledComponents'

type CenteredPopupProps = {
  open: boolean
  onClose: () => void
  onConfirm?: (params?: any) => void
  buttonLabels?: { confirm?: string; cancel?: string }
  icon: string | any
  children: ReactNode[] | ReactNode
  disableBackdropClick?: boolean
  extraBottom?: ReactNode[] | ReactNode
}

const CenteredPopup: FC<CenteredPopupProps> = ({
  open,
  onClose,
  onConfirm,
  buttonLabels,
  icon,
  children,
  disableBackdropClick,
  extraBottom,
}) => {
  const [isPending, setIsPending] = useState(false)

  const handleClose = (_: React.SyntheticEvent, reason: 'backdropClick') => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    setIsPending(false)
    onClose()
  }

  const handleConfirm = async () => {
    setIsPending(true)
    try {
      if (onConfirm) await onConfirm()
    } catch (e) {
      setIsPending(false)
      throw e
    }
    setIsPending(false)
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <StyledCenteredModalConent>
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <ImageWrapper>
            {typeof icon === 'string' ? (
              <img src={icon} alt="invite" style={{ objectFit: 'cover' }} />
            ) : (
              <Lottie options={{ animationData: icon }} />
            )}
          </ImageWrapper>
          <Box>{children}</Box>
        </Box>
        {extraBottom && <StyledTypography>{extraBottom}</StyledTypography>}
        <Box display="flex" width={'100%'}>
          {!!buttonLabels?.cancel && (
            <Button variant="outlined" onClick={onClose}>
              {buttonLabels?.cancel}
            </Button>
          )}
          {Boolean(onConfirm) && (
            <LoadingButton variant="contained" onClick={handleConfirm} loading={isPending} fullWidth>
              {buttonLabels?.confirm}
            </LoadingButton>
          )}
        </Box>
      </StyledCenteredModalConent>
    </Modal>
  )
}

const StyledCenteredModalConent = styled(Box)(() => ({
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  minWidth: '512px',
  padding: theme.spacing(4, 8),
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
  gap: theme.spacing(4),
}))
export default CenteredPopup
