import { Box, styled, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

import { theme } from '@/theme/theme'

type CardHeaderParams = {
  title?: string
  children?: ReactNode[] | ReactNode
}

export const CardHeader: FC<CardHeaderParams> = ({ title, children }) => {
  if (title)
    return (
      <StyledSpaceBetweenBox>
        <Typography fontWeight={theme.typography.fontWeightSemiBold}> {title}</Typography>
        <Box display="flex" gap={2}>
          {children}
        </Box>
      </StyledSpaceBetweenBox>
    )
  if (Array.isArray(children) && children.length)
    return (
      <StyledSpaceBetweenBox>
        {children[0]}
        <Box display="flex" gap={2}>
          {children.slice(1)}
        </Box>
      </StyledSpaceBetweenBox>
    )

  return children
}

const StyledSpaceBetweenBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export default CardHeader
