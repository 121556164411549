import { Card, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../Breadcrumbs/Breadcrumbs'
import { FC, ReactNode } from 'react'

type PageHeaderParams = {
  children?: ReactNode[] | ReactNode
}

const PageHeader: FC<PageHeaderParams> = ({ children }) => {
  return (
    <Grid item xs={12} xl={12}>
      <Card sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 64 }}>
        <CustomBreadcrumbs />
        {children}
      </Card>
    </Grid>
  )
}

export default PageHeader
