import { SortOrder } from '@/types/common'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCaretUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { ChangeEvent, FC, MouseEvent } from 'react'

import { TalbeColumn } from '.'
import { theme } from '@/theme/theme'

type CustomTableHeadProps = {
  columns: TalbeColumn[]
  numSelected: number
  onRequestSort: (event: MouseEvent<unknown>, property: keyof any) => void
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void
  sortOrder?: SortOrder.asc | SortOrder.desc
  sortBy: string
  rowCount: number
  selectable?: boolean
  customSelect?: boolean
  disablePadding?: boolean
}

const CustomTableHead: FC<CustomTableHeadProps> = ({
  columns,
  onSelectAllClick,
  sortOrder,
  sortBy,
  numSelected,
  rowCount,
  onRequestSort,
  selectable,
  customSelect,
  disablePadding,
}) => {
  const handleSort = (property: string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {columns.map((col, index) => (
          <TableCell
            key={col.id}
            align={index === 0 || (index === 1 && customSelect) ? 'left' : 'center'}
            padding={!disablePadding ? 'normal' : 'none'}
            sortDirection={sortBy === col.id ? sortOrder : false}>
            {!col.sortable ? (
              <>{col.headerComponent ? col.headerComponent() : col.label}</>
            ) : (
              <TableSortLabel
                active={sortBy === col.id}
                direction={sortBy === col.id ? sortOrder : SortOrder.asc}
                onClick={handleSort(col.id)}
                IconComponent={(props) => (
                  <FontAwesomeIcon
                    icon={faCaretUp as IconProp}
                    style={{ position: 'absolute', right: theme.spacing(-2.5) }}
                    {...props}
                  />
                )}>
                {col.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default CustomTableHead
