import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore

import Allowlisting from '@/common/components/Allowlisting'
import { ColunmsItem, FormButtons } from '@/common/components/Form/TwoColumnsLayout'
import LayoutAccordion from '@/common/components/Layout/LayoutPageContent/LayoutAccordion'
import { theme } from '@/theme/theme'
import { StyledStepIconCircle } from './Styled'

type StepParams = {
  isDone?: boolean
  nextStep: () => void
  prevStep: () => void
  open: boolean
  toggleOpen: () => void
}

const AllowlistingStep: FC<StepParams> = ({ isDone, nextStep, prevStep, open, toggleOpen }) => {
  const { t } = useTranslation()

  const handleContinue = () => {
    isDone && nextStep()
  }

  return (
    <LayoutAccordion
      expanded={open}
      onClick={toggleOpen}
      title={t('onboarding.step3.title')}
      summaryBackGroundColor={isDone ? theme.palette.green[100] : ''}
      startIcon={
        <StyledStepIconCircle done={isDone}>{isDone ? <FontAwesomeIcon icon={faCheck} /> : 3}</StyledStepIconCircle>
      }>
      <Allowlisting />
      <ColunmsItem offsetLeft>
        <FormButtons>
          <Button variant="outlined" size="large" onClick={() => prevStep()}>
            {t('onboarding.step3.cancel')}
          </Button>
          <Button size="large" disabled={!isDone} variant="contained" onClick={handleContinue}>
            {t('onboarding.step3.submit')}
          </Button>
        </FormButtons>
      </ColunmsItem>
    </LayoutAccordion>
  )
}

export default AllowlistingStep
