import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'

const verifyDomainConfig = async (domainPayload: any) => {
  const url = `${API_URL}/domains/${domainPayload._id}/verify_sending_configurations`
  const { data } = await axios.post(url, domainPayload, { withCredentials: true })

  return data.data
}

export const useVerifyDomainConfig = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: verifyDomainConfig,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['domains'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['organizationOnbordingStatus'],
      })
    },
  })
}
