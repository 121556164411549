import { faGlobePointer, faIdCard, faMemo, faPeopleGroup } from '@fortawesome/pro-light-svg-icons'
import { Box, Link, Typography } from '@mui/material'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import StepsProgress from '@/common/components/StepsProgress'
import { theme } from '@/theme/theme'
import { OrganizationOnboardingStatus } from '@/types/organizations'

type OpenStates = {
  1: boolean
  2: boolean
  3: boolean
  4: boolean
}
type OnboardingProgressComponentParams = {
  onbordingStatusData?: OrganizationOnboardingStatus
  openStates: OpenStates
}

const OnboardingProgressComponent: FC<OnboardingProgressComponentParams> = ({ onbordingStatusData, openStates }) => {
  const { t } = useTranslation()
  const lastStateOpen = openStates[4]
    ? 4
    : openStates[3]
    ? 3
    : openStates[2]
    ? 2
    : openStates[1]
    ? 1
    : onbordingStatusData?.organization_details_completed &&
      onbordingStatusData?.domain_verification_completed &&
      onbordingStatusData?.allowlisting_completed &&
      onbordingStatusData?.recipients_completed.office_set_up &&
      onbordingStatusData?.recipients_completed.members_added
    ? 4
    : 0
  return (
    <Box>
      <Typography lineHeight={2}>
        <Trans
          i18nKey={'onboarding.OnboardingProcess.description'}
          components={{
            1: <br />,
            2: (
              <Link
                color={theme.palette.blue[900]}
                href={'https://catalog.cywareness.io/onboarding_guide'}
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
      <StepsProgress
        steps={[
          {
            title: t('onboarding.OnboardingProcess.organizationDetails'),
            icon: faIdCard,
            completed: onbordingStatusData?.organization_details_completed,
          },
          {
            title: t('onboarding.OnboardingProcess.domainVerification'),
            icon: faGlobePointer,
            completed: onbordingStatusData?.domain_verification_completed,
          },
          {
            title: t('onboarding.OnboardingProcess.allowlisting'),
            icon: faMemo,
            completed: onbordingStatusData?.allowlisting_completed,
          },
          {
            title: t('onboarding.OnboardingProcess.recipientManagement'),
            icon: faPeopleGroup,
            completed:
              onbordingStatusData?.recipients_completed.office_set_up &&
              onbordingStatusData?.recipients_completed.members_added,
          },
        ]}
        lastStateOpen={lastStateOpen}></StepsProgress>
    </Box>
  )
}

export default OnboardingProgressComponent
