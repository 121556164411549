import { Box, Paper, Typography, styled } from '@mui/material'
import { Parser as HtmlToReactParser } from 'html-to-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Sender } from '@/types/phishingSimulations'
import EmailPreview from '@/assets/images/DesktopPreview/email-frame.svg'

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: 'auto',
  height: '654px',
  width: '1218px',
  backgroundImage: `url(${EmailPreview})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(5),
  },
}))

const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  maxHeight: '425px',
  pointerEvents: 'none',
}))

const InboxBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#DDDDDD',
  borderRadius: 4,
  padding: theme.spacing(1),
  height: 22,
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
}))

const MainEmailContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(15, 5, 0, 10),
}))

const SenderName = styled(Box)(({ theme }) => ({
  margin: theme.spacing(-1, 0, 0, 5),
  display: 'flex',
  alignItems: 'center',
}))

const htmlToReactParser = new (HtmlToReactParser as any)()

interface EmailMessageProps {
  subject: string
  sender: Sender
  message: string
}

const EmailMessage: FC<EmailMessageProps> = ({ subject, sender, message }) => {
  const { t } = useTranslation()
  const env = import.meta.env.VITE_ENV
  const domain = env === 'development' || env === 'dev' ? t('feedbackPage.domainDev') : t('feedbackPage.domain')
  message = message.replaceAll('{{link_click_url}}', 'https://tinyurl.com/')
  //fix HTML style bleed out to the rest of the page.
  document.body.setAttribute('style', 'width:100%')
  return (
    <StyledPaper>
      <MainEmailContainer>
        <Box display="flex" alignItems="center" mb={2} maxWidth={'90%'}>
          <Typography gutterBottom variant="h5" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {subject}
          </Typography>
          <InboxBox>
            <Typography variant="caption" fontSize={12} color="primary">
              {t('feedbackPage.inbox')}
            </Typography>
          </InboxBox>
        </Box>
        <SenderName>
          <Box display="flex" flexDirection="column">
            <Typography id="sender" variant="subtitle2">
              <strong>{sender.name}</strong>
              <Typography ml={1} variant="caption" color="textSecondary">
                {`<${sender.mailbox}@${(!!sender.subdomain ? sender.subdomain + '.' : '') + domain}>`}
              </Typography>
            </Typography>
          </Box>
        </SenderName>
        <Box sx={{ overflowY: 'auto' }}>
          <MessageContainer>
            <Box bgcolor="#fff">{htmlToReactParser.parse(message)}</Box>
          </MessageContainer>
        </Box>
      </MainEmailContainer>
    </StyledPaper>
  )
}

export default EmailMessage
