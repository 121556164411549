import { useContext, useEffect, useState } from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Drawer, IconButton, List, Link } from '@mui/material'
import LogoIcon from '@/assets/images/logo-clean-blue.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  faChevronLeft,
  faChevronRight,
  faBookOpen,
  faRectanglesMixed,
  faBullhorn,
  faUserGroupSimple,
  faGear,
  faFlag,
  faShieldKeyhole,
  faHome,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Alert from '../Alert/Alert'
import MenuItem from './MenuItem'
import AlertPanel from '../Navbar/AlertPanel/AlertPanel'
import Dropdown from '../Navbar/Dropdown'
import { LayoutContext } from './Layout'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'

export default function Menu() {
  const classes = useStyles()
  const navigateTo = useNavigateWithLayoutBlocker()
  // const { data: apps } = useApps();
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [alertIsOpen, setAlertIsOpen] = useState(false)
  const { isMenuOpen, setIsMenuOpen, menuExpandEnabled, setWasMenuOpen } = useContext(LayoutContext)
  const [alertDrawerIsOpen, setAlertDrawerIsOpen] = useState(false)

  // handle sidebar open/close between sessions
  useEffect(() => {
    const storedValue = localStorage.getItem('cw_isSidebarOpen')
    if (storedValue) {
      const parsedStoredValue = JSON.parse(storedValue)
      setIsMenuOpen(parsedStoredValue)
      setWasMenuOpen(parsedStoredValue)
    }
  }, [])

  function openAlertDrawer() {
    setAlertDrawerIsOpen(true)
  }

  function closeAlertDrawer() {
    setAlertDrawerIsOpen(false)
  }
  const handleToggleSidebar = () => {
    const newState = !isMenuOpen
    setIsMenuOpen(newState)
    setWasMenuOpen(newState)
    localStorage.setItem('cw_isSidebarOpen', JSON.stringify(newState))
  }

  // function navigate({ url, external }: { url: string; external: boolean }) {
  //   external ? window.open(url, "_blank") : navigateTo(url);
  // }

  // function isCurrentPath({
  //   external,
  //   internalUrl,
  // }: {
  //   external: boolean;
  //   internalUrl: string;
  // }): boolean {
  //   return (
  //     !external &&
  //     pathname.includes(internalUrl.split("/").filter((v) => !!v)[0])
  //   );
  // }

  // function handleUpgrade() {
  //   setAlertIsOpen(true);
  // }

  function closeAlert() {
    setAlertIsOpen(false)
  }

  return (
    <>
      <Alert
        handleClose={closeAlert}
        open={alertIsOpen}
        dialogTitle={t('appCard.appDisabled')}
        dialogContent={
          <Trans i18nKey={'appCard.contactToUpgrade'}>
            To enable, please contact us at
            <Link className={classes.link} href={'mailto:hello@cywareness.io'}>
              hello@cywareness.io
            </Link>
            or via our contact page at
            <Link rel="noopener" href={'https://cywareness.io/contact-us/'} target="_blank" className={classes.link}>
              https://cywareness.io/contact-us/
            </Link>
          </Trans>
        }
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: `${classes.drawerPaper} ${!isMenuOpen && classes.drawerPaperClose}`,
        }}
        open={isMenuOpen}>
        <img className={classes.logo} alt={'Cywareness Logo'} src={LogoIcon} height={300} width={260} />
        <div className={classes.menuContent}>
          <div className={classes.menuPanelWrapper}>
            <div className={classes.actionItemsWrapper}>
              <List>
                {/* <MenuItem
                  onClick={() => navigateTo('/dashboard2')}
                  selected={pathname === '/dashboard2'}
                  text="Home Dashboard">
                  <FontAwesomeIcon icon={faHome as IconProp} />
                </MenuItem>
                <MenuItem
                  onClick={() => navigateTo('/dashboard2/dashboard3')}
                  selected={pathname === '/dashboard2/dashboard3'}
                  text="Home Onboarding">
                  <FontAwesomeIcon icon={faHome as IconProp} />
                </MenuItem> */}
                <MenuItem onClick={() => navigateTo('/')} selected={pathname === '/'} text={t('menu.dashboard')}>
                  <FontAwesomeIcon icon={faRectanglesMixed as IconProp} />
                </MenuItem>
                <MenuItem
                  onClick={() => navigateTo('/content-library')}
                  selected={pathname.startsWith('/content-library')}
                  text={t('menu.library')}>
                  <FontAwesomeIcon icon={faBookOpen as IconProp} />
                </MenuItem>
                <MenuItem
                  onClick={() => navigateTo('/campaigns')}
                  selected={pathname.startsWith('/campaign') || pathname.startsWith('/training-campaigns')}
                  text={t('menu.simulator')}>
                  <FontAwesomeIcon icon={faBullhorn as IconProp} />
                </MenuItem>
                <MenuItem
                  onClick={() => navigateTo('/response-console')}
                  selected={pathname.startsWith('/response-console')}
                  text={t('menu.reports')}>
                  <FontAwesomeIcon icon={faFlag as IconProp} />
                </MenuItem>
              </List>
            </div>
          </div>
          <List className={classes.bottomItemsWrapper}>
            <div>
              <MenuItem
                onClick={() => navigateTo('/recipients/members')}
                selected={pathname.includes('/recipients')}
                text={t('menu.users')}>
                <FontAwesomeIcon icon={faUserGroupSimple as IconProp} />
              </MenuItem>
              <MenuItem
                onClick={() => navigateTo('/client-activity')}
                selected={pathname === '/client-activity'}
                text={t('menu.clientActivity')}>
                <FontAwesomeIcon icon={faShieldKeyhole as IconProp} />
              </MenuItem>
              <MenuItem
                onClick={() => navigateTo('/settings')}
                selected={pathname.startsWith('/settings')}
                text={t('menu.settings')}>
                <FontAwesomeIcon icon={faGear as IconProp} />
              </MenuItem>
              <AlertPanel open={alertDrawerIsOpen} handleClose={closeAlertDrawer} handleOpen={openAlertDrawer} />
              <div className={classes.menuPanel}>
                <Dropdown />
              </div>
            </div>
          </List>
          <div>
            <IconButton
              disabled={!menuExpandEnabled}
              onClick={handleToggleSidebar}
              className={classes.closeMenuIcon}
              size="small">
              <FontAwesomeIcon icon={(isMenuOpen ? faChevronLeft : faChevronRight) as IconProp} />
            </IconButton>
          </div>
        </div>
      </Drawer>
    </>
  )
}

const DRAWER_WIDTH = 250
const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
      cursor: 'pointer',
    },
    logo: {
      marginRight: theme.spacing(2),
      position: 'absolute',
      bottom: -50,
      left: -50,
      opacity: 0.2,
    },
    chip: {
      transform: 'scale(0.75) translateX(40px) ',
    },
    title: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(3),
    },
    drawerPaper: {
      position: 'relative',
      height: '100vh',
      padding: theme.spacing(0, 0.25, 4),
      whiteSpace: 'nowrap',
      boxShadow: theme.shadows[0],
      background: theme.palette.blueGray[900],
      width: DRAWER_WIDTH,
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8),
      },
    },
    menuPanelWrapper: {
      width: '100%',
      flex: 1,
    },
    menuPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    menuContent: {
      marginTop: theme.spacing(1),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
      alignItems: 'flex-start',
    },
    actionItemsWrapper: {
      marginTop: 20,
    },
    bottomItemsWrapper: {
      width: '100%',
    },
    appIcon: {
      width: 24,
      height: 24,
      color: theme.palette.common.white,
    },
    selected: {
      color: theme.palette.common.white,
    },
    closeMenuIcon: {
      color: theme.palette.common.white,
      float: 'right',
      marginRight: theme.spacing(1),
    },
  })
)
