import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { useClientDetails } from '@/api/client/client'
import useIntegrations from '@/api/integrations/integrations'
import { NewOfficeParams, useNewOffice } from '@/api/offices/new-office'
// import completeAnimation from '@/assets/lottie/popup/copmlete.json'
import completeGif from '@/assets/images/popup/complete.gif'
import BulkUserUploadModal from '@/common/components/BulkUserUploadModal'
import CenteredPopup from '@/common/components/CenteredPopup'
import CreateGroupModal from '@/common/components/CreateGroupModal/CreateGroupModal'
import CreateOfficeModal from '@/common/components/CreateOfficeModal/CreateOfficeModal'
import CreateUserModal from '@/common/components/CreateUserModal/CreateUserModal'
import IntegrationModal from '@/common/components/Integrations/IntegrationModal'
import { getIntegrationTypes } from '@/common/components/Integrations/IntegrationsUtils'
import LayoutAccordion from '@/common/components/Layout/LayoutPageContent/LayoutAccordion'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { typography } from '@/theme/typography'
import { OrganizationOnboardingStatus } from '@/types/organizations'
import { StyledStepIconCircle, StyledStepSection } from './Styled'

type RecipientMangementStepParams = {
  onboardingStatusData?: OrganizationOnboardingStatus
  open: boolean
  toggleOpen: () => void
  prevStep: () => void
  closeSteps: () => void
}

const RecipientMangementStep: FC<RecipientMangementStepParams> = ({
  open,
  prevStep,
  toggleOpen,
  onboardingStatusData,
  closeSteps,
}) => {
  const { t } = useTranslation()
  const [officeModalIsOpen, setOfficeModalIsOpen] = useState(false)
  const [groupModalIsOpen, setGroupModalIsOpen] = useState(false)
  const [memberModalIsOpen, setMemberModalIsOpen] = useState(false)
  const [membersBulkModalIsOpen, setMembersBulkModalIsOpen] = useState(false)
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false)

  const [finishModalIsOpen, setFinishModalIsOpen] = useState(false)
  // const [isOnboardingDone, setIsOnboardingDone] = useState(false)
  const [createNewOffice] = useNewOffice()
  const { successToast, errorToast } = useToast()
  const { data: clientDetails, refetch: refetchClientData } = useClientDetails()
  const { data: integrations } = useIntegrations()

  const navigateTo = useNavigate()

  const onBoardingCompleted =
    onboardingStatusData?.organization_details_completed &&
    onboardingStatusData?.domain_verification_completed &&
    onboardingStatusData?.allowlisting_completed &&
    onboardingStatusData?.recipients_completed?.office_set_up &&
    onboardingStatusData?.recipients_completed?.members_added

  const isDone =
    onboardingStatusData?.recipients_completed.office_set_up && onboardingStatusData?.recipients_completed.members_added

  const handleComplete = () => {
    setFinishModalIsOpen(false)
    navigateTo('/content-library/videos')
    // setIsOnboardingDone(true)
    refetchClientData()
    closeSteps()
  }

  // TODO: re-Implement those modals (new groups, new office, new user) - with teh new modals and new form layout.
  // move into the modal like the groups one
  const handleCreateNewOffice = async (values: NewOfficeParams) => {
    try {
      await createNewOffice(values)
      successToast(t('users.newOfficeSuccess'))
    } catch (e) {
      const errorMessage =
        // @ts-ignore
        e.response.data.message || t('users.errors.faliedNewOffice')
      errorToast(errorMessage)
      throw e
    }
  }

  const newOfficeClick = () => setOfficeModalIsOpen(true)
  const newGroupClick = () => setGroupModalIsOpen(true)
  const newMemberClick = () => setMemberModalIsOpen(true)
  const uploadCsvClick = () => setMembersBulkModalIsOpen(true)
  const useIntegrationClick = () => setIntegrationModalOpen(true)

  return (
    <>
      <LayoutAccordion
        expanded={open}
        onClick={toggleOpen}
        title={t('onboarding.recipientManagement.title')}
        summaryBackGroundColor={isDone ? theme.palette.green[100] : ''}
        startIcon={
          <StyledStepIconCircle done={isDone}>{isDone ? <FontAwesomeIcon icon={faCheck} /> : 4}</StyledStepIconCircle>
        }>
        <Box>
          <StyledStepSection>
            <Typography fontWeight={theme.typography.fontWeightSemiBold}>
              {t('onboarding.recipientManagement.subTitle')}
            </Typography>
            <Typography>
              <Trans
                i18nKey={'onboarding.recipientManagement.link'}
                components={{
                  1: <Link to="/recipients/members" style={{ color: theme.palette.blue[900] }} target="_blank" />,
                }}
              />
            </Typography>
          </StyledStepSection>
          <StyledStepSection>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={theme.typography.fontWeightSemiBold}>
                {t('onboarding.recipientManagement.office.title')}
              </Typography>
              {onboardingStatusData?.recipients_completed?.office_set_up && <CompleteBadge />}
            </Box>
            <Typography>{t('onboarding.recipientManagement.office.description')}</Typography>
            <Box paddingTop={2}>
              <Button onClick={newOfficeClick} variant="outlined">
                {t('onboarding.recipientManagement.office.newOffice')}
              </Button>
            </Box>
          </StyledStepSection>
          <StyledStepSection>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={theme.typography.fontWeightSemiBold}>
                {t('onboarding.recipientManagement.members.title')}
              </Typography>
              {onboardingStatusData?.recipients_completed?.members_added && <CompleteBadge />}
            </Box>
            <Typography>{t('onboarding.recipientManagement.members.description')}</Typography>
            <Box display="flex" gap={2} paddingTop={2}>
              <Button
                disabled={!onboardingStatusData?.recipients_completed?.office_set_up}
                variant="outlined"
                onClick={newMemberClick}>
                {t('onboarding.recipientManagement.members.newMember')}
              </Button>
              <Button
                disabled={!onboardingStatusData?.recipients_completed?.office_set_up}
                variant="outlined"
                onClick={uploadCsvClick}>
                {t('onboarding.recipientManagement.members.uploadCsv')}
              </Button>
              <Button
                variant="outlined"
                disabled={!onboardingStatusData?.recipients_completed?.office_set_up}
                onClick={useIntegrationClick}>
                {t('onboarding.recipientManagement.members.useIntegration')}
              </Button>
            </Box>
          </StyledStepSection>
          <StyledStepSection>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={theme.typography.fontWeightSemiBold}>
                <Trans
                  i18nKey={'onboarding.recipientManagement.groups.title'}
                  components={{ 1: <span style={{ fontWeight: theme.typography.fontWeightRegular }} /> }}></Trans>
              </Typography>
              {onboardingStatusData?.recipients_completed?.groups_added && <CompleteBadge />}
            </Box>
            <Typography> {t('onboarding.recipientManagement.groups.description')}</Typography>
            <Box display="flex" gap={2} paddingTop={2}>
              <Button
                disabled={
                  !onboardingStatusData?.recipients_completed?.members_added ||
                  !onboardingStatusData?.recipients_completed?.office_set_up
                }
                variant="outlined"
                onClick={newGroupClick}>
                {t('onboarding.recipientManagement.groups.newGroup')}
              </Button>
            </Box>
          </StyledStepSection>
          <Box display="flex" gap={2} justifyContent="flex-end">
            <Button variant="outlined" size="large" onClick={prevStep}>
              {t('onboarding.recipientManagement.back')}
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={!onBoardingCompleted}
              onClick={() => {
                setFinishModalIsOpen(true)
                closeSteps()
              }}>
              {t('onboarding.recipientManagement.finishOnboarding')}
            </Button>
          </Box>
        </Box>
        <CreateOfficeModal
          saveOfficeEdit={() => {}}
          open={officeModalIsOpen}
          setOpen={setOfficeModalIsOpen}
          createNewOffice={handleCreateNewOffice}
          onClose={() => {}}
        />
        <CreateUserModal open={memberModalIsOpen} onClose={() => {}} setOpen={setMemberModalIsOpen} />
        <BulkUserUploadModal
          open={membersBulkModalIsOpen}
          onClose={() => {
            setMembersBulkModalIsOpen(false)
          }}
        />
        <IntegrationModal
          title={'New Integration'}
          isOpen={integrationModalOpen}
          onClose={() => setIntegrationModalOpen(false)}
          integrationTypes={getIntegrationTypes(clientDetails?.gws_enabled || false)}
          existIntegrationsTypes={integrations?.map((integration) => integration.type) || []}
        />
        <CreateGroupModal open={groupModalIsOpen} setOpen={setGroupModalIsOpen} />
        <CenteredPopup
          open={finishModalIsOpen}
          onClose={handleComplete}
          onConfirm={handleComplete}
          buttonLabels={{
            confirm: 'Launch First Campaign!',
          }}
          icon={completeGif}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Typography fontSize={22} fontWeight={typography.fontWeightSemiBold}>
              {t('onboarding.recipientManagement.onboardingComplete')}
            </Typography>
            <Typography>{t('onboarding.recipientManagement.launchCampaign')}</Typography>
          </Box>
        </CenteredPopup>
      </LayoutAccordion>
      {/* {isOnboardingDone && (
        <Grid item xs={12} xl={12}>
          <Box display="flex" gap={3} justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                navigateTo('/')
              }}>
              {t('onboarding.recipientManagement.goToDashboard')}
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                navigateTo('/content-library/videos')
              }}>
              {t('onboarding.recipientManagement.goToLibraries')}
            </Button>
          </Box>
        </Grid>
      )} */}
    </>
  )
}

const CompleteBadge = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <FontAwesomeIcon icon={faBadgeCheck} color={theme.palette.green[500]} />
      <Typography fontSize={14}>{t('onboarding.recipientManagement.complete')}</Typography>
    </Box>
  )
}

export default RecipientMangementStep
