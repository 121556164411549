import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBadgeCheck, faCircleExclamation, faSquareExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Formik } from 'formik'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import useCreateIntegration, { IntegrationData as CreateIntegrationData } from '@/api/integrations/create'
import { ldapIntegrationType } from '@/api/integrations/integrations'
import useTestConnection, { OnPremiseAuthenticationData } from '@/api/integrations/test-connection'
import useUpdateIntegration, { IntegrationData as UpdateIntegrationData } from '@/api/integrations/update'
import { useOffices } from '@/api/offices/get'
import AuthenticationIcon from '@/assets/images/Integrations/FormStages/Authentication'
import ConfigurationIcon from '@/assets/images/Integrations/FormStages/Configuration'
import Autocomplete from '@/common/components/Inputs/Autocomplete'
import Checkbox from '@/common/components/Inputs/Checkbox'
import TextField from '@/common/components/Inputs/TextField'
import Timepicker from '@/common/components/Inputs/Timepicker'
import Stepper from '@/common/components/Stepper'
import Step from '@/common/components/Stepper/Step'
import { theme } from '@/theme/theme'
import { Office } from '@/types/offices'
import { FormSection, IntegrationCardType, StyledButtonWrapper } from './IntegrationModal'
import { cronToDayjs } from './IntegrationsUtils'
import DepartmentMapper from './DepartmentMapper'
import InactiveUsersModal from './InactiveUsersModal'
import { Group } from '@/types/groups'

type OnPremiseIntegrationProps = {
  integration: ldapIntegrationType | undefined
  integrationTypes: IntegrationCardType[]
  onClose: () => void
}

interface IntegrationFormValues {
  type: string
  authentication: {
    host: string
    port: number
    useSsl: boolean
    user: string
    password: string
  }
  configuration: {
    schedule: Dayjs
    userImportStatus: { value: string } | undefined
    domain: string
    defaultUserOffice: Office | null
    usersDN: string
    departmentOfficeMapEnabled: boolean
    departmentOfficeMap: Record<string, Office>
    departmentGroupMapEnabled: boolean
    departmentGroupMap: Record<string, Group>
  }
}
const OnPremiseIntegration: FC<OnPremiseIntegrationProps> = ({ integration, onClose }) => {
  const { data: groupsData, isFetching: isFetchingGroups } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const { data: officesData, isFetching: isFetchingOffices } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const { t } = useTranslation()
  const [createIntegration] = useCreateIntegration()
  const [updateIntegration] = useUpdateIntegration()
  const [inactivePopupOpen, setInactivePopupOpen] = useState(false)

  const submitForm = async (values: IntegrationFormValues, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true)
    const integrationData = {
      ...values,
      name: 'AD OnPremise',
      configuration: {
        ...values.configuration,
        schedule: `${values.configuration.schedule.utc().format('m')} ${values.configuration.schedule.format(
          'H'
        )} * * *`,
        userImportStatus: values.configuration.userImportStatus?.value || 'active',
        defaultUserOffice: values.configuration.defaultUserOffice?._id,
        departmentGroupMap: Object.fromEntries(
          Object.entries(values.configuration.departmentGroupMap).map(([k, v]) => [k, v._id])
        ),
        departmentOfficeMap: Object.fromEntries(
          Object.entries(values.configuration.departmentOfficeMap).map(([k, v]) => [k, v._id])
        ),
      },
    }

    if (integration) {
      await updateIntegration({
        id: integration.id,
        integrationData: integrationData as UpdateIntegrationData,
      })
    } else {
      await createIntegration(integrationData as CreateIntegrationData)
    }
    setSubmitting(false)
    onClose()
  }

  const handleSave = async (values: IntegrationFormValues, { setSubmitting }) => {
    if (values.configuration.userImportStatus === 'inactive') {
      setInactivePopupOpen(true)
    } else {
      submitForm(values, setSubmitting)
    }
  }

  const officesById = useMemo(() => {
    return offices.reduce((acc, office) => {
      return { ...acc, [office._id]: office }
    }, {})
  }, [offices])

  const groupsById = useMemo(() => {
    return (groups || []).reduce((acc, group) => {
      return { ...acc, [group._id]: group }
    }, {})
  }, [groups])
  const [
    testIntegrationConnection,
    { isLoading: isTestingConnection, isError: isConnectionError, isSuccess: isConnectionSuccess },
  ] = useTestConnection()
  const handleConnectionTest = async (data: OnPremiseAuthenticationData) => {
    await testIntegrationConnection(data)
  }
  const initialFormState = {
    type: 'ldap',
    authentication: {
      host: integration?.authentication?.host || '',
      port: integration?.authentication?.port || 389,
      useSsl: integration?.authentication?.use_ssl || false,
      user: integration?.authentication?.user || '',
      password: '',
    },
    configuration: {
      schedule: cronToDayjs(integration?.configuration?.schedule),
      userImportStatus: integration?.configuration.user_import_status || 'active',
      domain: integration?.configuration?.domain || '',
      defaultUserOffice:
        offices.filter((office) => office._id === integration?.configuration.default_user_office)[0] || '',
      usersDN: integration?.configuration?.users_dn || '',
      departmentOfficeMapEnabled: Object.keys(integration?.configuration?.department_office_map || {}).length > 0,
      departmentOfficeMap: Object.entries(integration?.configuration?.department_office_map || {}).map(([k, v]) => ({
        key: k,
        value: officesById[v],
      })),
      departmentGroupMapEnabled: Object.keys(integration?.configuration?.department_group_map || {}).length > 0,
      departmentGroupMap: Object.entries(integration?.configuration?.department_group_map || {}).map(([k, v]) => ({
        key: k,
        value: groupsById[v],
      })),
    },
  }
  const validationSchema = Yup.object().shape({
    authentication: Yup.object().shape({
      host: Yup.string().required(t('settings.integrations.validations.hostIsRequired')),
      port: Yup.number().required(t('settings.integrations.validations.portIsRequired')),
      user: Yup.string().required(t('settings.integrations.validations.userNameIsRequired')),
      password: Yup.string().required(t('settings.integrations.validations.passwordIsRequired')),
    }),
    configuration: Yup.object().shape({
      domain: Yup.string().required(t('settings.integrations.validations.rootDomainIsRequired')),
      usersDN: Yup.string().required(t('settings.integrations.validations.usersDNIsRequired')),
      defaultUserOffice: Yup.string().nullable().required(t('settings.integrations.validations.defaultOfficeRequired')),
      userImportStatus: Yup.string().required(t('settings.integrations.validations.importAsRequired')),
      schedule: Yup.mixed()
        .nullable()
        .required(t('settings.integrations.validations.importAsRequired'))
        .test('is-valid-time', 'Time is not valid', (value: string) => {
          return dayjs.isDayjs(value) && value.isValid()
        }),
    }),
  })
  return (
    <Formik onSubmit={handleSave} initialValues={initialFormState} validationSchema={validationSchema}>
      {({ setFieldValue, isSubmitting, values, touched, setFieldTouched, errors, setSubmitting }) => (
        <Form noValidate>
          <InactiveUsersModal
            open={inactivePopupOpen}
            onClose={() => setInactivePopupOpen(false)}
            onConfirm={() => {
              setInactivePopupOpen(false)
              submitForm(values, setSubmitting)
            }}
          />
          <Stepper>
            <Step label={t('settings.integrations.authentication')} icon={AuthenticationIcon} active>
              <FormSection>
                <Box display="flex" gap={theme.spacing(2)}>
                  <TextField
                    sx={{ flexBasis: '80%', flexGrow: 1 }}
                    required
                    label="Host"
                    name="authentication.host"
                    onChange={() => {
                      setFieldTouched('authentication.host')
                    }}
                    placeholder="E.g. 132.41.13.3/ad.mycompany.com"
                  />

                  <TextField
                    sx={{ flexBasis: '20%', flexGrow: 0 }}
                    required
                    label="Port"
                    type="number"
                    name="authentication.port"
                    onChange={() => {
                      setFieldTouched('authentication.port')
                    }}
                    placeholder="E.g. 389"
                  />
                </Box>
                <Checkbox name="authentication.useSsl" label="SSL Enabled" />
                <TextField
                  required
                  label="Username"
                  name="authentication.user"
                  onChange={() => {
                    setFieldTouched('authentication.user')
                  }}
                  placeholder="E.g. CywarenessIntegration"
                />
                <TextField
                  required
                  type="password"
                  label="Password"
                  name="authentication.password"
                  onChange={() => {
                    setFieldTouched('authentication.password')
                  }}
                  placeholder="****************"
                />

                <Box display="flex" gap={theme.spacing(2)} alignItems="center">
                  <Button
                    disabled={isTestingConnection}
                    variant="outlined"
                    onClick={() =>
                      handleConnectionTest({
                        type: values.type,
                        authentication: values.authentication,
                      })
                    }>
                    {t('settings.integrations.verify')}
                  </Button>
                  <Box>
                    {isTestingConnection && (
                      <Typography>
                        <FontAwesomeIcon
                          icon={faCircleExclamation as IconProp}
                          color={theme.palette.blue[500]}
                          width={theme.spacing(3)}
                        />
                        {t('settings.integrations.authenticationInProgress')}
                      </Typography>
                    )}
                    {isConnectionError && (
                      <Typography>
                        <FontAwesomeIcon
                          icon={faSquareExclamation as IconProp}
                          color={theme.palette.red[900]}
                          width={theme.spacing(3)}
                        />
                        {t('settings.integrations.authenticationFailed')}
                      </Typography>
                    )}
                    {isConnectionSuccess && (
                      <Typography>
                        <FontAwesomeIcon
                          icon={faBadgeCheck as IconProp}
                          color={theme.palette.green[500]}
                          width={theme.spacing(3)}
                        />
                        {t('settings.integrations.authenticationVerified')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </FormSection>
            </Step>
            <Step label={t('settings.integrations.configurations')} icon={ConfigurationIcon} active>
              <FormSection>
                <TextField
                  required
                  label="Root Domain"
                  name="configuration.domain"
                  onChange={() => {
                    setFieldTouched('configuration.domain')
                  }}
                  placeholder="E.g. mycompany.com"
                />

                <Typography>{t('settings.integrations.syncOptions')}</Typography>

                <Checkbox disabled disableFormik checked={true} name="members" label="Members" />
                <TextField
                  required
                  label="Members DN"
                  name="configuration.usersDN"
                  onChange={() => {
                    setFieldTouched('configuration.usersDN')
                  }}
                  placeholder="E.g. cn=users name,dc=mycompany,dc=com"
                />
                <FormControl fullWidth>
                  <InputLabel id="import-as-select-label">{t('settings.integrations.importAs')}</InputLabel>
                  <StyledSelect
                    required
                    labelId="import-as-select-label"
                    value={values.configuration.userImportStatus}
                    label={t('settings.integrations.importAs')}
                    onChange={(e) => {
                      setFieldValue('configuration.userImportStatus', e.target.value)
                      setFieldTouched('configuration.userImportStatus')
                    }}>
                    <MenuItem key={0} value={'active'}>
                      {t('settings.integrations.active')}
                    </MenuItem>
                    <MenuItem key={1} value={'inactive'}>
                      {t('settings.integrations.inactive')}
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
                <Typography fontSize="12px" marginTop={theme.spacing(-1)}>
                  {t('settings.integrations.billed')}
                </Typography>
                <Autocomplete
                  label={t('settings.integrations.defaultOffice')}
                  placeholder={t('settings.integrations.defaultOffice')}
                  loading={isFetchingOffices}
                  name="configuration.defaultUserOffice"
                  onChange={() => {
                    setFieldTouched('configuration.defaultUserOffice')
                  }}
                  options={offices || []}
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option, value) => option?._id === value?._id}
                />
                <Typography fontSize="12px" marginTop={theme.spacing(-1)}>
                  *All users will be imported into the default office unless configured differently in the offices
                  section.
                </Typography>

                <Checkbox name="configuration.departmentGroupMapEnabled" label="Groups" />
                <Box hidden={!values.configuration.departmentGroupMapEnabled}>
                  <DepartmentMapper
                    keyLabel="Active Directory Department"
                    keyPlaceholder="Department name"
                    valueLabel="Group"
                    valuePlaceholder="Search Group"
                    loading={isFetchingGroups}
                    options={groups || []}
                    value={values.configuration.departmentGroupMap}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(value) => {
                      setFieldValue('configuration.departmentGroupMap', value)
                      setFieldTouched('configuration.departmentGroupMap')
                    }}
                  />
                </Box>
                <Checkbox name="configuration.departmentOfficeMapEnabled" label="Offices" />
                <Box hidden={!values.configuration.departmentOfficeMapEnabled}>
                  <DepartmentMapper
                    keyLabel="Active Directory Office"
                    keyPlaceholder="Office name"
                    valueLabel="Office"
                    valuePlaceholder="Search Office"
                    loading={isFetchingOffices}
                    options={offices || []}
                    value={values.configuration.departmentOfficeMap}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(value) => {
                      setFieldValue('configuration.departmentOfficeMap', value)
                      setFieldTouched('configuration.departmentOfficeMap')
                    }}
                  />
                </Box>
                <Box>
                  <Typography>{t('settings.integrations.sync')}</Typography>
                  <Box sx={{ display: 'flex', mt: '12px' }}>
                    <Timepicker
                      required
                      name="configuration.schedule"
                      label={t('settings.integrations.syncAt')}
                      onChange={() => {
                        setFieldTouched('configuration.schedule')
                      }}
                    />
                  </Box>
                </Box>
              </FormSection>
            </Step>
          </Stepper>
          <StyledButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting || (!integration && !isConnectionSuccess) || !Object.keys(touched).length}>
              {!!integration ? t('settings.integrations.update') : t('settings.integrations.save')}
            </Button>
          </StyledButtonWrapper>
        </Form>
      )}
    </Formik>
  )
}

const StyledSelect = styled(Select)(() => ({
  height: '36px',
  '.MuiSelect-select': {
    height: '36px',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
  },
}))
export default OnPremiseIntegration
