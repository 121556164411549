import { startCase } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState, useMemo, FC } from 'react'
import createStyles from '@mui/styles/createStyles'
import { Step, StepContent, StepLabel, Stepper, Tooltip, Typography, styled } from '@mui/material'
import { faEnvelopeOpen, faComputerMouse, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import Alert from '@/common/components/Alert/Alert'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import { numberWithCommas } from '@/common/utils/numberWithCommas'
import { CampaignStatEvents, CampaignEventsStats, CampaignUsersByEvent } from '@/common/types/campaign.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ShieldWithMagnifyingGlassIcon from './ShieldWithMagnifyingGlassIcon'
import DataBasePlusIcon from './DataBasePlusIcon'
interface EventsStepperProps {
  eventStats: CampaignEventsStats
  usersByEvent: CampaignUsersByEvent
}

type ModalState = {
  open: boolean
  eventType: CampaignStatEvents
}

const EventsStepper: FC<EventsStepperProps> = ({ eventStats, usersByEvent }) => {
  const initialModalState: ModalState = {
    open: false,
    eventType: 'email_open',
  }

  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const [modalState, setModalState] = useState<ModalState>(initialModalState)

  function CustomStepIcon(props) {
    const { active } = props
    const icons = {
      1: <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} />,
      2: <FontAwesomeIcon icon={faComputerMouse as IconProp} />,
      3: <DataBasePlusIcon size={20} color="#294D55" />,
      4: <ShieldWithMagnifyingGlassIcon size={20} color="#294D55" />,
    }
    return (
      <div className={`${classes.stepperIcon} ${active ? classes.stepperIconActive : ''}`}>
        {icons[String(props.icon)]}
      </div>
    )
  }

  function handleOpenModal(eventType: CampaignStatEvents) {
    if (!!eventStats[eventType]) {
      setModalState({ open: true, eventType })
    }
  }

  function handleCloseModal() {
    setModalState((prevState) => ({ ...prevState, open: false }))
  }

  const columns = useMemo(
    () => [
      {
        Header: startCase(t('campaignDashboard.name')),
        accessor: 'first_name',
        Cell: (data) => {
          const { first_name, last_name } = data.row.original
          return `${first_name} ${last_name}`
        },
        disableSortBy: true,
      },
      {
        Header: startCase(t('campaignDashboard.username')),
        accessor: 'username',
        disableSortBy: true,
      },
    ],
    [t]
  )

  function handleNavigateToUserProfile({ original }) {
    navigateTo(`/recipients/users/${original._id}`)
  }

  return (
    <>
      <Alert
        handleClose={handleCloseModal}
        open={modalState.open}
        dialogTitle={t(`campaignDashboard.modalTitles.${modalState.eventType}`)}
        dialogText={t(`campaignDashboard.modalTexts.${modalState.eventType}`, {
          count: usersByEvent[modalState.eventType].length,
          value: numberWithCommas(usersByEvent[modalState.eventType].length),
        })}
        closeOnClick={false}
        dialogContent={
          <SimpleTable
            stickyHeader
            enableCheckbox={false}
            columns={columns}
            data={usersByEvent[modalState.eventType]}
            setData={() => {}}
            noResultsTextId={'campaignDashboard.usersTable.noResults'}
            onRowClick={handleNavigateToUserProfile}
          />
        }
      />
      <Stepper nonLinear activeStep={2}>
        <Step
          active={eventStats.email_open.count > 0}
          onClick={() => handleOpenModal('email_open')}
          style={{
            cursor: eventStats.email_open.count > 0 ? 'pointer' : 'default',
          }}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <Typography className={classes.percentages}>{`${eventStats.email_open.percentage}%`}</Typography>
            <div className={classes.labelContainer}>
              {t('campaignDashboard.opened')}{' '}
              <Tooltip title={t('campaignDashboard.outlookTooltip') as string} placement={'right'}>
                <FontAwesomeIcon icon={faInfoCircle as IconProp} className={classes.tooltip} />
              </Tooltip>
            </div>
            <CustomStepContent>
              {t('campaignDashboard.openedInfo', {
                count: eventStats.email_open.count,
                value: numberWithCommas(eventStats.email_open.count),
              })}{' '}
            </CustomStepContent>
          </StepLabel>
        </Step>
        <Step
          active={eventStats.email_link_click.count > 0}
          onClick={() => handleOpenModal('email_link_click')}
          style={{
            cursor: eventStats.email_link_click.count > 0 ? 'pointer' : 'default',
          }}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <Typography className={classes.percentages}>{`${eventStats.email_link_click.percentage}%`}</Typography>
            <div className={classes.labelContainer}>
              {t('campaignDashboard.clicked')}
              <Tooltip title={t('campaignDashboard.clickTooltip') as string} placement={'right'}>
                <FontAwesomeIcon icon={faInfoCircle as IconProp} className={classes.tooltip} />
              </Tooltip>
            </div>
            <CustomStepContent>
              {t('campaignDashboard.clickedInfo', {
                count: eventStats.email_link_click.count,
                value: numberWithCommas(eventStats.email_link_click.count),
              })}
            </CustomStepContent>
          </StepLabel>
        </Step>
        <Step
          active={eventStats.submit.count > 0}
          onClick={() => handleOpenModal('submit')}
          style={{
            cursor: eventStats.submit.count > 0 ? 'pointer' : 'default',
          }}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <Typography className={classes.percentages}>{`${eventStats.submit.percentage}%`}</Typography>
            <div className={classes.labelContainer}>{t('campaignDashboard.insertedData')}</div>
            <CustomStepContent>
              {t('campaignDashboard.insertedDataInfo', {
                count: eventStats.submit.count,
                value: numberWithCommas(eventStats.submit.count),
              })}
            </CustomStepContent>
          </StepLabel>
        </Step>
        <Step
          active={eventStats.report.count > 0}
          onClick={() => handleOpenModal('report')}
          style={{
            cursor: eventStats.report.count > 0 ? 'pointer' : 'default',
          }}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <Typography className={classes.percentages}>{`${eventStats.report.percentage}%`}</Typography>
            <div className={classes.labelContainer}>{t('campaignDashboard.reported')}</div>
            <CustomStepContent>
              {t('campaignDashboard.reportedInfo', {
                count: eventStats.report.count,
                value: numberWithCommas(eventStats.report.count),
              })}
            </CustomStepContent>
          </StepLabel>
        </Step>
      </Stepper>
    </>
  )
}

const CustomStepContent = styled(StepContent)(() => ({
  fontSize: '14px',
  border: 'none',
  marginLeft: '0px',
  paddingLeft: '0px',
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    percentages: {
      fontSize: '18px',
      fontWeight: 'bolder',
    },
    stepperIcon: {
      zIndex: 1,
      color: '#294D55',
      width: 50,
      height: 50,
      display: 'flex',
      border: 'solid',
      borderWidth: '1px',
      borderRadius: '18px',
      borderColor: '#294D55',
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepperIconActive: {
      backgroundColor: '#adeff9',
      borderColor: '#dff9fd',
    },
    labelContainer: {
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
    },
    tooltip: {
      marginLeft: theme.spacing(1),
      display: 'flex',
    },
  })
)
export default EventsStepper
