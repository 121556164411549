import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip, TooltipProps, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'

import { theme } from '@/theme/theme'

interface InfoTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  title: string | JSX.Element
  fontSize?: number
  color?: string
}
const InfoTooltip: FC<InfoTooltipProps> = ({ title, fontSize, color, ...rest }) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <Typography
          sx={{
            margin: 0,
            fontSize: 12,
            paddingLeft: 0.6,
          }}>
          {title}
        </Typography>
      }
      {...rest}>
      <IconButton sx={{ width: '40px', height: '40px', margin: theme.spacing(-1, -1) }}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={faCircleInfo as IconProp}
          fontSize={fontSize ? fontSize : 16}
          style={{ color: color ? color : theme.palette.blueGray[900] }}
        />
      </IconButton>
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      padding: theme.spacing(0.2, 0.8),
      color: theme.palette.text.secondary,
    },
  })
)
export default InfoTooltip
