import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const verifyTXTRecord = async (domain_id: string) => {
  const url = `${API_URL}/domains/${domain_id}/verify_txt_record`
  const { data } = await axios.post(url, {}, { withCredentials: true })

  return data.data
}

export const useVerifyTXTRecord = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: verifyTXTRecord,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['domains'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['organizationOnbordingStatus'],
      })
    },
  })
}
