import { Box, styled } from '@mui/material'

import { theme } from '@/theme/theme'

const MainTabPage = styled(Box)(() => ({
  borderRadius: '0px 15px 15px 15px',
  position: 'relative',
  background: theme.palette.white,
}))

export default MainTabPage
