import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { OrganizationState } from '@/types/organizations'
import { Domain } from '@/types/domains'

//TODO: This
export type ClientDetailsResult = {
  _id: string
  name: string
  admin: string
  type: string
  default_language: string
  default_timezone: string
  campaigns_quota: number
  users_quota: number
  countries: string[]
  market_sectors: string[]
  velocity: 'weekly' | 'monthly' | 'quarterly'
  brands: string[]
  awareness_last_30_days: number[]
  domains: Domain[]
  whitelist: boolean
  industry: string
  baseline: boolean
  baseline_execution: number
  onboarding: boolean
  simulator_enabled: boolean
  ad_enabled: boolean
  mfa_enabled: boolean
  allow_unverified_domains: boolean
  offices: {
    _id: string
    name: string
    geo_location: string
    time_zone: string
    working_hours: string
    language: string
    client_id: string
  }[]
  logo?: string
  origin_country: string
  members: { active: number; total: number }
  parent_name?: string
  state?: OrganizationState
  gws_enabled?: boolean
}

export async function getClientDetails() {
  const url = `${API_URL}/organizations/details/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export function useClientDetails() {
  return useQuery<ClientDetailsResult, ServerError>('organizations', getClientDetails)
}
