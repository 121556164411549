import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import deleteImg from '@/assets/images/popup/delete.svg'
import Popup from '@/common/components/Popup'

type DeleteSimulationPopupParams = {
  open: boolean
  onClose: () => void
  onConfirm?: (params?: any) => void
  simulationName?: string
}
const DeleteSimulationPopup: FC<DeleteSimulationPopupParams> = ({ open, onClose, onConfirm, simulationName }) => {
  const { t } = useTranslation()

  return (
    <Popup
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      buttonLabels={{
        confirm: t('simulationPackage.deletePopup.confirmButton'),
        cancel: t('simulationPackage.deletePopup.cancelButton'),
      }}
      icon={deleteImg}>
      <h4>{t('simulationPackage.deletePopup.title')}</h4>
      <p>{t('simulation.deletePopup.message1', { simulationName: simulationName })}</p>
      <p>{t('simulation.deletePopup.message2', { simulationName: simulationName })}</p>
    </Popup>
  )
}
export default DeleteSimulationPopup
