// import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useQueryCache } from 'react-query'

import { UploadMemberConflictResolutionStrategy } from '@/types/members'
import { API_URL } from '../constants'

//TODO Move this to types file
export type UploadMembersRequest = {
  file: any
  dry_run: boolean
  conflict_resolution_strategy: UploadMemberConflictResolutionStrategy | null
  members_to_keep?: string[]
  members_to_overwrite?: string[]
}

const uploadMembers = async ({
  file,
  dry_run,
  conflict_resolution_strategy,
  members_to_keep,
  members_to_overwrite,
}: UploadMembersRequest) => {
  const url = `${API_URL}/members/bulk-upload/`
  const formData = new FormData()
  formData.append('file', file)
  formData.append('dry_run', dry_run.toString())

  if (conflict_resolution_strategy) {
    formData.append('conflict_resolution_strategy', conflict_resolution_strategy)
    members_to_keep?.forEach((memberToKeep) => {
      formData.append('members_to_keep', memberToKeep)
    })
    members_to_overwrite?.forEach((membersToOverwrite) => {
      formData.append('members_to_overwrite', membersToOverwrite)
    })
  }

  const { data } = await axios.post(url, formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

const useMembersUpload = () => {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: uploadMembers,
    onSuccess: () => {
      // ReactQuery v2 support
      queryCache.invalidateQueries('users')
      queryClient.invalidateQueries({ queryKey: ['members'] })
      queryClient.invalidateQueries({ queryKey: ['organizationOnbordingStatus'] })
    },
  })
}

export default useMembersUpload
