import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useEducationModuleById } from '@/api/education-modules/module-by-id'
import { EducationModule } from '@/api/education-modules/modules'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import VideoPlayerV2 from '@/common/components/VideoPlayerV2/VideoPlayerV2'
import { Box, Button, Card, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import VideoPageDetails from './VideoPageDetails'

export type VideoDetails = {
  video: EducationModule
}

export interface RouteParams {
  id: string
}

const VideoPage: FC = () => {
  const { id } = useParams<RouteParams>()
  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const { data: educationModule, isLoading, isError } = useEducationModuleById(id)
  const { t } = useTranslation()
  const handleLaunch = () => {
    // pause video before
    ;(document.getElementById('video-player') as HTMLVideoElement)?.pause()
    setLaunchWizardIsOpen(true)
  }

  if (!educationModule || isLoading || isError) {
    return null
  }

  return (
    <LayoutPageContent>
      <PageHeader>
        <Button onClick={handleLaunch} variant={'contained'}>
          {t('videoLibrary.launch')}
        </Button>
      </PageHeader>
      <Grid item xs={12}>
        <Card>
          <Box minHeight={300}>
            {educationModule && (
              <VideoPlayerV2
                subtitleTracks={educationModule.subtitle_tracks}
                videoTracks={educationModule.video_tracks}
              />
            )}
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <VideoPageDetails video={educationModule} />
        </Card>
      </Grid>
      {educationModule && (
        <LaunchWizard
          open={launchWizardIsOpen}
          onClose={() => setLaunchWizardIsOpen(false)}
          assets={[educationModule]}
        />
      )}
    </LayoutPageContent>
  )
}

export default VideoPage
