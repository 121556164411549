import { useMutation as useMutation5, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useMutation, useQueryCache } from 'react-query'
import { API_URL } from '../constants'

async function switchClient(client_id: string) {
  await axios.post(`${API_URL}/profile/change-client/`, { client_id }, { withCredentials: true })
}

export function useSwitchClient() {
  const queryCache = useQueryCache()
  return useMutation(switchClient, {
    onSuccess: async () => {
      await queryCache.invalidateQueries()
      queryCache.clear()
    },
  })
}

export const useSwitchClientV5 = () => {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()

  return useMutation5({
    mutationFn: switchClient,
    onSuccess: async () => {
      await queryCache.invalidateQueries()
      await queryClient.invalidateQueries({
        queryKey: ['organizations'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['admins'],
      })
    },
  })
}
