import { Alert, AlertTitle, Box, Button } from '@mui/material'
import Domains from './Domains'
import { theme } from '@/theme/theme'
import onboardingGuideBook from '@/assets/images/SettingsPage/onboarding-guide-book-image.png'
import Allowlisting from '@/common/components/Allowlisting'
import { useTranslation } from 'react-i18next'

export default function AllowlistingTab() {
  return (
    <Box padding={theme.spacing(2, 0)} display="flex" flexDirection="column" gap={4}>
      <GoToOnboardingEbook />
      <Domains />
      <Box>
        <Allowlisting />
      </Box>
    </Box>
  )
}

const GoToOnboardingEbook = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" gap={4}>
      <Box>
        <Alert severity="info" sx={{ padding: theme.spacing(1, 2, 0) }}>
          <AlertTitle>{t('settings.whitelisting.eBook.title')}</AlertTitle>
          {t('settings.whitelisting.eBook.description')}
        </Alert>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <img src={onboardingGuideBook}></img>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            window.open('https://catalog.cywareness.io/onboarding_guide', '_blank')
          }}>
          {t('settings.whitelisting.eBook.goToEbook')}
        </Button>
      </Box>
    </Box>
  )
}
