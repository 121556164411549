import LoadingButton from '@mui/lab/LoadingButton'
import { Box, styled, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { useOrganizations } from '@/api/organizations/get'
import { useSwitchClientV5 } from '@/api/profile/switch-client'
import anotherPlanetImg from '@/assets/images/admin-profile/another_planet.svg'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'

const SupportAdminSwitchAccount = () => {
  const { t } = useTranslation()
  const { data } = useOrganizations()

  // TODO: may be replace with user.mainOrganization enpoint which is not exist yet
  const mainOrganization = data?.filter((organization) => !organization.parent_id)[0]
  const mainOrganizationName = mainOrganization?.name
  const mainOrganizationId = mainOrganization?._id || ''
  const { successToast, errorToast } = useToast()

  const { mutateAsync: switchClient, isPending } = useSwitchClientV5()
  const handleSwitch = async () => {
    try {
      await switchClient(mainOrganizationId)
      successToast(t('settings.admins.profile.supportAdminSwitchAccount.successMessage'))
    } catch (e) {
      errorToast(t('settings.admins.profile.supportAdminSwitchAccount.errorMessage'))
    }
  }
  return (
    <Box display="flex" flexDirection="column" gap={4} alignItems="center" padding={2}>
      <StyledPattayaTypography>{t('settings.admins.profile.supportAdminSwitchAccount.title')}</StyledPattayaTypography>
      <img src={anotherPlanetImg} style={{ padding: theme.spacing(2) }} />
      <Typography fontWeight={theme.typography.fontWeightMedium} textAlign="center">
        <Trans
          i18nKey={'settings.admins.profile.supportAdminSwitchAccount.description'}
          components={{ 1: <br /> }}
          values={{ mainOrganizationName: mainOrganizationName || '' }}
        />
      </Typography>
      <LoadingButton variant="contained" onClick={handleSwitch} loading={isPending || !mainOrganizationId}>
        {t('settings.admins.profile.supportAdminSwitchAccount.switchAccount')}
      </LoadingButton>
    </Box>
  )
}

const StyledPattayaTypography = styled(Typography)(() => ({
  fontFamily: 'Pattaya',
  fontWeight: theme.typography.fontWeightRegular,
  background: `linear-gradient(${theme.palette.blueGray[200]}, ${theme.palette.blueDianne[900]} ,${theme.palette.grey[900]})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textAlign: 'center',
  fontSize: '32px',
}))

export default SupportAdminSwitchAccount
