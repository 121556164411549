import { Box, InputLabel, MenuItem, Select } from '@mui/material'
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals'
import { FieldHookConfig, useField } from 'formik'
import { ChangeEvent, FC } from 'react'
import { FlagImage } from 'react-international-phone'
import 'react-international-phone/style.css'

import { countriesArray } from '@/assets/countries'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HelperTextComponent from '../HelperTextComponent'
import { StyledFormControl, StyledOutlinedInput } from '../Select'

interface CountrySelectProps
  extends Omit<MuiTextFieldProps, 'name' | 'value' | 'error' | 'onChange' | 'onBlur'>,
    Pick<FieldHookConfig<string>, 'name'> {
  disableFormik?: boolean
  label: MuiTextFieldProps['label']
  value?: MuiTextFieldProps['value']
  onChange?: MuiTextFieldProps['onChange']
}

const CountrySelect: FC<CountrySelectProps> = ({
  name,
  label,
  InputProps,
  InputLabelProps,
  disableFormik = false,
  onChange,
  ...otherProps
}) => {
  const [field, meta] = useField(name)

  const configTextField: MuiTextFieldProps = {
    ...otherProps,
    fullWidth: true,
    label: label,
    placeholder: label as string,
    InputProps: {
      ...InputProps,
    },
  }

  if (!disableFormik) {
    Object.assign(configTextField, {
      ...field,
      className: disableFormik ? '' : 'validate',
      error: meta.touched && Boolean(meta.error),
      helperText: <HelperTextComponent hasError={meta.touched && Boolean(meta.error)} helperText={meta.error} />,
      onChange: onChange
        ? (event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event)
            onChange(event)
          }
        : field.onChange,
    })
  } else {
    if (onChange) configTextField.onChange = onChange
  }

  return (
    <StyledFormControl fullWidth>
      <InputLabel id={`select-${name}`}>{label}</InputLabel>
      <Select
        labelId={`select-${name}`}
        {...configTextField}
        IconComponent={(props) => (
          <Box {...props} padding={1} marginTop={-1.25}>
            <FontAwesomeIcon icon={faAngleRight} fontSize={18} />
          </Box>
        )}
        input={<StyledOutlinedInput label={label} />}>
        {countriesArray.map((country) => {
          return (
            <MenuItem value={country.code}>
              <FlagImage iso2={country.code.toLowerCase()} style={{ marginRight: '8px' }} />
              {country.name}
            </MenuItem>
          )
        })}
      </Select>
    </StyledFormControl>
  )
}

export default CountrySelect
