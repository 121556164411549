import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import deleteImg from '@/assets/images/popup/delete.svg'
import Popup from '@/common/components/Popup'

type DeletePackagePopupParams = {
  open: boolean
  onClose: () => void
  onConfirm?: (params?: any) => void
  packageName?: string
}
const DeletePackagePopup: FC<DeletePackagePopupParams> = ({ open, onClose, onConfirm, packageName }) => {
  const { t } = useTranslation()

  return (
    <Popup
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      buttonLabels={{
        confirm: t('simulationPackage.deletePopup.confirmButton'),
        cancel: t('simulationPackage.deletePopup.cancelButton'),
      }}
      icon={deleteImg}>
      <h4>{t('simulationPackage.deletePopup.title')}</h4>
      <p>{t('simulationPackage.deletePopup.message1', { simulationName: packageName })}</p>
      <p>{t('simulationPackage.deletePopup.message2', { simulationName: packageName })}</p>
    </Popup>
  )
}
export default DeletePackagePopup
