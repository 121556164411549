import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlass, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, InputBase, debounce, Typography, Grid, Card } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import { ChangeEvent, FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCampaignCancel } from '@/api/campaigns/cancel'
import { useCampaigns } from '@/api/campaigns/get'
import { US_DATE_TIME_WITH_MONTH_NAME } from '@/api/constants'
import { getErrorMessage } from '@/api/utils/get-error'
import question from '@/assets/images/popup/question.svg'
import EditModal, { allowStartChange } from '@/common/components/Campaign/EditModal'
import TimezoneTooltip from '@/common/components/InfoTooltip/TimezoneTooltip'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Popup from '@/common/components/Popup'
import Table, { TalbeColumn } from '@/common/components/Table'
import TableCellCampaignType from '@/common/components/Table/TableCellCampaignType'
import TagsFilter, { Tag } from '@/common/components/TagsFilter/TagsFilterNew'
import TruncatedText from '@/common/components/TruncatedText/TruncatedText'
import useQueryParams from '@/common/hooks/useQueryParams'
import useToast from '@/common/hooks/useToast'
import {
  AssetType,
  CampaignListItem,
  CampaignListQuery,
  CampaignQuerySort,
  CampaignScore,
  CampaignStatus,
} from '@/types/campaigns'
import { RowDataItem, SortOrder } from '@/types/common'
import { useNavigate } from 'react-router-dom'
import DateRangePicker from './CampaignDateRangePicker/CampaignDateRangePicker'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'

const INITIAL_QUERY_FILTERS: CampaignListQuery = {
  name: '',
  sort_by: CampaignQuerySort.start_date,
  sort_order: SortOrder.desc,
  start_date: '',
  end_date: '',
  score: [],
  status: [],
  asset_type: [],
  is_test: [],
  difficulty: [],
  skip: 0,
  limit: 10,
}

const tags: Tag[] = [
  { propKey: 'is_test', value: 'true', label: 'Test Campaign' },
  { propKey: 'is_test', value: 'false', label: 'Exclude Test Campaigns' },
  { propKey: 'asset_type', value: AssetType.training_video, label: 'Video Campaign' },
  { propKey: 'asset_type', value: AssetType.phishing_simulation, label: 'Simulation Campaign' },
  { propKey: 'difficulty', value: '1', label: 'Difficulty Lvl 1' },
  { propKey: 'difficulty', value: '2', label: 'Difficulty Lvl 2' },
  { propKey: 'difficulty', value: '3', label: 'Difficulty Lvl 3' },
  { propKey: 'difficulty', value: '4', label: 'Difficulty Lvl 4' },
  { propKey: 'difficulty', value: '5', label: 'Difficulty Lvl 5' },
  { propKey: 'score', value: CampaignScore.bad, label: 'Bad' },
  { propKey: 'score', value: CampaignScore.low, label: 'Low' },
  { propKey: 'score', value: CampaignScore.average, label: 'Average' },
  { propKey: 'score', value: CampaignScore.excellent, label: 'Excellent' },
  { propKey: 'status', value: CampaignStatus.in_progress, label: 'In Progress' },
  { propKey: 'status', value: CampaignStatus.processing, label: 'Launching' },
  { propKey: 'status', value: CampaignStatus.pending, label: 'Scheduled' },
  { propKey: 'status', value: CampaignStatus.completed, label: 'Completed' },
  { propKey: 'status', value: CampaignStatus.canceled, label: 'Canceled' },
]

const Campaigns: FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [campaignToModify, setCampaignToModify] = useState<RowDataItem>({} as RowDataItem)

  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const [nameDebounce, setNameDebounce] = useState(INITIAL_QUERY_FILTERS.name)

  const { data: campaigns, isFetching } = useCampaigns(queryParams as CampaignListQuery)

  const { results: campaignsData, total } = campaigns ?? { total: 0, results: [] }

  const { mutateAsync: campaignCancel } = useCampaignCancel()

  const { successToast, errorToast } = useToast()

  const tableColumns: TalbeColumn[] = [
    {
      id: 'name',
      label: t('adminDashboard.campaignsTable.campaignName'),
      sortable: true,
      component: (row: CampaignListItem) => {
        return (
          <StyledNowrapBox>
            {row.is_test ? (
              <TruncatedText text={row.name} maxLength={7} />
            ) : (
              <TruncatedText text={row.name} maxLength={14} />
            )}
            {row.is_test && (
              <span className={classes.testCampaignTicket}>{t('adminDashboard.campaignsTable.testCampaign')}</span>
            )}
          </StyledNowrapBox>
        )
      },
    },
    {
      id: 'users_count',
      label: t('adminDashboard.campaignsTable.totalUsers'),
      sortable: true,
    },
    {
      id: 'start_date',
      label: t('adminDashboard.campaignsTable.startDate'),
      sortable: true,
      component: (row: CampaignListItem) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {row.start_tz === 'per_user'
            ? dayjs.utc(row.start_date).format(US_DATE_TIME_WITH_MONTH_NAME)
            : dayjs.tz(row.start_date, row.start_tz).format(US_DATE_TIME_WITH_MONTH_NAME)}
          {row.start_tz === 'per_user' ? (
            <TimezoneTooltip title={t('adminDashboard.campaignsTable.campaignTimezone')} />
          ) : (
            <></>
          )}
        </Box>
      ),
    },
    {
      id: 'end_date',
      label: t('adminDashboard.campaignsTable.endingDate'),
      sortable: true,
      component: (row: CampaignListItem) => (
        <Box style={{ textAlign: 'center' }}>
          {row.end_tz === 'per_user'
            ? dayjs.utc(row.end_date).format(US_DATE_TIME_WITH_MONTH_NAME)
            : dayjs.tz(row.end_date, row.end_tz).local().format(US_DATE_TIME_WITH_MONTH_NAME)}
        </Box>
      ),
    },
    {
      id: 'type',
      label: t('adminDashboard.campaignsTable.campaignType'),
      component: TableCellCampaignType,
    },
    {
      id: 'score',
      label: t('adminDashboard.campaignsTable.score'),
      sortable: true,
      component: (row: CampaignListItem) => (row.score ? t('humanizedScore.' + row.score) : ''),
    },
    {
      id: 'status',
      label: t('adminDashboard.campaignsTable.status'),
      component: (row: CampaignListItem) => (
        <Box>
          {row.status_details ? (
            <Typography color={'inherit'}>{t('campaigns.statusDetails.' + row.status_details)}</Typography>
          ) : (
            <Typography color={'inherit'}>{t('trainingCampaignDashboard.' + row.status)}</Typography>
          )}
        </Box>
      ),
    },
    {
      id: 'actions',
      label: '',
      component: (row: CampaignListItem) => (
        <MoreMenu
          items={[
            {
              label: t('adminDashboard.campaignsTable.actions.cancel'),
              onClick: () => handleCampaignCancel(row),
              disabled: !allowStartChange(row.status as CampaignStatus, row.start_date),
            },
            {
              label: t('adminDashboard.campaignsTable.actions.edit'),
              onClick: () => handleEditCampaign(row),
            },
          ]}
          ariaLabel="table.cellActionsMenu"
        />
      ),
    },
  ]

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setNameDebounce(e.target.value)
    debounce(() => {
      updateQueryParam({ name: e.target.value })
    }, 1000)()
  }

  const handleRowClick = (row: RowDataItem) => {
    const { asset_type = '', _id = '' } = row

    if (!_id || !asset_type) {
      return
    }

    navigateToCampaign(row)
  }

  const navigateToCampaign = (row: RowDataItem) => {
    if (row.asset_type === AssetType.phishing_simulation) {
      navigateTo(`/campaign/${row._id}`)
    } else if (row.asset_type === AssetType.training_video) {
      navigateTo(`/training-campaigns/${row._id}`)
    }
  }

  const handleCampaignCancel = (row: RowDataItem) => {
    setCampaignToModify(row)
    setCancelModalOpen(true)
  }

  const handleCampaignCancelConfirm = async () => {
    setCancelModalOpen(false)
    if (!campaignToModify) return

    try {
      await campaignCancel(campaignToModify._id)
      navigateToCampaign(campaignToModify)

      successToast(t('adminDashboard.campaignsTable.cancelCampaignSuccess'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('adminDashboard.campaignsTable.cancelCampaignError'))
    }
  }

  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const handleEditCampaign = (row: RowDataItem) => {
    setLaunchWizardIsOpen(true)
    setCampaignToModify(row)
  }

  return (
    <LayoutPageContent>
      <PageHeader />
      <Grid item xs={12}>
        <Card>
          <Box display="flex">
            <InputBase
              fullWidth
              value={nameDebounce}
              style={{
                display: 'flex',
                flexGrow: 1,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0, 2),
                marginRight: theme.spacing(0),
              }}
              endAdornment={
                nameDebounce ? (
                  <IconButton
                    onClick={() => {
                      setNameDebounce('')
                      updateQueryParam({
                        name: '',
                      })
                    }}>
                    <FontAwesomeIcon icon={faX as IconProp} width={16} />
                  </IconButton>
                ) : (
                  <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} width={16} />
                )
              }
              placeholder={t('contentLibrary.search')}
              onChange={handleSearch}
            />
            <DateRangePicker queryParams={queryParams} updateQueryParam={updateQueryParam} />
          </Box>
          <TagsFilter tags={tags} queryParams={queryParams} updateQueryParam={updateQueryParam} />
          <Box overflow="auto" margin={theme.spacing(0, -4)}>
            <Table
              loading={isFetching}
              data={campaignsData}
              total={total}
              queryParams={queryParams}
              updateQueryParam={updateQueryParam}
              columns={tableColumns}
              rowClick={handleRowClick}
            />
          </Box>
        </Card>
      </Grid>
      <EditModal
        campaignId={campaignToModify._id}
        open={launchWizardIsOpen}
        onClose={() => setLaunchWizardIsOpen(false)}
      />
      <Popup
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onConfirm={() => handleCampaignCancelConfirm()}
        buttonLabels={{
          confirm: t('adminDashboard.campaignsTable.cancelCampaignPopup.confirm'),
          cancel: t('adminDashboard.campaignsTable.cancelCampaignPopup.cancel'),
        }}
        icon={question}>
        <h4>{t('adminDashboard.campaignsTable.cancelCampaignPopup.title')}</h4>
        <Trans
          i18nKey={'adminDashboard.campaignsTable.cancelCampaignPopup.content'}
          components={{
            1: <p />,
            2: <ol />,
            3: <li />,
          }}
        />
      </Popup>
    </LayoutPageContent>
  )
}

const StyledNowrapBox = styled(Box)(() => ({
  whiteSpace: 'nowrap',
}))

//TODO : To styled components:
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: theme.palette.cyan[500],
      borderRadius: '15px',
    },
    '& .MuiOutlinedInput-root': {
      width: '200px',
    },
  },
  tableWrapper: {
    marginTop: '18px',
    boxShadow: '-1px -0px 3px 0.1px lightgray',
    borderRadius: '15px',
  },
  divider: {
    marginBottom: '18px',
  },
  testCampaignTicket: {
    background: theme.palette.secondary.main,
    marginLeft: '10px',
    borderRadius: '3px',
    padding: '2px 6px',
    fontSize: '12px',
  },
}))

export default Campaigns
