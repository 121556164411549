import dayjs from 'dayjs'

import AdAzure from '@/assets/images/Integrations/Types/AdAzure.svg'
import GoogleWorkspaceIcon from '@/assets/images/Integrations/Types/AdGoogleWorkspace.svg'
import AdOnPremise from '@/assets/images/Integrations/Types/AdOnPremise.svg'

export const cronToDayjs = (cronString: string | undefined) => {
  if (typeof cronString === 'undefined') {
    return dayjs()
  }

  const [minute, hour] = cronString.split(' ')

  return dayjs.utc().hour(parseInt(hour)).minute(parseInt(minute)).second(0).local()
}

export const getIntegrationTypes = (isGoogleWorkspaceEnabled: boolean) => {
  return [
    ...[
      ...(isGoogleWorkspaceEnabled
        ? [
            {
              label: 'Google Workspace',
              value: 'google-workspace',
              icon: GoogleWorkspaceIcon,
            },
          ]
        : []),
    ],
    {
      label: 'Microsoft Entra ID',
      value: 'ms-graph',
      icon: AdAzure,
    },
    {
      label: 'AD OnPremise',
      value: 'ldap',
      icon: AdOnPremise,
    },
  ]
}
