import { PagedQuery } from './common'

export enum MessageDeliveryType {
  sendgrid = 'sendgrid',
  bridged_delivery = 'bridged_delivery',
}

export enum MailboxType {
  gmail = 'gmail',
  exchange_on_prem = 'exchange_on_prem',
  office_365 = 'office_365',
}

export type DomainDeliveryConfiguration = {
  message_delivery_type: MessageDeliveryType
  mailbox_type?: MailboxType
  mailbox_host?: string
  mailbox_port?: number
  default: boolean
  enabled: boolean
  spam_filters: DomainSpamFilters
  tls_required?: boolean
  verification_sent_at?: string
  verified: boolean
}

type DomainSpamFilters = {
  email_gateway?: boolean
  mail_flow_rules?: boolean
}

export type Domain = {
  _id: string
  client_id: string
  domain: string
  verified: boolean
  verify_token: string
  has_members: boolean
  created_at?: string
  message_delivery_configurations: DomainDeliveryConfiguration[]
}

export type DomainsQuery = {
  skip: number
  limit: number
  sort_by: string
  sort_order: string
}

export type DomainsResponse = {
  total: number
  results: Domain[]
}

export enum DomainQuerySort {
  domain = 'domain',
  verified = 'verified',
  created_at = 'created_at',
}

export type DomainListQuery = PagedQuery<DomainQuerySort>

export type DomainsRequest = {
  query: DomainsQuery
}
