import { FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material'
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup'
import { FieldHookConfig, useField } from 'formik'
import { ChangeEvent, FC, ReactNode } from 'react'

import { theme } from '@/theme/theme'
import HelperTextComponent from '../HelperTextComponent'

interface RadioGroupProps
  extends Omit<MuiRadioGroupProps, 'name' | 'value' | 'row' | 'error' | 'onChange'>,
    Pick<FieldHookConfig<string>, 'name'> {
  label?: string
  disableFormik?: boolean
  value?: MuiRadioGroupProps['value']
  options: { value: string; label: string | ReactNode }[]
  className?: string
  onChange?: MuiRadioGroupProps['onChange']
}

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  label,
  disableFormik = false,
  className,
  options = [],
  onChange,
  ...otherProps
}) => {
  const [field, meta] = useField(name)

  if (!disableFormik) {
    Object.assign(otherProps, {
      ...field,
      // className: disableFormik ? '' : 'validate',
      // error: meta.touched && Boolean(meta.error),
      onChange: onChange
        ? (event: ChangeEvent<HTMLInputElement>, value: any) => {
            field.onChange(event)
            onChange(event, value)
          }
        : field.onChange,
    })
  } else {
    Object.assign(otherProps, {
      onChange: onChange,
    })
  }

  return (
    <FormControl
      error={meta.touched && Boolean(meta.error)}
      variant="standard"
      sx={{ position: 'relative', paddingBottom: 2 }}>
      {label && (
        <FormLabel sx={{ color: theme.palette.blueDianne[900], fontWeight: theme.typography.fontWeightSemiBold }}>
          {label}
        </FormLabel>
      )}
      <MuiRadioGroup
        {...otherProps}
        value={field.value}
        onChange={(event, value) => {
          field.onChange(event)
          onChange && onChange(event, value)
        }}>
        {options.map((option, index) => (
          <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </MuiRadioGroup>
      <HelperTextComponent
        hasError={meta.touched && Boolean(meta.error)}
        helperText={meta.error}
        sx={{ position: 'absolute', bottom: 0 }}
      />
    </FormControl>
  )
}

export default RadioGroup
