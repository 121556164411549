import axios from 'axios'
import { API_URL } from '../constants'
import { ServerError } from '../types'
import { useMutation, useQueryCache } from 'react-query'
import { useQueryClient } from '@tanstack/react-query'

export type IntegrationData = {
  name: string
  type: string
  authentication: {
    host: string
    port: number
    useSsl: boolean
    user: string
    password: string
  }
  configuration: {
    schedule: string
    userImportStatus: string
    domain: string
    usersDN: string
    defaultUserOffice: string
    departmentOfficeMap: {
      [key: string]: string
    }
    departmentGroupMap: {
      [key: string]: string
    }
  }
}

export type MicrosoftEntraIdIntegrationData = {
  name: string
  type: string
  authentication: {
    tenantId: string
    clientSecret: string
    clientId: string
  }
  configuration: {
    schedule: string
    userImportStatus: string
    defaultUserOffice: string
    groupsMap: {
      [key: string]: string
    }
  }
}

export type GoogleIntegrationData = {
  name: string
  type: string
  authentication: {
    token: string
    refresh_token: string
  }
  configuration: {
    schedule: string
    userImportStatus: string
    defaultUserOffice: string
    groupsMap: {
      [key: string]: string
    }
  }
}

export async function createIntegration(
  integrationData: IntegrationData | GoogleIntegrationData | MicrosoftEntraIdIntegrationData
) {
  const url = `${API_URL}/integrations/`
  let params
  if (integrationData.type === 'ldap') {
    const ldapIntegrationData = integrationData as IntegrationData
    params = {
      name: ldapIntegrationData.name,
      type: ldapIntegrationData.type,
      authentication: {
        host: ldapIntegrationData.authentication.host,
        port: ldapIntegrationData.authentication.port,
        use_ssl: ldapIntegrationData.authentication.useSsl,
        user: ldapIntegrationData.authentication.user,
        password: ldapIntegrationData.authentication.password,
      },
      configuration: {
        schedule: ldapIntegrationData.configuration.schedule,
        user_import_status: ldapIntegrationData.configuration.userImportStatus,
        domain: ldapIntegrationData.configuration.domain,
        default_user_office: ldapIntegrationData.configuration.defaultUserOffice,
        users_dn: ldapIntegrationData.configuration.usersDN,
        department_office_map: ldapIntegrationData.configuration.departmentOfficeMap,
        department_group_map: ldapIntegrationData.configuration.departmentGroupMap,
      },
    }
  } else if (integrationData.type === 'google-workspace') {
    const googleIntegrationData = integrationData as GoogleIntegrationData
    params = {
      name: googleIntegrationData.name,
      type: googleIntegrationData.type,
      authentication: {
        token: googleIntegrationData.authentication.token,
        refresh_token: googleIntegrationData.authentication.refresh_token,
      },
      configuration: {
        schedule: googleIntegrationData.configuration.schedule,
        user_import_status: googleIntegrationData.configuration.userImportStatus,
        default_user_office: googleIntegrationData.configuration.defaultUserOffice,
        groups_map: googleIntegrationData.configuration.groupsMap,
      },
    }
  } else if (integrationData.type === 'ms-graph') {
    const azureIntegrationData = integrationData as MicrosoftEntraIdIntegrationData
    params = {
      name: azureIntegrationData.name,
      type: azureIntegrationData.type,
      authentication: {
        tenant_id: azureIntegrationData.authentication.tenantId,
        client_secret: azureIntegrationData.authentication.clientSecret,
        client_id: azureIntegrationData.authentication.clientId,
      },
      configuration: {
        schedule: azureIntegrationData.configuration.schedule,
        user_import_status: azureIntegrationData.configuration.userImportStatus,
        default_user_office: azureIntegrationData.configuration.defaultUserOffice,
        groups_map: azureIntegrationData.configuration.groupsMap,
      },
    }
  }

  const { data } = await axios.post(url, params, {
    withCredentials: true,
  })

  return data
}

export default function useCreateIntegration() {
  const queryCache = useQueryCache()
  const queryClient = useQueryClient()
  return useMutation<any, ServerError, IntegrationData | GoogleIntegrationData | MicrosoftEntraIdIntegrationData>(
    createIntegration,
    {
      throwOnError: true,
      onSettled: () => {
        queryCache.invalidateQueries('integrations')
        queryClient.invalidateQueries({ queryKey: ['organizationOnbordingStatus'] })
      },
    }
  )
}
