import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Box, Button, styled, Typography } from '@mui/material'
import { FC } from 'react'

import { theme } from '@/theme/theme'
import { max } from 'lodash'

type ButtonProps = {
  buttonText: string
  onClick: () => void
}

type InfoAlertProps = {
  severity?: 'info' | 'warning' | 'error' | 'success'
  title: string
  maxHeight?: string
  customButtons?: ButtonProps[]
  children: React.ReactNode
}

const InfoAlert: FC<InfoAlertProps> = ({ severity = 'info', title, maxHeight = '600px', customButtons, children }) => {
  return (
    <StyledAlert severity={severity} icon={false} sx={{ maxHeight: maxHeight }}>
      <StyledTitle>
        <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '32px', color: theme.palette[severity].main }} />
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
          {title}
        </Typography>
      </StyledTitle>
      <StyledBody>
        <Box>{children}</Box>
      </StyledBody>
      {customButtons && (
        <StyledButtons>
          {customButtons.map((button, index) => (
            <Button variant="outlined" color={severity} key={index} onClick={button.onClick}>
              {button.buttonText}
            </Button>
          ))}
        </StyledButtons>
      )}
    </StyledAlert>
  )
}

const StyledAlert = styled(Alert)(() => ({
  padding: theme.spacing(2, 3),

  '& .MuiAlert-message': {
    height: '100%',
    width: '100%',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

const StyledTitle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.white,
  borderRadius: '12px',
  padding: '16px 24px',
}))

const StyledBody = styled(Box)(() => ({
  flex: 1,
  backgroundColor: theme.palette.white,
  borderRadius: '12px',
  padding: '16px',
  paddingLeft: '24px',
  overflowY: 'hidden',

  '>div': {
    height: '100%',
    overflowY: 'auto',
    paddingRight: '8px',
    lineHeight: '1.5',
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      // backgroundColor: theme.palette.grey[400],
    },
  },
}))

const StyledButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
  backgroundColor: theme.palette.white,
  borderRadius: '12px',
  padding: '16px 24px',
}))

export default InfoAlert
