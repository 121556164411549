import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, AlertTitle, Button, Typography } from '@mui/material'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import DomainsTable from '@/common/components/DomainsTable'
import { ColunmsItem, FormButtons, TwoColunmsLayout } from '@/common/components/Form/TwoColumnsLayout'
import LayoutAccordion from '@/common/components/Layout/LayoutPageContent/LayoutAccordion'
import { theme } from '@/theme/theme'
import { StyledStepIconCircle } from './Styled'

type StepParams = {
  isDone?: boolean
  nextStep: () => void
  prevStep: () => void
  open: boolean
  toggleOpen: () => void
}

const DomainVerificationStep: FC<StepParams> = ({ isDone, nextStep, prevStep, open, toggleOpen }) => {
  const { t } = useTranslation()

  return (
    <LayoutAccordion
      expanded={open}
      onClick={toggleOpen}
      title={t('onboarding.domainVerification.title')}
      summaryBackGroundColor={isDone ? theme.palette.green[100] : ''}
      startIcon={
        <StyledStepIconCircle done={isDone}>{isDone ? <FontAwesomeIcon icon={faCheck} /> : 2}</StyledStepIconCircle>
      }>
      <TwoColunmsLayout>
        <ColunmsItem>
          <Typography>
            <Trans
              i18nKey={'onboarding.domainVerification.link'}
              components={{
                1: <Link to="/settings/allowlisting" style={{ color: theme.palette.blue[900] }} target="_blank" />,
              }}
            />
          </Typography>
          <Typography>{t('onboarding.domainVerification.Instructions1')}</Typography>
          <Typography>{t('onboarding.domainVerification.Instructions2')}</Typography>
        </ColunmsItem>
        <ColunmsItem>
          <Alert severity="info">
            <AlertTitle>{t('onboarding.domainVerification.info.title')}</AlertTitle>
            {t('onboarding.domainVerification.info.content')}
          </Alert>
        </ColunmsItem>
        <ColunmsItem fullWidth>
          <DomainsTable />
        </ColunmsItem>
        <ColunmsItem offsetLeft>
          <FormButtons>
            <Button variant="outlined" size="large" onClick={() => prevStep()}>
              {t('onboarding.domainVerification.actions.back')}
            </Button>
            <Button
              size="large"
              disabled={!isDone}
              variant="contained"
              onClick={() => {
                isDone && nextStep()
              }}>
              {t('onboarding.domainVerification.actions.continue')}
            </Button>
          </FormButtons>
        </ColunmsItem>
      </TwoColunmsLayout>
    </LayoutAccordion>
  )
}

export default DomainVerificationStep
