import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'

import { OrganizationOnboardingStatus } from '@/types/organizations'
import { API_URL } from '../constants'

const getOrganizationOnbordingStatus = async () => {
  const url = `${API_URL}/organizations/onboarding-status`
  const { data } = await Axios.get(url, { withCredentials: true })

  return data
}

//TODO Revemo stepComplete, only used for mock data
export const useOrganizationOnbordingStatus = () =>
  useQuery<OrganizationOnboardingStatus>({
    queryKey: ['organizationOnbordingStatus'],
    queryFn: getOrganizationOnbordingStatus,
  })
