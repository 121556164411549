import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { intersection } from 'lodash'
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ContentLibraryContext } from '../ContentLibrary'
import PackagesCaruselView from './PackagesCaruselView'
import SimulationLibraryCardView from './SimulationLibraryCardView'
import SimulationLibraryTable from './SimulationLibraryTable'

import { useCategories } from '@/api/categories/categories'
import { usePhishingSimulationDelete } from '@/api/phishing-simulations/delete'
import { usePhishingSimulations } from '@/api/phishing-simulations/get'
import { usePhishingSimulationPackageDelete } from '@/api/phishing-simulations/packages/delete'
import { usePhishingSimulationPackages } from '@/api/phishing-simulations/packages/get'
import { useToggleSavedContent } from '@/api/profile/toggle-saved-asset'
import { useToggleSavedAssetPackage } from '@/api/profile/toggle-saved-asset-package'
import { getErrorMessage } from '@/api/utils/get-error'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import { LayoutContext } from '@/common/components/Layout/Layout'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'
import useToast from '@/common/hooks/useToast'
import { useAuth } from '@/context/Auth'
import { AssetType } from '@/types/campaigns'
import {
  Languages,
  PhishingSimulation,
  PhishingSimulationPackage,
  PhishingSimulationPackageExtended,
  PhishingSimulationVector,
} from '@/types/phishingSimulations'
import DeletePackagePopup from './DeletePackagePopup'
import DeleteSimulationPopup from './DeleteSimulationPopup'

type LanguageObject = { [key: string]: string[] }

type SimulationsLibraryProps = {
  isTableView: boolean
  queryFilters?: {
    name?: string
    languages?: string[]
    tags?: string[]
    difficulties?: string[]
    vectors?: string[]
    ai_generated?: boolean
    custom?: boolean
    packages_only?: boolean
  }
}

type GroupedSimulation = {
  [name: string]: PhishingSimulation[]
}

const SimulationsLibrary: FC<SimulationsLibraryProps> = ({ queryFilters, isTableView }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: phishingSimulationPackagesData, isPending: isPhishingSimulationPackagesDataPending } =
    usePhishingSimulationPackages()
  const { data: phishingSimulationsData, isPending: isPhishingSimulationsDataPending } = usePhishingSimulations({
    limit: 3000000,
  }) // As a general rule BE doesn't allow limit = 0
  const { mutateAsync: deletePhishingSimulation } = usePhishingSimulationDelete()
  const { data: categoryData } = useCategories()
  const { user: { saved_assets = [], saved_asset_packages = [], current_client_id = '' } = {} } = useAuth()
  const navigateTo = useNavigateWithLayoutBlocker()
  const [saveAsset] = useToggleSavedContent()
  const { errorToast, successToast } = useToast()
  const [previewModule, setPreviewModule] = useState<PhishingSimulation | undefined>()
  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const { isMultiSimulationSideMenuOpen } = useContext(ContentLibraryContext)
  const [moduleForDeletion, setModuleForDeletion] = useState<PhishingSimulation | PhishingSimulationPackage | null>(
    null
  )
  const [deleteTemplateAlertIsOpen, setDeleteTemplateAlertIsOpen] = useState(false)
  const { setTempSimulationPackage, setTempSimulationPackageId, setTempSimulationPackageName } =
    useContext(LayoutContext)
  const { setIsMultiSimulationSideMenuOpen } = useContext(ContentLibraryContext)
  const [searchParams] = useSearchParams()
  const { mutateAsync: deleteSimulationPackage } = usePhishingSimulationPackageDelete()
  const { mutateAsync: toggleSavedAssetPackage } = useToggleSavedAssetPackage()

  const groupedSimulationsByCategory: GroupedSimulation = useMemo(() => {
    if (!categoryData) {
      return { All: phishingSimulationsData?.results || [] }
    }

    const groupedSimulations: GroupedSimulation = {}

    categoryData.categories.forEach((category) => {
      groupedSimulations[category.name] = []
    })

    phishingSimulationsData?.results.forEach((simulation) => {
      for (const tag of simulation.tags) {
        const categories = categoryData?.categories_by_tag[tag] || [{ name: 'Other' }]
        categories.forEach(({ name }) => {
          if (!groupedSimulations[name]) {
            groupedSimulations[name] = []
          }
          const existingSimulations = groupedSimulations[name].map((simulation) => simulation.id)
          if (!existingSimulations.includes(simulation.id)) {
            groupedSimulations[name].push(simulation)
          }
        })
      }
    })

    return groupedSimulations
  }, [categoryData, phishingSimulationsData])

  const matchDifficultyFilter = useCallback(
    (difficulties: number[]) => {
      return (
        !queryFilters?.difficulties?.length ||
        (queryFilters?.difficulties?.length &&
          queryFilters?.difficulties?.length > 0 &&
          queryFilters?.difficulties?.some((difficulty) => difficulties.includes(Number(difficulty))))
      )
    },
    [queryFilters]
  )

  const matchVectorFilter = useCallback(
    (vectors: string[]) => {
      return (
        !queryFilters?.vectors?.length ||
        (queryFilters?.vectors?.length &&
          queryFilters?.vectors?.length > 0 &&
          queryFilters?.vectors?.some((vector) => vectors.includes(vector)))
      )
    },
    [queryFilters]
  )

  const matchTagFilter = useCallback(
    (tags: string[]) => {
      return (
        !queryFilters?.tags?.length ||
        (queryFilters?.tags?.length &&
          queryFilters?.tags?.length > 0 &&
          intersection(tags, queryFilters.tags).length > 0)
      )
    },
    [queryFilters]
  )

  const matchTitleFilter = useCallback(
    (title: string) => {
      return !queryFilters?.name || title.toLowerCase().includes(queryFilters?.name.toLowerCase())
    },
    [queryFilters]
  )

  const matchCustomAiFilter = useCallback(
    (ai_generated: boolean, is_public: boolean) => {
      return !queryFilters?.ai_generated || (queryFilters?.ai_generated == ai_generated && !is_public)
    },
    [queryFilters]
  )

  const matchCustomFilter = useCallback(
    (ai_generated: boolean, organization_id: string, is_public: boolean) => {
      const isCustom = current_client_id == organization_id && !is_public && !ai_generated
      return !queryFilters?.custom || queryFilters?.custom == isCustom
    },
    [queryFilters, current_client_id]
  )

  const matchLanguageFilter = useCallback(
    (languages: string[]) => {
      return !queryFilters?.languages?.length || intersection(languages, queryFilters?.languages).length > 0
    },
    [queryFilters]
  )

  const filteredPhishingSimulations: GroupedSimulation = useMemo(() => {
    if (queryFilters?.packages_only) {
      return {}
    }
    const filteredPhishingSimulationsGroups = {} as any
    if (!isTableView) {
      Object.entries(groupedSimulationsByCategory).forEach(([topic, simulation]) => {
        const filteredSimulations = simulation.filter(
          (simulation) =>
            matchTitleFilter(simulation.name) &&
            matchLanguageFilter(simulation.languages.message) &&
            matchTagFilter(simulation.tags) &&
            matchDifficultyFilter(simulation.difficulties) &&
            matchVectorFilter(simulation.vectors) &&
            matchCustomAiFilter(simulation.ai_generated, simulation.is_public) &&
            matchCustomFilter(simulation.ai_generated, simulation.organization_id, simulation.is_public)
        )
        if (filteredSimulations.length > 0) {
          filteredPhishingSimulationsGroups[topic] = filteredSimulations
        }
      })
    } else {
      filteredPhishingSimulationsGroups['all'] = phishingSimulationsData?.results.filter(
        (simulation) =>
          matchTitleFilter(simulation.name) &&
          matchLanguageFilter(simulation.languages.message) &&
          matchTagFilter(simulation.tags) &&
          matchDifficultyFilter(simulation.difficulties) &&
          matchVectorFilter(simulation.vectors) &&
          matchCustomAiFilter(simulation.ai_generated, simulation.is_public) &&
          matchCustomFilter(simulation.ai_generated, simulation.organization_id, simulation.is_public)
      )
    }

    return filteredPhishingSimulationsGroups
  }, [
    isTableView,
    groupedSimulationsByCategory,
    matchTitleFilter,
    matchLanguageFilter,
    matchTagFilter,
    matchDifficultyFilter,
    matchVectorFilter,
    matchCustomAiFilter,
    matchCustomFilter,
    phishingSimulationsData,
  ])

  const filteredPhishingSimulationPackages = useMemo(() => {
    if (!phishingSimulationPackagesData) return []

    // TODO Refactor this...
    // phishingSimulationPackagesData PhishingSimulationPackage[] same variable should not be extended to different type
    // use map instead of foreEach to createn new variable from the new type to avoid ts errors

    phishingSimulationPackagesData.forEach((pack: PhishingSimulationPackageExtended) => {
      const languages: { landing_page: string[]; message: string[] }[] = []
      let difficulties: number[] = []
      let tags: string[] = []
      let vectors: PhishingSimulationVector[] = []
      pack.simulations.forEach((simulation: PhishingSimulation) => {
        languages.push(simulation.languages)
        difficulties.push(...simulation.difficulties)
        tags.push(...simulation.tags)
        vectors.push(...simulation.vectors)
      })
      difficulties = Array.from(new Set(difficulties))
      tags = Array.from(new Set(tags))
      const mergedObject: LanguageObject = languages.reduce((acc: LanguageObject, obj: LanguageObject) => {
        Object.keys(obj).forEach((key) => {
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key] = Array.from(new Set([...acc[key], ...obj[key]]))
        })
        return acc
      }, {})
      pack.languages = mergedObject as Languages
      pack.difficulties = difficulties.sort()
      pack.tags = tags
      pack.vectors = vectors
    })

    const filtered = phishingSimulationPackagesData.filter(
      (pack: PhishingSimulationPackageExtended & { languages: LanguageObject }) =>
        matchTitleFilter(pack.name) &&
        matchLanguageFilter(pack.languages.message) &&
        matchDifficultyFilter(pack.difficulties ?? []) &&
        matchTagFilter(pack.tags ?? []) &&
        matchVectorFilter(pack.vectors ?? []) &&
        matchCustomAiFilter(pack.ai_generated ?? false, pack.is_public) &&
        matchCustomFilter(pack.ai_generated ?? false, pack.organization_id ?? '', pack.is_public ?? false)
    )

    return filtered
  }, [
    isTableView,
    groupedSimulationsByCategory,
    matchTitleFilter,
    matchLanguageFilter,
    matchTagFilter,
    matchDifficultyFilter,
    matchVectorFilter,
    matchCustomAiFilter,
    matchCustomFilter,
    phishingSimulationPackagesData,
  ])

  const handlePackageSaveToggle = async (id: string) => {
    try {
      await toggleSavedAssetPackage(id)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
    }
  }

  const handlePackageEdit = (simulationPackage: PhishingSimulationPackage) => {
    setTempSimulationPackageId(simulationPackage.id)
    setTempSimulationPackageName(simulationPackage.name)
    setTempSimulationPackage([...simulationPackage.simulations])
    setIsMultiSimulationSideMenuOpen(true)
  }

  const handleSimulationPreview = (simulation: PhishingSimulation) => {
    navigateTo(`/content-library/simulations/${simulation.id}`)
  }
  const handleSimulationsPackagePreview = (simulationPackage: PhishingSimulationPackage) => {
    navigateTo(`/content-library/simulations-package/${simulationPackage.id}`)
  }

  const handleOpenLaunchWizard = (simulation: PhishingSimulation) => {
    setPreviewModule(simulation)
    setLaunchWizardIsOpen(true)
  }

  const openDeleteTemplateAlert = (simulation: PhishingSimulation) => {
    setModuleForDeletion(simulation)
    setDeleteTemplateAlertIsOpen(true)
  }

  const closeDeleteTemplateAlert = () => {
    setModuleForDeletion(null)
    setDeleteTemplateAlertIsOpen(false)
  }

  const handlePackageDelete = async () => {
    if (!moduleForDeletion) return
    try {
      await deleteSimulationPackage(moduleForDeletion.id)
      successToast(t('simulationPackage.deleteSuccess'))
      closeDeleteTemplateAlert()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
    }
  }

  const handleSimulationDelete = async () => {
    if (!moduleForDeletion) return
    try {
      await deletePhishingSimulation(moduleForDeletion.id)
      successToast(t('simulation.toast.deleted'))
      closeDeleteTemplateAlert()
    } catch (error) {
      errorToast(t('simulation.toast.failedToDelete'))
    }
  }

  const handleLaunchModule = async (simulation: PhishingSimulation) => {
    handleOpenLaunchWizard(simulation)
  }

  const handleSaveModule = async (id: string) => {
    try {
      await saveAsset(id)
    } catch (error) {
      errorToast(t('simulationLibrary.errors.failedToSave'))
    }
  }
  const editPackage = searchParams.get('editPackage')

  useEffect(() => {
    if (editPackage) {
      const packageFound = phishingSimulationPackagesData?.find(
        (simulationPackage) => simulationPackage.id === editPackage
      )

      if (packageFound) {
        handlePackageEdit({ ...packageFound })
      }
    }
  }, [editPackage, phishingSimulationPackagesData])

  return (
    <div className={classes.root}>
      {previewModule && (
        <LaunchWizard
          campaignType={AssetType.phishing_simulation}
          open={launchWizardIsOpen}
          onClose={() => setLaunchWizardIsOpen(false)}
          assets={[previewModule]}
        />
      )}
      {!!(moduleForDeletion as PhishingSimulationPackage)?.simulations ? (
        <DeletePackagePopup
          open={deleteTemplateAlertIsOpen}
          onClose={closeDeleteTemplateAlert}
          onConfirm={handlePackageDelete}
          packageName={moduleForDeletion?.name}
        />
      ) : (
        <DeleteSimulationPopup
          open={deleteTemplateAlertIsOpen}
          onClose={closeDeleteTemplateAlert}
          onConfirm={handleSimulationDelete}
          simulationName={moduleForDeletion?.name}
        />
      )}

      {isPhishingSimulationsDataPending || isPhishingSimulationPackagesDataPending ? (
        <LoadingContainer />
      ) : Object.values(filteredPhishingSimulations).length + filteredPhishingSimulationPackages.length ? (
        isTableView ? (
          <SimulationLibraryTable
            deleteModule={openDeleteTemplateAlert}
            launchModule={handleLaunchModule}
            saveModule={handleSaveModule}
            saveSimulationPackageToggle={handlePackageSaveToggle}
            editSimulationPackage={handlePackageEdit}
            savedSimulations={saved_assets}
            savedPackages={saved_asset_packages}
            queryFilters={queryFilters}
          />
        ) : (
          <>
            {filteredPhishingSimulationPackages.length > 0 && !isMultiSimulationSideMenuOpen && (
              <PackagesCaruselView
                showPreview={handleSimulationsPackagePreview}
                packages={filteredPhishingSimulationPackages}
                savedSimulationPackages={saved_asset_packages}
                saveSimulationPackageToggle={handlePackageSaveToggle}
              />
            )}
            <SimulationLibraryCardView
              showPreview={handleSimulationPreview}
              deleteModule={openDeleteTemplateAlert}
              launchModule={handleLaunchModule}
              saveModule={handleSaveModule}
              simulationsByTopic={filteredPhishingSimulations}
              savedSimulations={saved_assets}
            />
          </>
        )
      ) : (
        <NoResultsContainer text={t('simulationLibrary.noResults')} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      paddingBottom: theme.spacing(10),
    },
    bannerContainer: {
      marginTop: theme.spacing(2),
    },
  })
)

export default SimulationsLibrary
