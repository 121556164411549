import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const domainCreate = async (domain: string) => {
  const url = `${API_URL}/domains/`
  const { data } = await axios.post(url, { domain: domain }, { withCredentials: true })

  return data.data
}

export const useDomainCreate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: domainCreate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['domains'],
      })
    },
  })
}
