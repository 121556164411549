import { useDraggable } from '@dnd-kit/core'
import styled from '@emotion/styled'
import { Box, Button, Checkbox, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { ContentLibraryContext } from '@/pages/ContentLibrary/ContentLibrary'
import { theme } from '@/theme/theme'
import { RowDataItem, SortOrder } from '@/types/common'
import { PhishingSimulationAndPackages } from '@/types/phishingSimulations'
import CustomTableFooter from './TableFooter'
import CustomTableHead from './TableHead'
import TableNoResults from './TableNoResults'
import TableSceletonLoader from './TableSceletonLoader'

export type TalbeColumn = {
  id: string
  label: string | ReactElement
  headerComponent?: () => ReactNode
  sortable?: boolean
  format?: (row: any) => string
  component?: (row: any) => ReactNode
}

type CustomTablePros = {
  loading: boolean
  data: RowDataItem[]
  total: number
  columns: TalbeColumn[]
  rowClick?: (row: RowDataItem) => void
  selectable?: boolean
  customSelect?: boolean
  disabledPagination?: boolean
  multiSelectBar?: {
    selectedCountLable: string
    actions: { label: string; onClick: (ids: string[]) => void }[]
  }
  noResultsComponent?: ReactNode
  queryParams: any
  updateQueryParam: (newValues: any) => void
  inactiveRowCondition?: (row: RowDataItem) => boolean
  draggabledSimulationRows?: boolean
}

const CustomTable: FC<CustomTablePros> = ({
  loading,
  data,
  total,
  columns,
  rowClick,
  selectable,
  customSelect = false,
  disabledPagination = false,
  multiSelectBar,
  noResultsComponent,
  queryParams,
  updateQueryParam,
  inactiveRowCondition,
  draggabledSimulationRows = false,
}) => {
  //TODO consider movig this state outside the component
  const [selected, setSelected] = useState<string[]>([])
  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const currentPage = useMemo(() => queryParams.skip / queryParams.limit, [queryParams.skip, queryParams.limit])
  const emptyRows = useMemo(
    () => (currentPage > 0 ? Math.max(0, (1 + currentPage) * queryParams.limit - total) : 0),
    [currentPage, queryParams.limit, total]
  )

  const { t } = useTranslation()

  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof any) => {
    const isAsc = queryParams.sort_by === property && queryParams.sort_order === SortOrder.asc
    updateQueryParam({
      sort_by: property,
      sort_order: isAsc ? SortOrder.desc : SortOrder.asc,
    })
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n._id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: MouseEvent<unknown>, id: string) => {
    event?.stopPropagation()
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  return (
    <>
      {selectable && (
        <StyledToolbar expanded={selected.length > 0}>
          <Box>
            <span>{t(multiSelectBar?.selectedCountLable, { count: selected.length })}</span>
          </Box>
          <Box>
            {multiSelectBar?.actions.map((action) => (
              <Button
                key={action.label}
                variant="outlined"
                onClick={async () => {
                  try {
                    await action.onClick(selected)
                    setSelected([])
                  } catch (e) {}
                }}>
                {action.label}
              </Button>
            ))}
          </Box>
        </StyledToolbar>
      )}
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <CustomTableHead
          columns={columns}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={handleSelectAllClick}
          sortOrder={queryParams.sort_order}
          sortBy={queryParams.sort_by}
          rowCount={data?.length || 0}
          selectable={selectable}
          customSelect={customSelect}
        />

        <TableBody>
          {/* Loading skeleton loaderc */}
          {loading && (
            <TableSceletonLoader rowsCount={queryParams.limit} columnsCount={columns.length} selectable={selectable} />
          )}

          {/* No results message */}
          {!loading && total === 0 && (
            <TableRow style={{ height: (48 + 13) * queryParams.limit }}>
              <TableCell colSpan={columns.length + Number(!!selectable)} align="center">
                {noResultsComponent ? <>{noResultsComponent}</> : <TableNoResults />}
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            total > 0 &&
            data.map((row, index) => {
              const unwrappedTableRow = (
                <StyledTableRow
                  key={row._id}
                  hover
                  tabIndex={-1}
                  selected={isSelected(row._id)}
                  aria-checked={isSelected(row._id)}
                  onClick={() => rowClick && rowClick(row)}
                  clickable={!!rowClick}
                  isInactive={!!(inactiveRowCondition && inactiveRowCondition(row))}>
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row._id)}
                        color="primary"
                        checked={isSelected(row._id)}
                        inputProps={{
                          'aria-labelledby': `table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((col, index) => (
                    <TableCell
                      key={`${col.id}-${index}`}
                      align={index === 0 || (index === 1 && customSelect) ? 'left' : 'center'}>
                      {col.component && col.component(row)}
                      {col.format && col.format(row)}
                      {!col.component && !col.format && row[col.id]}
                    </TableCell>
                  ))}
                </StyledTableRow>
              )
              if (draggabledSimulationRows)
                return (
                  <DragTableRowWrapper row={row} columns={columns}>
                    {unwrappedTableRow}
                  </DragTableRowWrapper>
                )
              return unwrappedTableRow
            })}
          {/* Add empty rows to thE table to keep the same table height */}
          {emptyRows > 0 && (
            <TableRow style={{ height: (48 + 13) * emptyRows }}>
              {/* TODO Refactor this */}
              <TableCell colSpan={columns.length + Number(!!selectable)} />
            </TableRow>
          )}
        </TableBody>

        <CustomTableFooter
          colSpan={columns.length + Number(!!selectable)}
          currentPage={currentPage}
          total={total}
          disabled={disabledPagination}
          queryParams={queryParams}
          updateQueryParam={updateQueryParam}
        />
      </Table>
    </>
  )
}

const DragTableRowWrapper: FC<{
  children: ReactElement
  row: RowDataItem
  columns: TalbeColumn[]
}> = ({ children, row, columns }) => {
  const dragId = row.id + '_table'
  const { attributes, listeners, setNodeRef, active } = useDraggable({
    id: dragId,
  })
  const { setDraggedSimulation, setDraggedColumns } = useContext(ContentLibraryContext)
  useEffect(() => {
    if (active?.id === dragId) {
      setDraggedSimulation(row as PhishingSimulationAndPackages)
      setDraggedColumns(columns)
    }
  }, [active])

  return React.cloneElement(children, {
    ref: active?.id === dragId && !row.simulations ? setNodeRef : undefined,
    ...listeners,
    ...attributes,
  })
}

const StyledTableRow = styled(TableRow)(({ clickable, isInactive }: { clickable: boolean; isInactive: boolean }) => ({
  '&.MuiTableRow-hover:hover': { backgroundColor: theme.palette.blue[50] },
  cursor: clickable ? 'pointer' : 'default',
  background: isInactive ? theme.palette.grey[100] : '',
}))
const StyledToolbar = styled(Box)(({ expanded }: { expanded: boolean }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  maxHeight: expanded ? '48px' : '2px !important',
  opacity: expanded ? 1 : 0,
  transition: 'all .25s ease-in-out',
  overflow: 'hidden',
}))

export default CustomTable
