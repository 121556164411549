import { Box, Card, Grid, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useAdmin } from '@/api/admins/get'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import { useAuth } from '@/context/Auth'
import { theme } from '@/theme/theme'
import AdminProfileDetailsSection from './AdminProfileDetailsSection'
import AdminProfileHeader from './AdminProfileHeader'
import AdminProfileMFA from './AdminProfileMFA'
import AdminProfileSecuritySection from './AdminProfileSecuritySection'
import SupportAdminSwitchAccount from './SupportAdminSwitchAccount'

const AdminProfile: FC = () => {
  const { _id } = useParams()
  const { user } = useAuth()
  const { data: adminData, isFetching } = useAdmin({ id: _id })
  const isCurrnetUser = user?.id === adminData?._id

  return (
    <LayoutPageContent minWidth={940}>
      <PageHeader />
      {!isFetching && adminData && (
        <>
          <Grid item xs={12}>
            <Card>
              <AdminProfileHeader adminData={adminData} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <AdminProfileDetailsSection adminData={adminData} user={user} />
            </Card>
          </Grid>

          {isCurrnetUser && (
            <>
              <Grid item xs={12}>
                <Card>
                  <AdminProfileSecuritySection user={user} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <AdminProfileMFA user={user} />
                </Card>
              </Grid>
            </>
          )}
        </>
      )}
      {!adminData && !isFetching && (
        <Grid item xs={12}>
          <Card>
            <SupportAdminSwitchAccount />
          </Card>
        </Grid>
      )}
    </LayoutPageContent>
  )
}

export const StyledButtonsWraper = styled(Box)(() => ({
  padding: theme.spacing(1, 0, 2),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginLeft: 'auto',
}))
export const StyledTitle = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: theme.typography.fontWeightMedium,
}))

export default AdminProfile
