import { theme } from '@/theme/theme'
import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import { ChangeEvent, FC } from 'react'

interface IntegrationCardProps {
  icon: string
  label: string
  footnote?: string
  [x: string]: any // This is to allow any other props passed to Card component
}

const IntegrationCard: FC<IntegrationCardProps> = ({
  icon: IconComponent,
  label,
  footnote,
  marked,
  disabled,
  ...props
}) => {
  return (
    <Box
      {...props}
      sx={{
        border: marked ? `2px solid ${theme.palette.cyan[500]}` : `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '10px',
      }}>
      <CardContent
        sx={{
          width: '98px',
          height: '98px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px !important',
          background: disabled && theme.palette.grey[300],
        }}>
        <CardMedia
          sx={{
            mb: 1,
            width: '80px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: disabled && 0.5,
          }}>
          {IconComponent && <img src={IconComponent} width={50} />}
        </CardMedia>
        <Typography
          sx={{
            fontSize: 10,
            color: disabled && theme.palette.grey[600],
            fontWeight: 500,
            textAlign: 'center',
          }}>
          {footnote && label}
        </Typography>
        <Typography
          sx={{
            fontSize: 10,
            color: disabled && theme.palette.grey[600],
            fontWeight: 500,
            textAlign: 'center',
          }}>
          {footnote || label}
        </Typography>
      </CardContent>
    </Box>
  )
}

interface IntegrationType {
  value: string
  icon: string
  label: string
  footnote?: string
  disabled?: boolean
}

interface IntegrationPickerProps {
  value: string
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
  integrationTypes: IntegrationType[]
  existIntegrationsTypes: string[]
}

const IntegrationPicker: FC<IntegrationPickerProps> = ({
  value,
  name,
  onChange,
  integrationTypes,
  existIntegrationsTypes,
}) => {
  return (
    <FormControl>
      <RadioGroup row name={name} value={value} onChange={onChange}>
        {integrationTypes.map((integrationType) => (
          <FormControlLabel
            sx={{ marginRight: theme.spacing(3.5) }}
            key={integrationType.value}
            value={integrationType.value}
            control={
              <Radio
                sx={{ display: 'none' }}
                disabled={existIntegrationsTypes?.indexOf(integrationType.value) !== -1 || false}
              />
            }
            label={
              <IntegrationCard
                icon={integrationType.icon}
                label={integrationType.label}
                footnote={integrationType.footnote}
                marked={integrationType.value === value}
                disabled={existIntegrationsTypes?.indexOf(integrationType.value) !== -1 || false}
              />
            }
            disabled={integrationType.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default IntegrationPicker
