import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ISO_DATE, SHORT_EU_DATE } from '@/api/constants'
import { theme } from '@/theme/theme'

type DateRangePickerProps = {
  queryParams: any
  updateQueryParam: (newValues: any) => void
}

const DateRangePicker: FC<DateRangePickerProps> = ({ queryParams, updateQueryParam }) => {
  const { t } = useTranslation()
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)

  const handleDateChange = (key: string, date: string | null) => {
    updateQueryParam({ [key]: date ? dayjs(date).format(ISO_DATE) : null })
    setOpenStartDate(false)
    setOpenEndDate(false)
  }

  return (
    <>
      <Box sx={{ width: '200px', ml: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            components={{
              OpenPickerIcon: () => (
                <FontAwesomeIcon icon={(openStartDate ? faCaretDown : faCaretRight) as IconProp} fontSize={16} />
              ),
            }}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            inputFormat={SHORT_EU_DATE}
            open={openStartDate}
            onOpen={() => setOpenStartDate(true)}
            onClose={() => setOpenStartDate(false)}
            label={t('createTrainingCampaign.details.startDate')}
            value={queryParams.start_date ? dayjs(queryParams.start_date) : null}
            onChange={(date: string | null) => handleDateChange('start_date', date)}
            renderInput={(params) => (
              <StyledTextField
                size="small"
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
                onClick={() => setOpenStartDate(true)}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ width: '200px', ml: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            components={{
              OpenPickerIcon: () => (
                <FontAwesomeIcon icon={(openEndDate ? faCaretDown : faCaretRight) as IconProp} fontSize={16} />
              ),
            }}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            inputFormat={SHORT_EU_DATE}
            open={openEndDate}
            onOpen={() => setOpenEndDate(true)}
            onClose={() => setOpenEndDate(false)}
            label={t('createTrainingCampaign.details.endDate')}
            value={queryParams.end_date ? dayjs(queryParams.end_date) : null}
            onChange={(date: string | null) => handleDateChange('end_date', date)}
            renderInput={(params) => (
              <StyledTextField
                size="small"
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
                onClick={() => setOpenEndDate(true)}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </>
  )
}

const StyledTextField = styled(TextField)(() => ({
  fieldset: {
    borderColor: theme.palette.cyan[500],
  },
  '& .MuiFilledInput-root': {
    border: `1px solid ${theme.palette.cyan[500]}`,
  },
  '& .MuiFilledInput-root:hover': {
    backgroundColor: 'inherit',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.blueGray[800],
  },
  '&:hover .MuiFilledInput-root': {
    borderColor: theme.palette.blueGray[800],
  },
  '& .MuiInputBase-input': {
    padding: '8px 12px',
  },
}))

export default DateRangePicker
