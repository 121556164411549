import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'

const sendInstructions = async (payload: { html_instructions: string }) => {
  const url = `${API_URL}/domains/send-allowlisting-instructions`
  const { data } = await axios.post(url, payload, { withCredentials: true })

  return data.data
}

export const useSendInstructions = () => {
  return useMutation({
    mutationFn: sendInstructions,
  })
}
