import { Card, Grid } from '@mui/material'

import { useReportsAnalytics } from '@/api/reports/reports-analytics'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import ReportsAnalytics from './ReportsAnalytics'
import ReportsTable from './ReportsTable'

export default function ResponseConsole() {
  const { data, isLoading } = useReportsAnalytics()

  return (
    <LayoutPageContent>
      <PageHeader />
      <Grid item xs={12}>
        <Card>
          {isLoading || !data ? (
            <LoadingContainer height={250} container={'paper'} />
          ) : (
            <ReportsAnalytics data={data} />
          )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <ReportsTable pendingReports={data?.pending_reports} />
        </Card>
      </Grid>
    </LayoutPageContent>
  )
}
