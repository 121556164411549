import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import iconBackgroundComplete from '@/assets/images/progress-component/icon-background-complete.svg'
import iconBackground from '@/assets/images/progress-component/icon-background.svg'
import { theme } from '@/theme/theme'

type StepParams = {
  completed?: boolean
  marked?: boolean
  index: number
  title?: string
  icon?: IconProp
}

const Step: FC<StepParams> = ({ completed, marked, index, title, icon }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {icon && (
        <Box height="68px" position="relative">
          <StyledStepTitleIconWrapper completed={marked}>
            <FontAwesomeIcon fontSize={24} icon={icon} />
          </StyledStepTitleIconWrapper>
        </Box>
      )}
      {title && (
        <Box height="52px" position="relative">
          <StyledStepTitleTypography>{title}</StyledStepTitleTypography>
        </Box>
      )}

      <StyledStepIconCircle marked={marked} completed={completed} index={index}>
        {completed ? <FontAwesomeIcon icon={faCheck} /> : index}
      </StyledStepIconCircle>
    </Box>
  )
}

const StyledStepIconCircle = styled(Box)(
  ({ completed, marked, index }: { completed?: boolean; marked?: boolean; index: number }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: completed ? theme.palette.green[400] : theme.palette.blueGray[50],
    color: completed ? theme.palette.white : theme.palette.blueDianne[900],
    width: '27px',
    height: '27px',
    borderRadius: '100%',
    fontWeight: theme.typography.fontWeightSemiBold,
    marginTop: '16px',
    marginBottom: '-8px',
    boxShadow: `
    0 0 0 8px ${marked ? theme.palette.cyan[500] : theme.palette.grey[400]},
    0 0 0 16px ${theme.palette.grey[100]}
  `,
    transition:
      marked && index > 1
        ? 'box-shadow 0.2s ease 1s, background 1s ease 1s, color 0.5s ease 1s'
        : 'box-shadow 0.2s ease, background 1s ease, color 0.5s ease',
  })
)

const StyledStepTitleIconWrapper = styled(Box)(({ completed }: { completed?: boolean }) => ({
  position: 'absolute',
  transform: 'translate(-30px, 0px)',
  width: '60px',
  height: '68px',
  backgroundImage: `url(${completed ? iconBackgroundComplete : iconBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  display: 'flex',
  justifyContent: 'center',
  color: completed ? theme.palette.black : theme.palette.grey[600],
  transition: 'background-image 1s ease 1s, color 1s ease 1s',
  '& svg': {
    padding: '17px',
    marginRight: '3px',
  },
}))

const StyledStepTitleTypography = styled(Typography)(() => ({
  position: 'absolute',
  transform: 'translate(-56px, 0px)',
  width: '112px',
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
}))

export default Step
