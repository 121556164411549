import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { Group } from '@/types/groups'
import { useQueryClient } from '@tanstack/react-query'

export async function deleteGroup(_id: string) {
  const url = `${API_URL}/groups/delete/${_id}`
  const { data } = await axios.post(url, {}, { withCredentials: true })
  return data.data
}

export function useDeleteGroup() {
  const queryCache = useQueryCache()
  const queryClient = useQueryClient()

  return useMutation<string, ServerError, string>(deleteGroup, {
    throwOnError: true,
    onMutate: (_id) => {
      queryCache.cancelQueries('groups')
      const previousGroups = queryCache.getQueryData('groups') as Group[]
      const updatedGroups = previousGroups?.filter((group) => _id !== group._id)
      //@ts-ignore
      queryCache.setQueryData('groups', () => updatedGroups)
      return () => queryCache.setQueryData('groups', previousGroups)
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('groups')
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}
