import { palette } from '../palette'

export const MuiCheckbox = {
  styleOverrides: {
    root: {
      width: '19px',
      height: '19px',
      padding: '9px',
      overflow: 'hidden',
      margin: '11px',
      // *** Unchecked - Unactive *** :
      color: 'transparent',
      background: palette.white,
      border: '1px solid',
      borderColor: palette.blueGray[900],
      borderRadius: '4px',

      // Unchecked - Unactive - Hover:
      '&:hover': {
        background: palette.white,
        boxShadow: `0 0 0 10px ${palette.grey[300]}`,
      },
      '&:active': {
        boxShadow: `0 0 0 10px ${palette.grey[400]}`,
      },
      // Unchecked - Unactive - Disabled:
      '&.Mui-disabled': {
        borderColor: palette.grey[400],
        color: 'transparent',
        background: palette.grey[50],
      },

      //for SCD issue
      '& input': {
        position: 'absolute !important',
        width: '100% !important',
      },

      // *** Checked - Active *** :
      '&.Mui-checked': {
        background: palette.blueGray[900],
        borderColor: palette.blueGray[900],
        color: palette.cyan[500],
        // Checked - Active - Hover:
        '&:hover': {
          boxShadow: `0 0 0 10px ${palette.cyan[50]}`,
        },
        '&:active': {
          boxShadow: `0 0 0 10px ${palette.cyan[200]}`,
        },
        // Checked - Active - Disabled:
        '&.Mui-disabled': {
          color: palette.grey[400],
          borderColor: palette.grey[400],
          background: palette.grey[600],
        },
      },
    },
    sizeSmall: {
      width: '15px',
      height: '15px',
      padding: '7px',
    },
  },
}
