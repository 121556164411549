import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'

export type UpdateClientParams = {
  default_language?: string
  default_timezone?: string
  countries?: string[]
  market_sectors?: string[]
  brands?: string[]
  velocity?: string
  onboarding?: boolean
  simulator_enabled?: boolean
  allow_unverified_domains?: boolean
  name?: string
  logo?: string
  mfa_enabled?: boolean
  origin_country?: string
}

//TODO Refacotr this to use React Query V5
//TODO Add indutry to the endpoint
export async function updateClient(params: UpdateClientParams): Promise<string> {
  const url = `${API_URL}/organizations/`
  const { data } = await axios.patch(url, { ...params }, { withCredentials: true })
  return data
}

export function useUpdateClient() {
  const queryCache = useQueryCache()
  return useMutation<string, ServerError, UpdateClientParams>(updateClient, {
    throwOnError: true,
    onMutate: (updatedClientFields) => {
      queryCache.cancelQueries('organizations')
      const previousClientData = queryCache.getQueryData('organizations')
      if (previousClientData) {
        const updatedClientData = {
          ...previousClientData,
          ...updatedClientFields,
        }
        queryCache.setQueryData('organizations', () => updatedClientData)
        return () => queryCache.setQueryData('organizations', previousClientData)
      }
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('organizations')
    },
  })
}
