import { useQuery } from '@tanstack/react-query'

import { API_URL } from '@/api/constants'
import { PagedResult } from '@/types/common'
import { OrganizationIndustry } from '@/types/organizations'

const getOrganizationIndustries = async () => {
  //   const url = `${API_URL}/organizations/industries`
  //   const { data } = await Axios.get(url, { withCredentials: true })

  //TODO Remove mock data
  const data = Promise.resolve({
    total: 10,
    results: [
      {
        id: '678513d5b9766eb96ffa0687',
        slug: 'technology_software',
      },
      {
        id: '678513d5b9766eb96ffa0688',
        slug: 'financial_services_banking',
      },
      {
        id: '678513d5b9766eb96ffa0689',
        slug: 'healthcare_pharmaceuticals',
      },
      {
        id: '678513d5b9766eb96ffa068a',
        slug: 'automotive_manufacturing',
      },
      {
        id: '678513d5b9766eb96ffa068b',
        slug: 'oil_gas',
      },
      {
        id: '678513d5b9766eb96ffa068c',
        slug: 'retail_e_commerce',
      },
      {
        id: '678513d5b9766eb96ffa068d',
        slug: 'telecommunications',
      },
      {
        id: '678513d5b9766eb96ffa068e',
        slug: 'construction_real_estate',
      },
      {
        id: '678513d5b9766eb96ffa068f',
        slug: 'agriculture_agribusiness',
      },
      {
        id: '678513d5b9766eb96ffa0690',
        slug: 'food_beverage',
      },
      {
        id: '678513d5b9766eb96ffa0691',
        slug: 'insurance',
      },
      {
        id: '678513d5b9766eb96ffa0692',
        slug: 'aerospace_defense',
      },
      {
        id: '678513d5b9766eb96ffa0693',
        slug: 'media_entertainment',
      },
      {
        id: '678513d5b9766eb96ffa0694',
        slug: 'travel_tourism',
      },
      {
        id: '678513d5b9766eb96ffa0695',
        slug: 'education_training',
      },
      {
        id: '678513d5b9766eb96ffa0696',
        slug: 'energy_utilities',
      },
      {
        id: '678513d5b9766eb96ffa0697',
        slug: 'mining_metals',
      },
      {
        id: '678513d5b9766eb96ffa0698',
        slug: 'transportation_logistics',
      },
      {
        id: '678513d5b9766eb96ffa0699',
        slug: 'chemical_manufacturing',
      },
      {
        id: '678513d5b9766eb96ffa069a',
        slug: 'fashion_apparel',
      },
      {
        id: '678513d5b9766eb96ffa069b',
        slug: 'consumer_electronics',
      },
      {
        id: '678513d5b9766eb96ffa069c',
        slug: 'hospitality_hotels',
      },
      {
        id: '678513d5b9766eb96ffa069d',
        slug: 'biotechnology',
      },
      {
        id: '678513d5b9766eb96ffa069e',
        slug: 'renewable_energy',
      },
      {
        id: '678513d5b9766eb96ffa069f',
        slug: 'sports',
      },
      {
        id: '678513d5b9766eb96ffa06a0',
        slug: 'government_public_administration',
      },
      {
        id: '678513d5b9766eb96ffa06a1',
        slug: 'marine_shipping',
      },
      {
        id: '678513d5b9766eb96ffa06a2',
        slug: 'printing_publishing',
      },
      {
        id: '678513d5b9766eb96ffa06a3',
        slug: 'other',
      },
    ],
  })

  return data
}

export const useOrganizationIndustries = () =>
  useQuery<PagedResult<OrganizationIndustry>>({
    queryKey: ['organizationsIndustries'],
    queryFn: getOrganizationIndustries,
  })
