import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, styled, Typography } from '@mui/material'
import { FC, ReactElement, ReactNode } from 'react'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { theme } from '@/theme/theme'

type LayoutAccordion = {
  title: string
  startIcon?: ReactElement
  summaryBackGroundColor?: string
  children: ReactNode
  expanded?: boolean
  onClick?: () => void
}

const LayoutAccordion: FC<LayoutAccordion> = ({
  title,
  startIcon,
  summaryBackGroundColor,
  expanded,
  children,
  onClick,
}) => {
  return (
    <Grid item xs={12} xl={12} minWidth={'300px'}>
      <StyledAccordion expanded={expanded}>
        <StyledAccordionSummary
          onClick={onClick}
          backgroundColor={summaryBackGroundColor}
          expandIcon={
            <StyledExpendIconWrapper>
              <FontAwesomeIcon icon={faChevronRight} color={theme.palette.white} />
            </StyledExpendIconWrapper>
          }>
          <Box display="flex" alignItems="center" gap={1}>
            {startIcon}
            <Typography fontWeight={theme.typography.fontWeightSemiBold}>{title}</Typography>
          </Box>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ padding: 4 }}>{children}</AccordionDetails>
      </StyledAccordion>
    </Grid>
  )
}

const StyledExpendIconWrapper = styled(Box)(() => ({
  background: theme.palette.blueEyes[500],
  height: '56px',
  width: '72px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledAccordion = styled(Accordion)(({ expanded }) => ({
  overflow: 'clip',
  border: 'none',
  borderBottom: expanded ? 'none' : `1px solid ${theme.palette.blueEyes[500]}`,
}))

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ backgroundColor }: { backgroundColor: string | undefined }) => ({
    background: backgroundColor,
    '.MuiAccordionSummary-expandIconWrapper': {
      transform: 'none !important',
      '&.Mui-expanded': {
        '& svg': {
          transform: 'rotate(90deg)',
        },
      },
    },
    borderRadius: '10px 10px 0px 0px',
    padding: '0px 0px 0px 32px',
    overflow: 'clip',
    minHeight: '56px',
    maxHeight: '56px',
    '&.Mui-expanded': {
      minHeight: '56px',
    },
    '.MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: 0,
    },
  })
)

export default LayoutAccordion
