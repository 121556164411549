import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import * as Yup from 'yup'

import { useUpdateClient } from '@/api/client/update-client'
import { countriesArray } from '@/assets/countries'
import HelperTextComponent from '@/common/components/Inputs/HelperTextComponent'
import TextField from '@/common/components/Inputs/TextFieldV2'
import UploadImageBox from '@/common/components/UploadImageBox/UploadImageBox'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { FieldWrapper, Title } from './ConfigurationsStyledComponents'

type OrganizationSettingsProps = {
  name?: string
  country?: string
  logo?: string
}

const OrganizationSettings: FC<OrganizationSettingsProps> = ({ name, country, logo }) => {
  const { t } = useTranslation()
  const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClient()
  const initialClientValues = {
    organizationName: name || '',
    organiztionCountry: country || '',
  }
  const { successToast, errorToast } = useToast()
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .min(2)
      .required(t('settings.configurationTab.organizationSettings.organiztaionNameRequired')),
    organiztionCountry: Yup.string().required(
      t('settings.configurationTab.organizationSettings.organizationOriginRequired')
    ),
  })

  async function handleOnFinishImageUpload(url: string) {
    await updateClient({ logo: url })
  }

  const handleSubmit = async (values: { organizationName: string; organiztionCountry: string }) => {
    const { organizationName, organiztionCountry } = values
    if (organizationName && organiztionCountry) {
      try {
        await updateClient({ name: organizationName, origin_country: organiztionCountry })
        successToast(t('settings.configurationTab.organizationSettings.organizationUpdated'))
      } catch (e) {
        errorToast(t('settings.configurationTab.organizationSettings.errors.organizationUpdateFailed'))
      }
    }
  }

  return (
    <Box paddingBottom={3}>
      <Title>{t('settings.configurationTab.organizationSettings.organizationSettings')}</Title>
      <Box display="flex" height={'184px'} paddingBottom={2}>
        <UploadImageBox image={logo} handleOnFinishImageUpload={handleOnFinishImageUpload} />
      </Box>
      <Formik onSubmit={handleSubmit} initialValues={initialClientValues} validationSchema={validationSchema}>
        {({ setFieldValue, isSubmitting, values, dirty, isValid, errors, touched }) => (
          <Form>
            <Box display="flex" flexDirection={'column'} gap={2}>
              <TextField
                label={t('settings.configurationTab.organizationSettings.organiztaionName')}
                name="organizationName"
                placeholder={t('settings.configurationTab.organizationSettings.organiztaionName')}
              />
              <StyledFormControl
                fullWidth
                size="small"
                variant="outlined"
                error={!values.organiztionCountry && touched.organizationName}>
                <InputLabel required htmlFor="style-select">
                  {t('settings.configurationTab.organizationSettings.organizationOrigin')}
                </InputLabel>
                <Select
                  size="small"
                  required
                  value={values.organiztionCountry}
                  name="organiztionCountry"
                  placeholder={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                  onChange={(value) => {
                    setFieldValue('organiztionCountry', value.target.value)
                  }}
                  input={
                    <StyledOutlinedInput
                      label={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                    />
                  }>
                  {countriesArray.map((country) => {
                    return <MenuItem value={country.code}>{country.name}</MenuItem>
                  })}
                </Select>
                <Box paddingTop={0.3} height={theme.spacing(2)}>
                  <HelperTextComponent hasError={!!errors.organiztionCountry} helperText={errors.organiztionCountry} />
                </Box>
              </StyledFormControl>
            </Box>
            <FieldWrapper>
              <Button
                sx={{ width: '100%' }}
                type="submit"
                disabled={!dirty || !isValid || isSubmitting || isUpdatingClient}
                variant="contained">
                {t('settings.configurationTab.organizationSettings.saveCahnge')}
              </Button>
            </FieldWrapper>
          </Form>
        )}
      </Formik>
      {/* <UploadImageModal
        open={imageModalIsOpen}
        setOpen={setImageModalIsOpen}
        onFinishUpload={handleOnFinishImageUpload}
      /> */}
    </Box>
  )
}

const StyledFormControl = styled(FormControl)(() => ({
  label: {
    fontSize: '12px',
    padding: '2px 8px',
    margin: '1px 0 0',
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    padding: 0,
    margin: 0,
    fontSize: '16px',
  },
}))

const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  '& MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  fieldset: {
    borderRadius: '10px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0.5px',
  },
  '&:hover fieldset': {
    borderWidth: '0.5px',
  },
  '& legend': {
    fontSize: '12px',
    width: '108px',
    marginLeft: '4px',
  },
}))

export default OrganizationSettings
