import { DomainListQuery, DomainsResponse } from '@/types/domains'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'
import { paramsSerializer } from '../utils/serialize-array'

export async function getDomains(params: DomainListQuery) {
  const url = `${API_URL}/domains/`
  const { data } = await axios.get(url, { withCredentials: true, params, paramsSerializer: paramsSerializer })
  return data
}
export const useDomains = (query: DomainListQuery) => {
  return useQuery<DomainsResponse>({
    queryKey: ['domains', query],
    queryFn: () => {
      return getDomains(query)
    },
    placeholderData: keepPreviousData,
  })
}
