import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faBadgeCheck,
  faCircleExclamation,
  faMinus,
  faPlus,
  faSquareExclamation,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete as MuiAutocomplete,
  Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Formik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { GroupListItem } from '@/types/groups'
import useCreateIntegration, { MicrosoftEntraIdIntegrationData } from '@/api/integrations/create'
import { MicrosoftEntraIdIntegrationType } from '@/api/integrations/integrations'
import useTestConnection, { MicrosoftEntraIdAuthenticationData } from '@/api/integrations/test-connection'
import useUpdateIntegration from '@/api/integrations/update'
import { useIntegrationEntities, useIntegrationEntitiesWithCredentials } from '@/api/integrations/entities'
import { useOffices } from '@/api/offices/get'
import AuthenticationIcon from '@/assets/images/Integrations/FormStages/Authentication'
import ConfigurationIcon from '@/assets/images/Integrations/FormStages/Configuration'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import Autocomplete from '@/common/components/Inputs/Autocomplete'
import Checkbox from '@/common/components/Inputs/Checkbox'
import Timepicker from '@/common/components/Inputs/Timepicker'
import Stepper from '@/common/components/Stepper'
import Step from '@/common/components/Stepper/Step'
import { theme } from '@/theme/theme'
import { Group } from '@/types/groups'
import { Office } from '@/types/offices'
import { FormSection, IntegrationCardType, StyledButtonWrapper } from './IntegrationModal'
import { cronToDayjs } from './IntegrationsUtils'
import { EntityType } from '@/types/integrations'
import InactiveUsersModal from './InactiveUsersModal'

type MicrosoftEntraIdIntegrationProps = {
  integration: MicrosoftEntraIdIntegrationType | undefined // change to Microsoft Entra when BE is ready.
  integrationTypes: IntegrationCardType[]
  onClose: () => void
}

interface IntegrationFormValues {
  type: string
  authentication: {
    tenantId: string
    clientSecret: string
    clientId: string
  }
  configuration: {
    schedule: Dayjs
    userImportStatus: { value: string } | undefined
    defaultUserOffice: Office | null
    departmentGroupMapEnabled: boolean
    groupsMap: Record<string, Group> | undefined
    azureGroups: string[]
    cywarenessGroups: string[]
  }
}
const MicrosoftEntraIdIntegration: FC<MicrosoftEntraIdIntegrationProps> = ({ integration, onClose }) => {
  const [createIntegration] = useCreateIntegration()
  const [updateIntegration] = useUpdateIntegration()
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const { data: officesData, isFetching: isFetchingOffices } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const [inactivePopupOpen, setInactivePopupOpen] = useState(false)
  const { t } = useTranslation()
  const [entities, setEntities] = useState<any>()
  const { data: entitiesData } = useIntegrationEntities(
    integration?.id as string,
    { types: [EntityType.GROUPS] },
    { enabled: !!integration }
  )

  useEffect(() => {
    if (entitiesData) {
      setEntities(entitiesData)
    }
  }, [entitiesData])

  const [
    testIntegrationConnection,
    { isLoading: isTestingConnection, isError: isConnectionError, isSuccess: isConnectionSuccess },
  ] = useTestConnection()

  useEffect(() => {
    if (isConnectionSuccess) {
    }
  }, [isConnectionSuccess])
  const { mutateAsync: getIntegrationEntitiesWithCredentials } = useIntegrationEntitiesWithCredentials()

  const initialFormState = {
    type: 'ms-graph',
    authentication: {
      tenantId: integration?.authentication?.tenant_id || '',
      clientSecret: integration?.authentication?.client_secret || '',
      clientId: integration?.authentication?.client_id || '',
    },
    configuration: {
      userImportStatus: integration?.configuration.user_import_status || 'active',
      defaultUserOffice:
        offices.filter((office) => office._id === integration?.configuration.default_user_office)[0] || '',
      departmentGroupMapEnabled: Object.keys(integration?.configuration?.groups_map || {}).length > 0 || false,
      schedule: cronToDayjs(integration?.configuration?.schedule),
      azureGroups:
        integration?.configuration.groups_map && Object.keys(integration.configuration.groups_map).length
          ? Object.keys(integration?.configuration.groups_map)
          : [''],
      cywarenessGroups:
        integration?.configuration.groups_map && Object.values(integration.configuration.groups_map).length
          ? Object.values(integration?.configuration.groups_map)
          : [''],
      groupsMap: {},
    },
  }

  const validationSchema = Yup.object().shape({
    configuration: Yup.object().shape({
      defaultUserOffice: Yup.string().nullable().required(t('settings.integrations.validations.defaultOfficeRequired')),
      schedule: Yup.mixed()
        .nullable()
        .required(t('settings.integrations.validations.importAsRequired'))
        .test('is-valid-time', 'Time is not valid', (value: string) => {
          return dayjs.isDayjs(value) && value.isValid()
        }),
    }),
  })

  const submitForm = async (values: IntegrationFormValues, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true)
    const integrationData = {
      ...values,
      name: 'Microsoft Entra ID Integration',
      configuration: {
        ...values.configuration,
        schedule: `${values.configuration.schedule.utc().format('m')} ${values.configuration.schedule.format(
          'H'
        )} * * *`,
        userImportStatus: values.configuration.userImportStatus?.value || 'active',
        defaultUserOffice: values.configuration.defaultUserOffice?._id || '',
        groupsMap: values.configuration.departmentGroupMapEnabled
          ? values.configuration.azureGroups.reduce((acc, key, index) => {
              acc[key] = values.configuration.cywarenessGroups[index]
              return acc
            }, {})
          : undefined,
      },
    }
    if (integration) {
      await updateIntegration({
        id: integration.id,
        integrationData: integrationData,
      })
    } else {
      await createIntegration(integrationData as MicrosoftEntraIdIntegrationData)
    }
    setSubmitting(false)
    onClose()
  }

  const handleSave = async (values: IntegrationFormValues, { setSubmitting }) => {
    if (values.configuration.userImportStatus?.value === 'inactive') {
      setInactivePopupOpen(true)
    } else {
      submitForm(values, setSubmitting)
    }
  }

  const handleConnectionTest = async (data: MicrosoftEntraIdAuthenticationData) => {
    await testIntegrationConnection(data)
  }

  return (
    <Formik onSubmit={handleSave} initialValues={initialFormState} validationSchema={validationSchema}>
      {({ setFieldValue, isSubmitting, values, touched, setFieldTouched, errors, setSubmitting }) => {
        useEffect(() => {
          const fetchAssets = async () => {
            if (
              values.authentication.tenantId &&
              values.authentication.clientId &&
              values.authentication.clientSecret
            ) {
              await getIntegrationEntitiesWithCredentials({
                entities: { types: [EntityType.GROUPS] },
                authData: {
                  type: values.type,
                  authentication: values.authentication,
                },
              }).then((res) => setEntities(res))
            }
          }
          fetchAssets()
        }, [isConnectionSuccess])

        return (
          <Form noValidate>
            <InactiveUsersModal
              open={inactivePopupOpen}
              onClose={() => setInactivePopupOpen(false)}
              onConfirm={() => {
                setInactivePopupOpen(false)
                submitForm(values, setSubmitting)
              }}
            />
            <Stepper>
              <Step label={t('settings.integrations.authentication')} icon={AuthenticationIcon} active={true}>
                <FormSection>
                  <Typography>
                    <Trans
                      i18nKey={'settings.integrations.azure.instructionsLink'}
                      components={{
                        1: (
                          <a
                            href="/settings/integrations/entra-id-istructions"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Typography>
                  <StyledTextField
                    label={t('settings.integrations.azure.tenantId')}
                    value={values.authentication.tenantId}
                    disabled={!!integration}
                    onChange={(e) => {
                      setFieldValue('authentication.tenantId', e.target.value)
                      setFieldTouched('authentication.tenantId')
                    }}
                  />
                  <StyledTextField
                    label={t('settings.integrations.azure.clientId')}
                    value={values.authentication.clientId}
                    disabled={!!integration}
                    onChange={(e) => {
                      setFieldValue('authentication.clientId', e.target.value)
                      setFieldTouched('authentication.clientId')
                    }}
                  />
                  <StyledTextField
                    label={t('settings.integrations.azure.clientSecret')}
                    value={values.authentication.clientSecret}
                    disabled={!!integration}
                    onChange={(e) => {
                      setFieldValue('authentication.clientSecret', e.target.value)
                      setFieldTouched('authentication.clientSecret')
                    }}
                  />

                  <Box display="flex" gap={theme.spacing(2)} alignItems="center">
                    <Button
                      disabled={
                        !!integration ||
                        !values.authentication.tenantId ||
                        !values.authentication.clientSecret ||
                        !values.authentication.clientId ||
                        isTestingConnection
                      }
                      variant="outlined"
                      onClick={() =>
                        handleConnectionTest({
                          type: values.type,
                          authentication: values.authentication,
                        })
                      }>
                      {t('settings.integrations.verify')}
                    </Button>
                    <Box whiteSpace="nowrap">
                      {(isConnectionSuccess || !!integration) && (
                        <Typography>
                          <FontAwesomeIcon
                            icon={faBadgeCheck as IconProp}
                            color={theme.palette.green[500]}
                            width={theme.spacing(3)}
                          />
                          {t('settings.integrations.authenticationVerified')}
                        </Typography>
                      )}
                      {isConnectionError && (
                        <Typography>
                          <FontAwesomeIcon
                            icon={faSquareExclamation as IconProp}
                            color={theme.palette.red[900]}
                            width={theme.spacing(3)}
                          />
                          {t('settings.integrations.authenticationFailed')}
                        </Typography>
                      )}
                      {isTestingConnection && (
                        <Typography>
                          <FontAwesomeIcon
                            icon={faCircleExclamation as IconProp}
                            color={theme.palette.blue[500]}
                            width={theme.spacing(3)}
                          />
                          {t('settings.integrations.authenticationInProgress')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </FormSection>
              </Step>

              <Step label={t('settings.integrations.configurations')} icon={ConfigurationIcon} active>
                <FormSection>
                  <Typography>{t('settings.integrations.syncOptions')}</Typography>
                  <Box display="flex" alignItems="center">
                    <Checkbox checked disabled disableFormik name="members" label="Members" />
                    <InfoTooltip
                      title={
                        <>
                          <b>{t('settings.integrations.azure.membersInfo.title')}</b>
                          <br /> <span>{t('settings.integrations.azure.membersInfo.message')}</span>
                        </>
                      }
                      style={{ marginLeft: theme.spacing(-1) }}
                    />
                  </Box>
                  <FormControl fullWidth>
                    <StyledInputLabel id="import-as-select-label">
                      {t('settings.integrations.importAs')}
                    </StyledInputLabel>
                    <StyledSelect
                      required
                      labelId="import-as-select-label"
                      value={values.configuration.userImportStatus}
                      label={t('settings.integrations.importAs')}
                      onChange={(e) => {
                        setFieldValue('configuration.userImportStatus', e.target.value)
                        setFieldTouched('configuration.userImportStatus')
                      }}>
                      <MenuItem key={0} value={'active'}>
                        {t('settings.integrations.active')}
                      </MenuItem>
                      <MenuItem key={1} value={'inactive'}>
                        {t('settings.integrations.inactive')}
                      </MenuItem>
                    </StyledSelect>
                  </FormControl>
                  <Typography fontSize="12px" marginTop={theme.spacing(-1)}>
                    {t('settings.integrations.billed')}
                  </Typography>
                  <Autocomplete
                    label={t('settings.integrations.defaultOffice')}
                    placeholder={t('settings.integrations.defaultOffice')}
                    loading={isFetchingOffices}
                    name="configuration.defaultUserOffice"
                    onChange={() => setFieldTouched('configuration.defaultUserOffice')}
                    options={offices || []}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  />
                  <Typography fontSize="12px" marginTop={theme.spacing(-1)}>
                    {offices.length
                      ? t('settings.integrations.defaultOfficeNote')
                      : t('settings.integrations.linkToCreateNewOfficeNote')}
                  </Typography>
                  {!offices.length && (
                    <a href="/recipients/offices"> {t('settings.integrations.linkToCreateNewOffice')}</a>
                  )}
                  <Checkbox label="Groups" name="configuration.departmentGroupMapEnabled" />
                  {values.configuration.departmentGroupMapEnabled &&
                    values.configuration.azureGroups.map((_group, i) => {
                      return (
                        <Box display="flex" alignItems="center" gap="8px" key={i}>
                          <FormControl fullWidth>
                            <StyledMuiAutocomplete
                              options={entities?.groups || []}
                              value={values.configuration.azureGroups[i] || ''}
                              onChange={(_, newValue) => {
                                values.configuration.azureGroups[i] = (newValue as string) || ''
                                setFieldValue('configuration.azureGroups', [...values.configuration.azureGroups])
                                setFieldTouched('configuration.azureGroups', true)
                              }}
                              getOptionLabel={(option) => option as string}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t('settings.integrations.google.entraIdGroup')}
                                  variant="outlined"
                                />
                              )}
                              renderOption={(props, option) => (
                                <Tooltip
                                  title={option as string}
                                  placement="top"
                                  disableHoverListener={(option as string).length <= 13}>
                                  <MenuItem {...props}>
                                    {(option as string).length > 13
                                      ? `${(option as string).substring(0, 13)}...`
                                      : (option as string)}
                                  </MenuItem>
                                </Tooltip>
                              )}
                            />
                          </FormControl>
                          <FontAwesomeIcon icon={faArrowRight as IconProp} />
                          <FormControl fullWidth>
                            <StyledMuiAutocomplete
                              options={groups || []}
                              value={
                                groups.find((group) => group._id === values.configuration.cywarenessGroups[i]) || null
                              }
                              onChange={(_, newValue) => {
                                // Ensure the correct value is assigned
                                const selectedValue = newValue ? (newValue as GroupListItem)._id : ''
                                values.configuration.cywarenessGroups[i] = selectedValue

                                setFieldValue('configuration.cywarenessGroups', [
                                  ...values.configuration.cywarenessGroups,
                                ])
                                setFieldTouched('configuration.cywarenessGroups')
                              }}
                              getOptionLabel={(option) => (option as GroupListItem).name || ''}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t('settings.integrations.google.cywarenessGroup')}
                                  variant="outlined"
                                />
                              )}
                              renderOption={(props, option) => (
                                <Tooltip
                                  title={(option as GroupListItem).name}
                                  placement="top"
                                  disableHoverListener={(option as GroupListItem).name.length <= 13}>
                                  <MenuItem
                                    {...props}
                                    key={(option as GroupListItem)._id}
                                    value={(option as GroupListItem)._id}>
                                    {(option as GroupListItem).name.length > 13
                                      ? `${(option as GroupListItem).name.substring(0, 13)}...`
                                      : (option as GroupListItem).name}
                                  </MenuItem>
                                </Tooltip>
                              )}
                            />
                          </FormControl>
                          {i ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                values.configuration.azureGroups.splice(i, 1)
                                values.configuration.cywarenessGroups.splice(i, 1)
                                setFieldValue('configuration.azureGroups', [...values.configuration.azureGroups])
                                setFieldTouched('configuration.azureGroups')
                                setFieldValue('configuration.cywarenessGroups', [
                                  ...values.configuration.cywarenessGroups,
                                ])
                                setFieldTouched('configuration.cywarenessGroups')
                              }}>
                              <FontAwesomeIcon icon={faMinus as IconProp} />
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setFieldValue('configuration.azureGroups', [...values.configuration.azureGroups, ''])
                                setFieldTouched('configuration.azureGroups')
                                setFieldValue('configuration.cywarenessGroups', [
                                  ...values.configuration.cywarenessGroups,
                                  '',
                                ])
                                setFieldTouched('configuration.cywarenessGroups')
                              }}>
                              <FontAwesomeIcon icon={faPlus as IconProp} />
                            </Button>
                          )}
                        </Box>
                      )
                    })}
                  <Box>
                    <Typography>{t('settings.integrations.sync')}</Typography>
                    <Box sx={{ display: 'flex', mt: '12px' }}>
                      <Timepicker
                        required
                        name="configuration.schedule"
                        label={t('settings.integrations.syncAt')}
                        onChange={() => {
                          setFieldTouched('configuration.schedule')
                        }}
                      />
                    </Box>
                  </Box>
                </FormSection>
              </Step>
            </Stepper>
            <StyledButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || (!integration && !isConnectionSuccess) || !Object.keys(touched).length}>
                {!!integration ? t('settings.integrations.update') : t('settings.integrations.save')}
              </Button>
            </StyledButtonWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    marginTop: '-9px',
  },
}))

const StyledInputLabel = styled(InputLabel)(() => ({
  fontFamily: 'Montserrat',
  '&.MuiInputLabel-root': {
    marginTop: '-9px',
  },
  '&.MuiFormLabel-filled': {
    marginTop: '0px',
  },
}))
const StyledSelect = styled(Select)(() => ({
  height: '36px',
  '.MuiSelect-select': {
    height: '36px',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
  },
}))
const StyledMuiAutocomplete = styled(MuiAutocomplete)(() => ({
  height: 36,
  '& .MuiOutlinedInput-root': {
    height: 36,
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root': {
    marginTop: '-10px',
  },
  '& .MuiFormLabel-filled': {
    marginTop: '0px',
  },
  '& .Mui-focused': {
    marginTop: '0px',
  },
}))
export default MicrosoftEntraIdIntegration
