import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faFishingRod, faHeart, faPenToSquare, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Card, Grid, IconButton, Typography, styled, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { NEW_SAVE_ANIMATION_DELAY_TIME } from '@/api/constants'
import { usePhishingSimulationPackageDelete } from '@/api/phishing-simulations/packages/delete'
import { usePhishingSimulationPackage } from '@/api/phishing-simulations/packages/get'
import { useToggleSavedAssetPackage } from '@/api/profile/toggle-saved-asset-package'
import { getErrorMessage } from '@/api/utils/get-error'
import packageSaveAnimationData from '@/assets/lottie/rocketsPackaged.json'
import FeedbackAnimation from '@/common/animations/FeedbackAnimation'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'
import useToast from '@/common/hooks/useToast'
import { useAuth } from '@/context/Auth'
import { theme } from '@/theme/theme'
import { AssetType } from '@/types/campaigns'
import {
  Languages,
  PhishingSimulation,
  PhishingSimulationPackage,
  PhishingSimulationVector,
} from '@/types/phishingSimulations'
import SimulationPagePreviews from '../SimulationPage/SimulationPagePreviews'
import SimulationPageStats from '../SimulationPage/SimulationPageStats'
import SimulationPageTopics from '../SimulationPage/SimulationPageTopics'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import DeletePackagePopup from '../DeletePackagePopup'

type LanguageObject = { [key: string]: string[] }

const SimulationPackagePage: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(0)
  const { user, user: { saved_asset_packages = [] } = {} } = useAuth()

  const handleTabChange = useCallback(
    (_: ChangeEvent<{}>, index: number) => {
      setCurrentTab(index)
    },
    [setCurrentTab]
  )
  const { id = '' } = useParams<'id'>()
  const { errorToast, successToast } = useToast()

  const { mutateAsync: toggleSavedAssetPackage } = useToggleSavedAssetPackage()
  const { data: phishingSimulationPackageData, isPending } = usePhishingSimulationPackage(id)
  const { mutateAsync: deleteSimulationPackage } = usePhishingSimulationPackageDelete()

  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false)

  const isNEW =
    dayjs().valueOf() -
      dayjs(phishingSimulationPackageData?.created_at)
        .add(dayjs(phishingSimulationPackageData?.created_at).utcOffset(), 'minutes')
        .valueOf() <
    NEW_SAVE_ANIMATION_DELAY_TIME

  const handleSaveSimulation = async (simulationsPackage: PhishingSimulationPackage) => {
    try {
      await toggleSavedAssetPackage(simulationsPackage.id)
    } catch (error) {
      errorToast(t('simulationLibrary.errors.failedToSave'))
      console.log(error)
    }
  }

  const handlePackageDelete = async (simulationPackage: PhishingSimulationPackage) => {
    try {
      await deleteSimulationPackage(simulationPackage.id)
      successToast(t('simulationPackage.deleteSuccess'))
      navigate('/content-library/simulations')
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
    }
  }

  const handlePackageEdit = (simulationPackage: PhishingSimulationPackage) => {
    navigate(`/content-library/simulations?editPackage=${simulationPackage.id}`)
  }

  const phishingSimulationPackageExtended = useMemo(() => {
    if (!phishingSimulationPackageData) return null

    const languages: { landing_page: string[]; message: string[] }[] = []
    let difficulties: number[] = []
    let tags: string[] = []
    let vectors: PhishingSimulationVector[] = []
    phishingSimulationPackageData.simulations.forEach((simulation: PhishingSimulation) => {
      languages.push(simulation.languages)
      difficulties.push(...simulation.difficulties)
      tags.push(...simulation.tags)
      vectors.push(...simulation.vectors)
    })
    difficulties = Array.from(new Set(difficulties))
    tags = Array.from(new Set(tags))
    const mergedObject: LanguageObject = languages.reduce((acc: LanguageObject, obj: LanguageObject) => {
      Object.keys(obj).forEach((key) => {
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key] = Array.from(new Set([...acc[key], ...obj[key]]))
      })
      return acc
    }, {})

    return {
      ...phishingSimulationPackageData,
      languages: mergedObject as Languages,
      difficulties: difficulties.sort(),
      tags: tags,
      vectors: vectors,
    }
  }, [phishingSimulationPackageData])

  if (!phishingSimulationPackageData && !isPending) {
    navigate('/content-library/simulations')
  }

  if (isPending) return <LoadingContainer />

  return (
    phishingSimulationPackageExtended && (
      <LayoutPageContent>
        <LaunchWizard
          campaignType={AssetType.phishing_simulation}
          open={launchWizardIsOpen}
          onClose={() => setLaunchWizardIsOpen(false)}
          assets={phishingSimulationPackageExtended.simulations}
          packageName={phishingSimulationPackageExtended.name}
        />
        <DeletePackagePopup
          open={isDeletePopUpOpen}
          onClose={() => {
            setIsDeletePopUpOpen(false)
          }}
          onConfirm={() => {
            handlePackageDelete(phishingSimulationPackageExtended)
            setIsDeletePopUpOpen(false)
          }}
          packageName={phishingSimulationPackageExtended.name}
        />
        <PageHeader>
          <Button onClick={() => setLaunchWizardIsOpen(true)} variant={'contained'}>
            {t('videoLibrary.launch')}
          </Button>
        </PageHeader>
        <Grid item xs={12}>
          <Card>
            <SimulationPageStats simulation={phishingSimulationPackageExtended} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <MainTabsWrapper>
            <MainTabs value={currentTab} onChange={handleTabChange} variant="scrollable" scrollButtons={'auto'}>
              {phishingSimulationPackageExtended.simulations.map((simulation: PhishingSimulation, index: number) => (
                <MainTab
                  key={index}
                  label={simulation.name}
                  icon={
                    <Box>
                      <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={18} />
                    </Box>
                  }
                  iconPosition="start"
                />
              ))}
            </MainTabs>
          </MainTabsWrapper>
          {phishingSimulationPackageExtended.simulations.map((simulation: PhishingSimulation, index: number) => (
            <MainTabPage key={simulation.id} hidden={currentTab !== index}>
              <Box sx={{ minWidth: '500px' }}>
                <SimulationPagePreviews simulation={simulation} />
              </Box>
            </MainTabPage>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Card>
            <StyledBottomBox>
              <Typography fontSize={18} fontWeight={theme.typography.fontWeightBold}>
                {phishingSimulationPackageExtended.name}
              </Typography>
              <StyledActionsBox>
                <IconButton
                  onClick={() => {
                    handleSaveSimulation(phishingSimulationPackageExtended)
                  }}>
                  <FontAwesomeIcon
                    fontSize={20}
                    icon={
                      (saved_asset_packages.includes(phishingSimulationPackageExtended.id)
                        ? faHeartSolid
                        : faHeart) as IconProp
                    }
                    color={theme.palette.pink[500]}
                  />
                </IconButton>
                <IconButton
                  disabled={phishingSimulationPackageExtended.organization_id !== user?.current_client_id}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsDeletePopUpOpen(true)
                  }}>
                  <FontAwesomeIcon fontSize={20} icon={faTrash as IconProp} />
                </IconButton>
                <IconButton
                  disabled={phishingSimulationPackageExtended.organization_id !== user?.current_client_id}
                  onClick={(e) => {
                    handlePackageEdit(phishingSimulationPackageExtended)
                  }}>
                  <FontAwesomeIcon fontSize={20} icon={faPenToSquare as IconProp} />
                </IconButton>
              </StyledActionsBox>
            </StyledBottomBox>
            <SimulationPageTopics simulation={phishingSimulationPackageExtended} />
          </Card>
        </Grid>
        {isNEW && <FeedbackAnimation animationData={packageSaveAnimationData} />}
      </LayoutPageContent>
    )
  )
}

export const StyledTopicsBox = styled(Box)(() => ({
  marginTop: theme.spacing(4),
}))

export const StyledActionsBox = styled(Box)(() => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

export const StyledBottomBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export default SimulationPackagePage
