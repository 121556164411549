import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'

const updateDomain = async (domainPayload: any) => {
  const url = `${API_URL}/domains/${domainPayload._id}`
  const { data } = await axios.put(url, domainPayload, { withCredentials: true })

  return data.data
}

export const useUpdateDomain = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateDomain,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['domains'],
      })
    },
  })
}
