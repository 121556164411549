import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faImage } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography, styled } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UploadImageModal from '@/common/components/UploadImageModal/UploadImageModal'
import { theme } from '@/theme/theme'

type OrganizationSettingsProps = {
  image?: string
  handleOnFinishImageUpload: (url: string) => void
}

const UploadImageBox: FC<OrganizationSettingsProps> = ({ image, handleOnFinishImageUpload }) => {
  const { t } = useTranslation()

  const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState(image)

  const onFinishUpload = (imageUrl: string) => {
    handleOnFinishImageUpload(imageUrl)
    setImageUrl(imageUrl)
  }

  return (
    <>
      <UploadImageModal open={imageModalIsOpen} setOpen={setImageModalIsOpen} onFinishUpload={onFinishUpload} />
      <StyledLogoContainer
        src={imageUrl || ''}
        onClick={() => {
          setImageModalIsOpen(true)
        }}>
        <StyledUploadOnHover hasLogo={imageUrl !== ''}>
          <FontAwesomeIcon fontSize={40} icon={faImage as IconProp} />
          <Typography>
            {imageUrl !== ''
              ? t('settings.configurationTab.organizationSettings.replacePhoto')
              : t('settings.configurationTab.organizationSettings.uploadPhoto')}
          </Typography>
        </StyledUploadOnHover>
      </StyledLogoContainer>
    </>
  )
}

const StyledUploadOnHover = styled(Box)(({ hasLogo }: { hasLogo: boolean }) => ({
  background: `${theme.palette.black}60`,
  color: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: hasLogo ? '0%' : '50%',
  transition: 'opacity 0.5s',
  cursor: 'pointer',
  '&:hover': {
    opacity: '100%',
  },
}))

const StyledLogoContainer = styled(Box)(({ src }: { src: string }) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '200px',
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: '15px',
  flex: 1,
  overflow: 'clip',
}))

export default UploadImageBox
