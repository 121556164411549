import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { US_DATE_TIME_WITH_MONTH_NAME } from '@/api/constants'
import { useDynamicGroups } from '@/api/dynamic-groups/get'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import Table, { TalbeColumn } from '@/common/components/Table'
import { theme } from '@/theme/theme'
import { DynamicGroup } from '@/types/dynamicGroups'

type DynamicGroupsTabProps = {
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

const DynamicGroupsTab: FC<DynamicGroupsTabProps> = ({ queryParams, updateQueryParam }) => {
  const { data: queryData, isFetching } = useDynamicGroups(queryParams)
  const { results: dynamicGroups, total } = queryData || { total: 0, results: [] }

  const { t } = useTranslation()
  const navigateTo = useNavigate()

  //TODO Remvove this patch when stop sharing same queryParams for all tabs
  useEffect(() => {
    updateQueryParam({ limit: 25, skip: 0 })
  }, [dynamicGroups])

  const tableColumns: TalbeColumn[] = [
    {
      id: 'name',
      label: t('dynamicGroups.groupType'), //TODO add component
      component: (row: DynamicGroup) => (
        <Box display="flex" textAlign="left" alignItems="center" gap={0.5}>
          <FontAwesomeIcon icon={faAtom as IconProp} color={theme.palette.purple[500]} fontSize={18} />
          {row.name}
          <Tooltip title={row.description} placement="top">
            <FontAwesomeIcon icon={faInfoCircle as IconProp} />
          </Tooltip>
        </Box>
      ),
    },
    {
      id: 'count',
      label: t('dynamicGroups.totalMembers'),
    },
    {
      id: 'campaign_last_sent_at',
      label: t('dynamicGroups.lastCampaignSent'),
      format: (row: DynamicGroup) =>
        row?.campaign_last_sent_at ? dayjs.utc(row.campaign_last_sent_at).format(US_DATE_TIME_WITH_MONTH_NAME) : '-',
    },

    {
      id: 'awareness_score',
      label: t('dynamicGroups.score'),
      component: (row: DynamicGroup) => <HumanizedScore score={row.awareness_score} />,
    },
  ]

  const handleNavigateToGroupProfile = (id: string) => {
    navigateTo(`/recipients/dynamic-groups/${id}`)
  }

  return (
    <Table
      loading={isFetching}
      data={dynamicGroups}
      total={total}
      inactiveRowCondition={(row) => row.count === 0}
      queryParams={queryParams}
      updateQueryParam={updateQueryParam}
      columns={tableColumns}
      disabledPagination
      rowClick={(row: DynamicGroup) => {
        handleNavigateToGroupProfile(row.id)
      }}
    />
  )
}

export default DynamicGroupsTab
