import { FC, useCallback, useState } from 'react'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faFishingRod } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import SimulationPreview from '@/common/components/SimulationPreview/SimulationPreview'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'

type MultiSimPreviewTabsProps = {
  simulationsData: PhishingSimulation[]
}

const MultiSimPreviewTabs: FC<MultiSimPreviewTabsProps> = ({ simulationsData }) => {
  const [currentTab, setCurrentTab] = useState(0)

  const handleTabChange = useCallback(
    (_: React.ChangeEvent<{}>, index: number) => {
      setCurrentTab(index)
    },
    [setCurrentTab]
  )

  return (
    <>
      <MainTabsWrapper>
        <MainTabs value={currentTab} onChange={handleTabChange} variant="scrollable" scrollButtons={'auto'}>
          {simulationsData?.map((sim, index) => (
            <MainTab
              key={index}
              disableRipple
              icon={
                <Box>
                  <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={18} />
                </Box>
              }
              iconPosition="start"
              label={`${sim.name}`}
              value={index}
            />
          ))}
        </MainTabs>
      </MainTabsWrapper>

      {simulationsData?.map((sim, index) => (
        <MainTabPage key={index} hidden={currentTab !== index} width="100%">
          {index === currentTab && <SimulationPreview simulationsData={sim} />}
        </MainTabPage>
      ))}
    </>
  )
}

export default MultiSimPreviewTabs
