import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { ChangeEvent, ComponentProps, Dispatch, FC, SetStateAction, useState } from 'react'

import { VALID_CUSTOMIZE_MICRO_TRAINING_URL } from '@/api/constants'
import { EducationModule } from '@/api/education-modules/modules'
import { theme } from '@/theme/theme'
import { AssetType } from '@/types/campaigns'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import CampaignDetails from './CampaignDetails'

type AdvancedSettingsProps = Pick<
  ComponentProps<typeof CampaignDetails>,
  'assetMetadata' | 'setAssetMetadata' | 'errors' | 'setErrors' | 'dates' | 'setDates'
> & {
  distributionLogic: 'allAtOnce' | 'gradual'
  setDistributionLogic: Dispatch<SetStateAction<'allAtOnce' | 'gradual'>>
  customizeMicroTraining: {}
  setCustomizeMicroTraining: Dispatch<SetStateAction<{}>>
  campaignDurationInBusinessDays: number
  campaignType?: AssetType
  assets?: PhishingSimulation[] | EducationModule[]
}

const AdvancedSettings: FC<AdvancedSettingsProps> = ({
  distributionLogic,
  setDistributionLogic,
  customizeMicroTraining,
  setCustomizeMicroTraining,
  dates,
  setDates,
  errors,
  setErrors,
  campaignDurationInBusinessDays,
  assetMetadata,
  setAssetMetadata,
  campaignType = AssetType.training_video,
  assets,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  function handleDistributionLogicChange(e) {
    const value = e.target.value
    setDistributionLogic(value)
    if (value === 'allAtOnce') {
      setDates((prevState) => ({ ...prevState, span: 0 }))
    } else {
      setDates((prevState) => ({ ...prevState, span: 1 }))
    }
    setErrors({ ...errors, span: '' })
  }

  function handleSpanChange(e: ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value)
    if (value < 1) {
      return
    } else if (value > campaignDurationInBusinessDays - 1) {
      setErrors({ ...errors, span: t('launchWizard.errors.spanTooLong') })
      return
    } else {
      setErrors({ ...errors, span: '' })
      setDates((prevState) => ({
        ...prevState,
        span: value,
      }))
    }
  }

  const handleRadioChange = (assetId: string, value: string) => {
    setCustomizeMicroTraining((prevCustomizeMicroTraining: { [key: string]: string }) => {
      if (value === 'customURL') {
        return {
          ...prevCustomizeMicroTraining,
          [assetId]: prevCustomizeMicroTraining[assetId] || '',
        }
      } else {
        const { [assetId]: _, ...rest } = prevCustomizeMicroTraining
        return rest
      }
    })
  }

  const isValidURL = (url: string) => {
    try {
      new URL(url)
      return VALID_CUSTOMIZE_MICRO_TRAINING_URL.test(url)
    } catch (e) {
      return false
    }
  }

  const handleURLChange = (assetId: string, value: ChangeEvent<HTMLInputElement>) => {
    const newValue = value.target.value
    setCustomizeMicroTraining((prevCustomizeMicroTraining: { [key: string]: string }) => {
      if (newValue === '') {
        const { [assetId]: _, ...rest } = prevCustomizeMicroTraining
        return rest
      }
      if (!isValidURL(newValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [assetId]: t('launchWizard.errors.invalidURL'),
        }))
        return prevCustomizeMicroTraining
      } else {
        setErrors((prevErrors) => {
          const { [assetId]: _, ...rest } = prevErrors
          return rest
        })
        return {
          ...prevCustomizeMicroTraining,
          [assetId]: newValue,
        }
      }
    })
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      style={{ border: 'none' }}
      classes={{ root: classes.advancedSettingsRoot }}>
      <AccordionSummary
        style={{
          padding: 0,
          height: 30,
        }}
        aria-controls="advanced-settings-content"
        id="advanced-settings-header">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: expanded ? theme.palette.blueDianne[600] : theme.palette.blueDianne[900],
          }}>
          <Typography fontWeight={theme.typography.fontWeightBold}>
            {t('launchWizard.details.advancedSettings')}
          </Typography>
          <FontAwesomeIcon
            icon={(expanded ? faChevronDown : faChevronUp) as IconProp}
            style={{ paddingLeft: '10px' }}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <div>
          {campaignType === AssetType.phishing_simulation && (
            <>
              <Typography fontWeight={theme.typography.fontWeightSemiBold} padding={theme.spacing(1, 0)}>
                {t('launchWizard.advanced.microTraining.microTraining')}
              </Typography>
              <Grid container spacing={1}>
                {assets?.map((asset, index) => (
                  <Box key={index} display="flex" width="100%">
                    {assets.length > 1 && (
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography
                          textOverflow="ellipsis"
                          overflow="hidden"
                          display="block"
                          alignContent="center"
                          height="100%"
                          whiteSpace="nowrap"
                          padding={theme.spacing(0, 2)}>
                          {t('launchWizard.advanced.microTraining.forSimulation', {
                            simulationName: asset.name,
                          })}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={9} md={9}>
                      <RadioGroup
                        row
                        sx={{ alignItems: 'center' }}
                        value={customizeMicroTraining.hasOwnProperty(asset.id) ? 'customURL' : 'cywarenessMT'}
                        onChange={(e) => handleRadioChange(asset.id, e.target.value)}>
                        <FormControlLabel
                          value="cywarenessMT"
                          control={<Radio />}
                          label={t('launchWizard.advanced.microTraining.CywarenessMicroTraining')}
                        />
                        <Box whiteSpace="nowrap">
                          <FormControlLabel
                            value="customURL"
                            control={<Radio />}
                            label={t('launchWizard.advanced.microTraining.customURL')}
                          />
                          <InfoTooltip title={t('launchWizard.advanced.microTraining.toolTip')} fontSize={18} />
                          <StyledCustomizeURLTextField
                            disabled={!customizeMicroTraining.hasOwnProperty(asset.id)}
                            onChange={(value) => handleURLChange(asset.id, value)}
                            placeholder="Paste URL here"
                            error={!!errors[asset.id]}
                          />
                        </Box>
                        {customizeMicroTraining.hasOwnProperty(asset.id) && !!errors[asset.id] && (
                          <StyledFormHelperText error>{errors[asset.id]}</StyledFormHelperText>
                        )}
                      </RadioGroup>
                    </Grid>
                  </Box>
                ))}
              </Grid>
              <Divider sx={{ width: '755px', margin: theme.spacing(1, 0) }} />
            </>
          )}

          <Typography fontWeight={theme.typography.fontWeightSemiBold} padding={theme.spacing(1, 0)}>
            {t('launchWizard.advanced.distributionTimeFrame')}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="distribution logic"
              value={distributionLogic}
              onChange={handleDistributionLogicChange}>
              <FormControlLabel
                value="allAtOnce"
                control={<Radio color={'primary'} />}
                label={t('launchWizard.details.allAtOnce')}
              />
              <FormControlLabel
                value="gradual"
                control={<Radio color={'primary'} disabled={campaignDurationInBusinessDays < 2} />}
                label={
                  <div className={classes.flex}>
                    <FormLabel>{t('launchWizard.details.gradualSend')}</FormLabel>
                    <TextField
                      size="small"
                      disabled={distributionLogic !== 'gradual'}
                      className={classes.spanInput}
                      value={dates.span === 0 ? 1 : dates.span}
                      onChange={handleSpanChange}
                      inputProps={{
                        min: 1,
                        max: campaignDurationInBusinessDays,
                        type: 'number',
                      }}
                    />
                    <FormLabel>{t('launchWizard.details.businessDays')}</FormLabel>
                  </div>
                }
              />
            </RadioGroup>
            {campaignDurationInBusinessDays < 2 || errors.span ? (
              <FormHelperText error={!!errors.span}>
                {campaignDurationInBusinessDays < 2
                  ? t('launchWizard.errors.minimalDurationForGradualLaunch')
                  : errors.span}
              </FormHelperText>
            ) : null}
          </FormControl>
          <Divider sx={{ width: '755px', margin: theme.spacing(1, 0) }} />
          <Box>
            <Typography fontWeight={theme.typography.fontWeightSemiBold} padding={theme.spacing(1, 0)}>
              {t('launchWizard.advanced.localization')}
            </Typography>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dates.timezone === 'per_user'}
                    onChange={() => {
                      setDates((prevState) => ({
                        ...prevState,
                        timezone:
                          dates.timezone === 'per_user' ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'per_user',
                      }))
                      if (errors && (errors.recipients || errors.dates)) {
                        setErrors({ ...errors, recipients: '', dates: '' })
                      }
                    }}
                    name="timezone"
                  />
                }
                label={t('launchWizard.details.defaultTimezone')}
              />
              {campaignType === AssetType.training_video ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assetMetadata.preferUserLanguage}
                      onChange={() =>
                        setAssetMetadata((prevState) => ({
                          ...prevState,
                          preferUserLanguage: !assetMetadata.preferUserLanguage,
                        }))
                      }
                      name="language"
                    />
                  }
                  label={t('launchWizard.details.defaultLanguage')}
                />
              ) : null}
            </Box>
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const StyledFormHelperText = styled(FormHelperText)(() => ({
  marginLeft: theme.spacing(2),
}))

const StyledCustomizeURLTextField = styled(TextField)(() => ({
  margin: 0.5,
  '.MuiInputBase-input': {
    padding: theme.spacing(0.8, 1),
    borderRadius: '10px',
    '&.Mui-disabled': {
      background: theme.palette.grey[300],
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '10px',
  },
}))

const useStyles = makeStyles((theme) => ({
  advancedSettingsRoot: {
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 'auto',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  timezoneCheckbox: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
    '& .MuiFormControlLabel-root:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  spanInput: {
    margin: theme.spacing(0, 1, 0, 1),
    width: 50,
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  spanFormControl: {
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
  timezone: {
    width: 200,
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
    },
  },
}))

export default AdvancedSettings
