import styled from '@emotion/styled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box } from '@mui/material'
import { FC } from 'react'

import { theme } from '@/theme/theme'
import Step from './Step'
import StepTransition from './StepTransition'

type Step = {
  title?: string
  icon?: IconProp
  completed?: boolean
}

type StepsProgressParams = {
  steps: Step[]
  lastStateOpen: number
}
const StepsProgress: FC<StepsProgressParams> = ({ steps, lastStateOpen }) => {
  return (
    <StyledSteps>
      {steps.flatMap((step, index) => [
        !!index && <StepTransition marked={index < lastStateOpen} />,
        <Step
          completed={step.completed}
          marked={index < lastStateOpen}
          index={index + 1}
          title={step.title}
          icon={step.icon}
        />,
      ])}
    </StyledSteps>
  )
}

const StyledSteps = styled(Box)(() => ({
  display: 'flex',
  background: theme.palette.grey[100],
  height: '24px',
  alignItems: 'flex-end',
  margin: '160px 40px 16px ',
  padding: '6px 0px',
}))

export default StepsProgress
