import { theme } from '@/theme/theme'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const StyledStepIconCircle = styled(Box)(({ done }: { done?: boolean }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: done ? theme.palette.green[400] : theme.palette.blueGray[50],
  color: done ? theme.palette.white : theme.palette.blueDianne[900],
  width: '27px',
  height: '27px',
  borderRadius: '100%',
  fontWeight: theme.typography.fontWeightSemiBold,
}))

export const StyledStepSection = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}))
