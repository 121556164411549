import { Box, Skeleton, Typography, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useInterval from '@/common/hooks/useInterval'
import { AssetType, Campaign, CampaignStatus } from '@/types/campaigns'
import { theme } from '@/theme/theme'
import { US_DATE_TIME_WITH_MONTH_NAME } from '@/api/constants'

type CountdownProps = {
  campaign: Campaign
}

const Countdown: FC<CountdownProps> = ({ campaign }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const startsAt = moment(campaign?.schedule.start)
  const endsAt = moment(campaign?.schedule.end)

  const completed = moment().diff(startsAt, 'seconds')
  const length = endsAt.diff(startsAt, 'seconds')
  const secondsRemaining = length - completed
  const hasEnded = moment().isAfter(endsAt)
  const hasStarted = moment().isAfter(startsAt)

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  //TODO Refactor the Countdown component. It consisnt of lots of logic and the real Coundown in only part of it.
  if (campaign?.status === CampaignStatus.canceled) {
    return (
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={theme.spacing(1)}>
        <Typography fontWeight={theme.typography.fontWeightMedium}>
          {t('activeBaseline.countdown.originalSchedule')}
        </Typography>
        <Box display="flex" gap={theme.spacing(0.5)} justifyContent="center" flexWrap="wrap">
          <Typography fontWeight={theme.typography.fontWeightMedium}>
            {t('activeBaseline.countdown.startDate')}
          </Typography>
          <Typography className={classes.date} fontWeight={theme.typography.fontWeightBold}>
            {campaign ? moment(startsAt).format(US_DATE_TIME_WITH_MONTH_NAME) : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" gap={theme.spacing(0.5)} justifyContent="center" flexWrap="wrap">
          <Typography fontWeight={theme.typography.fontWeightMedium}>
            {' '}
            {t('activeBaseline.countdown.endDate')}
          </Typography>
          <Typography className={classes.date} fontWeight={theme.typography.fontWeightBold}>
            {campaign ? moment(endsAt).format(US_DATE_TIME_WITH_MONTH_NAME) : <Skeleton width={100} />}
          </Typography>
        </Box>

        <div className={classes.divider} />
        <Box display="flex" gap={theme.spacing(0.5)} justifyContent="center" flexWrap="wrap">
          <Typography fontWeight={theme.typography.fontWeightMedium} whiteSpace="nowrap">
            {t('activeBaseline.countdown.canceledOn')}
          </Typography>
          <Typography className={classes.date} fontWeight={theme.typography.fontWeightBold}>
            {campaign ? moment(campaign.updated_at).format(US_DATE_TIME_WITH_MONTH_NAME) : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Box>
    )
  }

  const convertToTime = (secondsRemaining: number) => {
    const days = Math.floor(secondsRemaining / (3600 * 24))
    const hours = Math.floor((secondsRemaining % (3600 * 24)) / 3600)
    const minutes = Math.floor((secondsRemaining % 3600) / 60)
    const seconds = Math.floor(secondsRemaining % 60)

    return { days, hours, minutes, seconds }
  }

  const getEndingTitle = () => {
    if (hasEnded) {
      return campaign.asset_type === AssetType.training_video
        ? t('activeBaseline.countdown.campaignEnded')
        : t('activeBaseline.countdown.ended')
    }

    return t('activeBaseline.countdown.endsIn')
  }

  useInterval(() => {
    setTimeRemaining(convertToTime(secondsRemaining))
  }, 1000)

  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={theme.spacing(1)}>
      <Typography fontWeight={theme.typography.fontWeightMedium}>
        {hasStarted ? t('activeBaseline.countdown.started') : t('activeBaseline.countdown.scheduled')}
      </Typography>
      <Typography className={classes.date} fontWeight={theme.typography.fontWeightBold}>
        {campaign ? moment(startsAt).format(US_DATE_TIME_WITH_MONTH_NAME) : <Skeleton width={100} />}
      </Typography>
      <div className={classes.divider} />
      <Typography fontWeight={theme.typography.fontWeightMedium}>{getEndingTitle()}</Typography>
      {!campaign ? (
        <Skeleton width={200} />
      ) : secondsRemaining > 0 ? (
        <Box className={classes.counter}>
          <div className={classes.countContainer}>
            <CountTypography>{timeRemaining.days}</CountTypography>
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              {t('activeBaseline.countdown.time.day', {
                count: timeRemaining.days,
              })}
            </Typography>
          </div>
          <div className={classes.countContainer}>
            <CountTypography>{timeRemaining.hours}</CountTypography>
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              {t('activeBaseline.countdown.time.hour', {
                count: timeRemaining.hours,
              })}
            </Typography>
          </div>
          <div className={classes.countContainer}>
            <CountTypography>{timeRemaining.minutes}</CountTypography>
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              {t('activeBaseline.countdown.time.minute', {
                count: timeRemaining.minutes,
              })}
            </Typography>
          </div>

          <div className={classes.countContainer}>
            <CountTypography>{timeRemaining.seconds}</CountTypography>
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              {t('activeBaseline.countdown.time.second', {
                count: timeRemaining.seconds,
              })}
            </Typography>
          </div>
        </Box>
      ) : (
        <Typography fontSize={22} fontWeight={theme.typography.fontWeightBold}>
          {moment(endsAt).format(US_DATE_TIME_WITH_MONTH_NAME)}
        </Typography>
      )}
    </Box>
  )
}

const CountTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '22px',
  color: 'inherit',
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    counter: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    countContainer: {
      textAlign: 'center',
    },
    divider: {
      height: 1,
      width: '70%',
      background: theme.palette.divider,
      margin: theme.spacing(1, 0),
    },
    date: {
      textTransform: 'uppercase',
    },
  })
)

export default Countdown
