import { Box, styled } from '@mui/material'

import { theme } from '@/theme/theme'

const MainTabsWrapper = styled(Box)(() => ({
  borderRadius: '15px 15px 0px 0px',
  width: 'fit-content',
  background: theme.palette.white,
}))

export default MainTabsWrapper
