import { useCallback, useEffect, useState } from 'react'
import { object, Schema, StringSchema, ValidationError } from 'yup'
import { FormProps } from './Form'
import { isEmpty, isArray } from 'lodash'
import { GridProps, OutlinedTextFieldProps } from '@mui/material'

export type Field = {
  name: string
  label?: string
  placeholder?: string
  initialValue?: string | string[] | number | boolean
  validationRule?: StringSchema<string>
  type?: string
  autoComplete?: string
  required?: boolean
  autoFocus?: boolean
  onChange?: (value: string) => string
  disabled?: (value: any) => boolean
  gridItemProps?: GridProps
  textFieldProps?: OutlinedTextFieldProps
  options?: { label: string; value: string }[]
  isAutoComplete?: boolean
  multiple?: boolean
  transform?: (value: string) => string
}

//TODO Deprecated, consider refactoring
type useFormProps = {
  fields: Field[]
  onSubmit: (values: { [name: string]: string | string[] | number }) => void
  clearOnSubmit?: boolean
  onContentChange?: (value: string) => void
}

export default function useForm({ fields, onSubmit, clearOnSubmit = true }: useFormProps) {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState<{
    [field: string]: ValidationError | null
  }>({})
  const [schema, setSchema] = useState<null | Schema>(null)

  const handleChange = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: isArray(value)
        ? value.map((v) => {
            return v?.value ? v.value : v
          })
        : value?.value
        ? value.value
        : value,
    }))
    setErrors((prevState) => ({ ...prevState, [name]: null }))
  }

  async function handleSubmit() {
    const validationErrors = {}
    const transformedValues = { ...values }

    // Apply transformation for 'username' or 'email' fields
    fields.forEach((field) => {
      if (field.name === 'username' || field.name === 'email') {
        transformedValues[field.name] = (transformedValues[field.name] as string).toLowerCase()
      }
    })

    try {
      await schema?.validate(transformedValues, { abortEarly: false })
    } catch (error) {
      console.log(error)
      error?.inner?.forEach((error) => {
        validationErrors[error.path] = error
      })
    }
    if (isEmpty(validationErrors)) {
      onSubmit(transformedValues)
      clearOnSubmit && initForm()
      return transformedValues
    }
    setErrors(validationErrors)
  }

  const initForm = useCallback(() => {
    const initErrors = {}
    const initValues = {}
    const initSchema = {}
    fields.forEach((field) => {
      initErrors[field.name] = null
      initValues[field.name] = field?.initialValue || ''
      initSchema[field.name] = field?.validationRule
    })
    setErrors(initErrors)
    setValues(initValues)
    setSchema(object().shape(initSchema))
  }, [fields])

  useEffect(() => {
    initForm()
  }, [initForm])

  const formProps: FormProps = {
    fields,
    values,
    errors,
    handleChange,
  }
  return { handleSubmit, formProps, clearForm: initForm }
}
