import { Alert, AlertTitle, Box, Button } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import StepSubmit from './StepSubmit'
import UploadLoading from './UploadLoading'

type Step2Props = {
  formatErrors: any
  isLoading: boolean
  handleClose: () => void
  setStep: (step: number) => void
}

const Step2: FC<Step2Props> = ({ formatErrors, isLoading, handleClose, setStep }) => {
  const { t } = useTranslation()

  return (
    <>
      {isLoading ? (
        <UploadLoading title="Validating" />
      ) : (
        <>
          {formatErrors && Object.keys(formatErrors).length === 0 ? (
            <Alert severity="success">{t('bulkUserUpload.csvSuccessfullyValidated')}</Alert>
          ) : (
            <Box display="flex" flexDirection="column" gap={2}>
              <p>{t('bulkUserUpload.csvFileValidationHasIssues')}</p>
              {formatErrors?.format_error && <Alert severity="error">{formatErrors.format_error}</Alert>}

              {formatErrors?.duplicate_email && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.duplicateEmail.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.duplicateEmail.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.duplicate_email} />
                </Alert>
              )}
              {formatErrors?.duplicate_phone_number && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.duplicatePhoneNumber.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.duplicatePhoneNumber.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.duplicate_phone_number} />
                </Alert>
              )}

              {formatErrors?.invalid_email && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.invalidEmail.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.invalidEmail.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.invalid_email} />
                </Alert>
              )}

              {formatErrors?.invalid_phone_number && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.invalidPhoneNumber.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.invalidPhoneNumber.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.invalid_phone_number} />
                </Alert>
              )}

              {formatErrors?.group_not_found && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.groupNotFound.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.groupNotFound.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.group_not_found} />
                </Alert>
              )}

              {formatErrors?.office_not_found && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.officeNotFound.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.officeNotFound.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.office_not_found} />
                </Alert>
              )}

              {formatErrors?.missing_required_field && (
                <Alert severity="error">
                  <AlertTitle>{t('bulkUserUpload.uploadValidationErrors.missingRequiredFields.title')}</AlertTitle>
                  <p>{t('bulkUserUpload.uploadValidationErrors.missingRequiredFields.message')}</p>
                  <strong>{t('bulkUserUpload.uploadValidationErrors.location')} - </strong>
                  <ErrorColumns errorArray={formatErrors.missing_required_field} />
                </Alert>
              )}
            </Box>
          )}
        </>
      )}

      <StepSubmit
        handleClose={handleClose}
        confirmHandler={() => setStep(3)}
        isSubmitDisabled={isLoading || Object.keys(formatErrors).length > 0}
        // Hidden - missing BE
        // ExtraButton={
        //   !isLoading && Object.keys(formatErrors).length ? (
        //     <Button variant="outlined" onClick={() => {}}>
        //       {t('bulkUserUpload.sendErrorsToEmail')}
        //     </Button>
        //   ) : undefined
        // }
      />
    </>
  )
}

const ErrorColumns = ({ errorArray }: any) =>
  Object.keys(errorArray).map((col, k1) => (
    <span key={k1}>
      <strong>Column {col}: </strong>
      {errorArray[col]
        .flat() // For now don't need to support nested arrays
        .map((row: number) => `Row ${row}`)
        .join(', ')}{' '}
    </span>
  ))

export default Step2
