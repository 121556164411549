import { useState, useCallback, SyntheticEvent, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToArc, faPlus } from '@fortawesome/pro-light-svg-icons'
import TextField from '@/common/components/Inputs/TextField'
import Autocomplete from '@/common/components/Inputs/Autocomplete'

interface Item<T> {
  key: string
  value: T | null
}

interface DepartmentMapperProps<T> {
  keyLabel: string
  keyPlaceholder: string
  valueLabel: string
  valuePlaceholder: string
  loading: boolean
  options: T[]
  value: Item<T>[]
  getOptionLabel: (option: T) => string
  isOptionEqualToValue?: (option: T, value: T) => boolean
  onChange: (value: Record<string, T>) => void
}

const DepartmentMapper = <T,>({
  keyLabel,
  keyPlaceholder,
  valueLabel,
  valuePlaceholder,
  loading,
  options,
  value,
  isOptionEqualToValue,
  getOptionLabel,
  onChange,
}: DepartmentMapperProps<T>) => {
  const [state, setState] = useState<Item<T>[]>((value.length > 0 && value) || [{ key: '', value: null }])

  const addNewRecord = () => {
    setState((prevState) => [...prevState, { key: '', value: null }])
  }

  const handleKeyChange = useCallback(
    (index) => (event) => {
      const newKey = event.target.value
      setState((prevState) => {
        const updatedItems = [...prevState]

        const itemToUpdate = updatedItems[index]
        if (itemToUpdate) {
          updatedItems[index] = { ...itemToUpdate, key: newKey }
        }

        return updatedItems
      })
    },
    [setState]
  )

  const handleValueChange = useCallback(
    (index) => (event: SyntheticEvent<Element, Event>, value: T | null, reason: AutocompleteChangeReason) => {
      setState((prevItems) => {
        const updatedItems = [...prevItems]
        updatedItems[index] = { ...updatedItems[index], value: value }

        return updatedItems
      })
    },
    [setState]
  )

  useEffect(() => {
    const value = state
      .filter((item) => item.key !== '' && !!item.value)
      .reduce((accumulator, current) => {
        accumulator[current.key] = current.value
        return accumulator
      }, {})
    if (Object.keys(value).length > 0) {
      onChange(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Box sx={{ display: 'flex', gap: 1.3, flexDirection: 'column' }}>
      {state.map(({ key, value }, index) => (
        <Grid container key={`item-${index}`} alignItems="center">
          <Grid item xs={5}>
            <TextField
              disableFormik
              label={keyLabel}
              name="name"
              placeholder={keyPlaceholder}
              value={key}
              onChange={handleKeyChange(index)}
            />
          </Grid>
          <Grid item display="flex" justifyContent="center" xs={1}>
            <FontAwesomeIcon style={{ fontSize: '25px' }} icon={faArrowRightToArc} />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              disableFormik
              label={valueLabel}
              value={value}
              placeholder={valuePlaceholder}
              loading={loading}
              name="value"
              options={options || []}
              getOptionLabel={getOptionLabel}
              onChange={handleValueChange(index)}
              isOptionEqualToValue={isOptionEqualToValue}
            />
          </Grid>
          <Grid item display="flex" justifyContent="center" xs={1}>
            <Box>
              <Button
                disableRipple
                variant="outlined"
                sx={{
                  width: 36,
                  height: 38,
                  minWidth: 36,
                  minHeight: 38,
                  padding: 0,
                  borderColor: '#c4c4c4',
                  borderRadius: '12px',
                }}>
                <FontAwesomeIcon icon={faPlus} onClick={() => addNewRecord()} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}

export default DepartmentMapper
