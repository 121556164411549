import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faRotateRight, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import MuiCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'

import { MONTH_DAY } from '@/api/constants'
import { IntegrationType } from '@/api/integrations/integrations'
import { FC } from 'react'

interface CardProps {
  integration: IntegrationType | undefined
  icon: string
  label: string
  onSync: (param: string | undefined) => Promise<void>
  onClick: () => void
  onDelete: (param: string | undefined) => Promise<void>
}

const formatTimestamp = (timestamp) => {
  if (timestamp && dayjs(timestamp).isValid()) {
    return dayjs(timestamp).format(MONTH_DAY)
  }
  return '-'
}

const getStatus = (theme, status) => {
  const statusMap = {
    Successful: {
      text: 'Successfully',
      color: theme.palette.green[500],
    },
    Failed: {
      text: 'Failed',
      color: theme.palette.red[500],
    },
    Stopped: {
      text: 'Stopped',
      color: theme.palette.warning.main,
    },
    null: {
      text: '-',
      color: theme.palette.text.primary,
    },
    default: {
      text: status || '-',
      color: theme.palette.text.primary,
    },
  }

  return statusMap[status] || statusMap.default
}

const IntegrationCard: FC<CardProps> = ({ icon: Icon, label, integration, onSync, onClick, onDelete }) => {
  const theme = useTheme()
  const lastSyncAt = formatTimestamp(integration?.last_execution_timestamp)
  const lastExecutionStatus = integration?.last_execution_status

  const { text: statusText, color: statusColor } = getStatus(theme, lastExecutionStatus)

  return (
    <StyledIntegrationCard>
      <IntegrationCardContent>
        <CardHeader
          sx={{ p: 0 }}
          action={
            <>
              <IconButton onClick={() => onSync(integration?.id)}>
                <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRotateRight as IconProp} />
              </IconButton>
              <IconButton onClick={() => onDelete(integration?.id)}>
                <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faTrash as IconProp} />
              </IconButton>
            </>
          }
          title={null}
        />
        <Box onClick={onClick}>
          <CardMedia sx={{ fontSize: 'initial' }}>{Icon && <img src={Icon} width={'110'} />}</CardMedia>
          <Typography
            sx={{
              mt: '15px',
              fontSize: 16,
              fontWeight: 500,
            }}>
            {label}
          </Typography>
          <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
            Last Sync: {lastSyncAt}{' '}
            <Typography component="span" sx={{ fontSize: 10, fontWeight: 500, color: statusColor }}>
              {statusText}
            </Typography>
          </Typography>
        </Box>
      </IntegrationCardContent>
    </StyledIntegrationCard>
  )
}

const StyledIntegrationCard = styled(MuiCard)(() => ({
  minWidth: '250px',
  height: '250px',
  borderRadius: '10px',
  textAlign: 'center',
  boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    cursor: 'pointer',
  },
}))

const IntegrationCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}))

export default IntegrationCard
