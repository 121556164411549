import { Button, Card, Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { usePhishingSimulationDelete } from '@/api/phishing-simulations/delete'
import { usePhishingSimulation } from '@/api/phishing-simulations/get'
import { useToggleSavedContent } from '@/api/profile/toggle-saved-asset'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import useToast from '@/common/hooks/useToast'
import { AssetType } from '@/types/campaigns'
import { PhishingSimulation } from '@/types/phishingSimulations'
import SimulationPageDetails from './SimulationPageDetails'
import SimulationPagePreviews from './SimulationPagePreviews'
import SimulationPageStats from './SimulationPageStats'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'

const SimulationPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id = '' } = useParams<'id'>()
  const { errorToast, successToast } = useToast()

  const [saveAsset] = useToggleSavedContent()

  const { data: simulation, isPending } = usePhishingSimulation(id)
  const { mutateAsync: deletePhishingSimulation } = usePhishingSimulationDelete()

  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)

  const handleDeleteSimulation = async (simulation: PhishingSimulation) => {
    if (!simulation) return

    try {
      await deletePhishingSimulation(simulation.id)
      successToast(t('simulation.toast.deleted'))

      navigate('/content-library/simulations')
    } catch (error) {
      errorToast(t('simulation.toast.failedToDelete'))
    }
  }

  const handleEditSimulation = async (simulation: PhishingSimulation) => {
    navigate(`/content-library/simulations/edit/${simulation.id}`)
  }

  const handleSaveSimulation = async (simulation: PhishingSimulation) => {
    try {
      await saveAsset(simulation.id)
    } catch (error) {
      errorToast(t('simulationLibrary.errors.failedToSave'))
      console.log(error)
    }
  }

  if (!simulation && !isPending) {
    navigate('/content-library/simulations')
  }

  if (!simulation) {
    return <LoadingContainer />
  }

  return (
    <LayoutPageContent minWidth={600}>
      <LaunchWizard
        campaignType={AssetType.phishing_simulation}
        open={launchWizardIsOpen}
        onClose={() => setLaunchWizardIsOpen(false)}
        assets={[simulation]}
      />
      <PageHeader>
        <Button onClick={() => setLaunchWizardIsOpen(true)} variant={'contained'}>
          {t('videoLibrary.launch')}
        </Button>
      </PageHeader>
      <Grid item xs={12}>
        <Card>
          <SimulationPagePreviews simulation={simulation} />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SimulationPageStats simulation={simulation} />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SimulationPageDetails
            simulation={simulation}
            onDeleteSimulation={handleDeleteSimulation}
            onSaveSimulation={handleSaveSimulation}
            onEditSimulation={handleEditSimulation}
          />
        </Card>
      </Grid>
    </LayoutPageContent>
  )
}

export default SimulationPage
