import { Box, Checkbox, Link, Paper, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { EducationModule } from '@/api/education-modules/modules'
import { Member, useMembers } from '@/api/members/get'
import { User } from '@/api/users/users'
import inactiveMembersAnimation from '@/assets/lottie/melting.json'
import useQueryParams from '@/common/hooks/useQueryParams'
import { Targets } from '@/types/campaigns'
import { RowDataItem, SortOrder } from '@/types/common'
import { PhishingSimulation, PhishingSimulationVector } from '@/types/phishingSimulations'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '../StatusFilter/StatusFilter'
import Table, { TalbeColumn } from '../Table'
import GroupCellComponent from '../Table/CellComponents/GroupCellComponent'
import OfficComponentCell from '../Table/CellComponents/OfficeCellComponent'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'
import { RecepientsType } from './UserPicker'
import DistributionSelect from './DistibutionSelect'

type LaunchWizardUsersTableProps = {
  handleAddUsers: (userIds: string[], results: Member[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  selectedUsers: string[]
  selectedGroups: string[]
  selectedOffices: string[]
  selectedDynamicGroupsMembers: string[]
  clientEnforceDomain: boolean
}

const INITIAL_QUERY_FILTERS = {
  username: '',
  full_name: '',
  invited_by: '',
  search: '',
  sort_by: 'full_name',
  sort_order: SortOrder.asc,
  status: ['active'],
  skip: 0,
  limit: 10,
}

const LaunchWizardUsersTable: FC<LaunchWizardUsersTableProps> = ({
  handleAddUsers,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  assets,
  selectedUsers,
  selectedGroups,
  selectedOffices,
  selectedDynamicGroupsMembers,
  clientEnforceDomain,
}) => {
  const { t } = useTranslation()

  const onlyAssetsWithEmail =
    assets?.filter((a) => a.vectors?.includes(PhishingSimulationVector.Email)).length === assets?.length
  const onlyAssetsWithPhone = assets?.every((a) =>
    a.vectors?.every((vector: PhishingSimulationVector) => vector === 'sms' || vector === 'whatsapp')
  )

  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const { data: queryData, isFetching } = useMembers(queryParams) // TODO add as MembersListQuery
  const { results: members, total } = queryData || { total: 0, results: [] }

  const isPackage = assets && assets.length > 1

  const isMemberSelected = useCallback(
    (member: Member) =>
      selectedUsers.includes(member._id) ||
      member.member_of.some((group) => selectedGroups.includes(group)) ||
      selectedOffices.includes(member.office_id) ||
      selectedDynamicGroupsMembers.includes(member._id) ||
      false,
    [selectedUsers, selectedGroups, selectedOffices, selectedDynamicGroupsMembers]
  )

  const handleSearchChange = useCallback(
    (value: string) => {
      updateQueryParam({ search: value })
    },
    [updateQueryParam]
  )

  const handleStatusChange = (newStatus: string[]) => {
    updateQueryParam({ status: newStatus })
  }

  const handleSelecctAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = members.filter((m) => !isDisabled(m))
      handleAddUsers(
        newSelected.map((m) => m._id),
        newSelected
      )
    } else {
      handleRemoveFromList(members.map((m) => m._id))
    }
  }

  const rowClick = (row: RowDataItem) => {
    if (isDisabled(row)) return

    if (selectedUsers.includes(row._id)) {
      handleRemoveFromList(row._id)
    } else {
      handleAddUsers([row._id], [{ ...(row as User) }])
    }
  }

  const tableColumns: TalbeColumn[] = useMemo(() => {
    const columns = [
      {
        id: 'custom_checkbox',
        label: '',
        headerComponent: () => {
          const isAnyUserDisabled = members?.some((m) => isDisabled(m))

          return (
            <Tooltip
              title={t('launchWizard.usersTable.tooltips.containsWarnings')}
              placement={'right'}
              disableHoverListener={!isAnyUserDisabled}>
              <Checkbox
                color="primary"
                checked={members.every((m) => selectedUsers.includes(m._id))}
                onChange={handleSelecctAll}
              />
            </Tooltip>
          )
        },
        component: (row: Member) => (
          <Tooltip
            title={<TableBodyTooltip member={row} onlyAssetsWithPhone={onlyAssetsWithPhone} />}
            placement={'right'}
            disableHoverListener={!isDisabled(row)}>
            <Checkbox
              color="primary"
              checked={isMemberSelected(row)}
              inputProps={{
                'aria-labelledby': `table-checkbox-${row._id}`,
              }}
            />
          </Tooltip>
        ),
      },
      {
        id: 'first_name',
        label: t('users.usersTable.name'),
        sortable: true,
        format: (row: Member) => `${row.first_name} ${row.last_name}`,
      },
      {
        id: 'office_id',
        label: t('users.usersTable.office'),
        component: (row: Member) => <OfficComponentCell id={row.office_id} />,
      },
      {
        id: 'groups',
        label: t('users.usersTable.groups'),
        component: (row: Member) => <GroupCellComponent memberOf={row.member_of} />,
      },
      {
        id: 'awareness_score',
        sortable: true,
        label: t('users.usersTable.score'),
        component: (row: Member) => <HumanizedScore score={row.awareness_score} />,
      },
    ]

    if (isPackage) {
      columns.splice(4, 0, {
        id: 'distribution',
        label: t('users.usersTable.distribution'),
        component: (row: RowDataItem) => (
          <DistributionSelect
            type="users"
            onChange={handleDistributionTargetsChange}
            itemId={row._id}
            selectedItems={distributionTargets.users}
            assets={assets}
            disabled={isDisabled(row)}
            disableUnverifiedDomain={!row.domain_verified}
          />
        ),
      })
    }

    return columns
  }, [selectedUsers, members, handleSelecctAll])

  //TODO Move to BE
  const isDisabled = (row: RowDataItem) => {
    const memberDomainAllowed = !clientEnforceDomain || (clientEnforceDomain && row.domain_verified)

    return (
      row.state === 'inactive' ||
      !memberDomainAllowed ||
      (onlyAssetsWithEmail && !row.domain_verified) ||
      (!row.phone_number && onlyAssetsWithPhone) ||
      false
    )
  }

  return (
    <>
      <div style={{ marginBottom: 1, display: 'flex' }}>
        <SimpleSearchBar onChange={handleSearchChange} />
        <StatusFilter onChange={handleStatusChange} values={queryParams.status} label="Status" />
      </div>
      <Paper sx={{ overflowX: 'scroll', 'scrollbar-width': 'thin' }}>
        <Table
          loading={isFetching}
          data={members}
          total={total}
          customSelect
          queryParams={queryParams}
          updateQueryParam={updateQueryParam}
          columns={tableColumns}
          inactiveRowCondition={isDisabled}
          noResultsComponent={<NoResults activeMembers={members?.length} totalMembers={total} />}
          rowClick={rowClick}
        />
      </Paper>
    </>
  )
}

type NoResultsProps = {
  activeMembers: number | undefined
  totalMembers: number
}

const NoResults = ({ activeMembers, totalMembers }: NoResultsProps) => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation animation={activeMembers || 0 > totalMembers ? inactiveMembersAnimation : undefined}>
      <p>{t(activeMembers || 0 > totalMembers ? 'noResults.imactiveMembers' : 'noResults.noRecipiants')}</p>
      <p>
        <Trans
          i18nKey={activeMembers || 0 > totalMembers ? 'noResults.goToMembers' : 'noResults.goToRecipiants'}
          components={{ 1: <NavLink to={'/recipients/members'} />, 2: <NavLink to={'/settings/admins'} /> }}
        />
      </p>
    </InTableMessageWithAnimation>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

//TODO Move to table cell compoent
const TableBodyTooltip: React.FC<{ member: Member; onlyAssetsWithPhone: boolean }> = ({
  member,
  onlyAssetsWithPhone,
}) => {
  const classes = useStyles()
  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={
          member.state === 'inactive'
            ? 'launchWizard.usersTable.tooltips.inactiveMembers.description'
            : !member.phone_number && onlyAssetsWithPhone
            ? 'launchWizard.usersTable.tooltips.invalidPhoneNumber.description'
            : 'launchWizard.usersTable.tooltips.unverifiedDomains.description'
        }
        components={{
          1: (
            <Link
              href={
                member.state === 'inactive'
                  ? '../../recipients/members'
                  : !member.phone_number && onlyAssetsWithPhone
                  ? `../../recipients/users/${member._id}`
                  : '../../settings/allowlisting'
              }
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default LaunchWizardUsersTable
