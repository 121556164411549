import { Box, styled } from '@mui/material'
import { FC } from 'react'

import { theme } from '@/theme/theme'

type StepTransitionParams = {
  marked?: boolean
}

const StepTransition: FC<StepTransitionParams> = ({ marked }) => {
  return (
    <StyledPipe>
      <StyledFill marked={marked} />
    </StyledPipe>
  )
}

const StyledPipe = styled(Box)(() => ({
  background: theme.palette.grey[400],
  width: '100%',
  height: '12px',
  zIndex: 1,
  margin: '0px 7px',
}))
const StyledFill = styled(Box)(({ marked }: { marked?: boolean }) => ({
  background: theme.palette.cyan[500],
  width: marked ? '100%' : '0%',
  height: '12px',
  transition: 'width 1s ease',
}))

export default StepTransition
