import { faExclamationSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

import { theme } from '@/theme/theme'

const HelperTextComponent: FC<{ hasError?: boolean; helperText?: ReactNode; sx?: any }> = ({
  hasError,
  helperText,
  sx,
}) => {
  return hasError ? (
    <Typography
      display="flex"
      alignItems="center"
      color={theme.palette.blueDianne[900]}
      gap={1}
      paddingTop={0.5}
      fontSize="12px"
      sx={sx}>
      <FontAwesomeIcon icon={faExclamationSquare} color={theme.palette.red[900]} fontSize={18}></FontAwesomeIcon>
      {helperText}
    </Typography>
  ) : (
    <></>
  )
}

export default HelperTextComponent
