import { Grid, styled } from '@mui/material'
import { FC, ReactNode } from 'react'

import { theme } from '@/theme/theme'

type PageLayoutParams = {
  children: ReactNode
  minWidth?: number
}

const LayoutPageContent: FC<PageLayoutParams> = ({ children, minWidth }) => {
  return (
    <StyledGrid container spacing={4} minWidth={minWidth}>
      {children}
    </StyledGrid>
  )
}

const StyledGrid = styled(Grid)(() => ({
  overflowX: 'hidden',
  padding: theme.spacing(4),
  // alignContent: 'flex-start',
}))

export default LayoutPageContent
