import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Link,
  Switch,
  Typography,
} from '@mui/material'
import { Form, Formik, useFormikContext } from 'formik'
import { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
// @ts-ignore
import * as Yup from 'yup'

import { ALL_DATA_LIMIT, DOMAIN_IP_REGEX } from '@/api/constants'
import { useDomains } from '@/api/domains/get'
import { useSendInstructions } from '@/api/domains/sendInstructions'
import { useUpdateDomain } from '@/api/domains/update'
import { useVerifyDomainConfig } from '@/api/domains/verifyConfig'
import { getErrorMessage } from '@/api/utils/get-error'
import GoogleWorkspace from '@/assets/images/Integrations/Types/AdGoogleWorkspace.svg'
import AdOnPremise from '@/assets/images/Integrations/Types/AdOnPremise.svg'
import Office365 from '@/assets/images/Integrations/Types/Office365.svg'
import domain from '@/assets/images/popup/domain.svg'
import { ColunmsItem, FormSection, TwoColunmsLayout } from '@/common/components/Form/TwoColumnsLayout'
import InfoAlert from '@/common/components/InfoAlert/InfoAlert'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import Checkbox from '@/common/components/Inputs/Checkbox'
import RadioGroup from '@/common/components/Inputs/RadioGroup'
import Select from '@/common/components/Inputs/Select'
import TextField from '@/common/components/Inputs/TextFieldV2'
import Popup from '@/common/components/Popup'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { SortOrder } from '@/types/common'
import { Domain, DomainDeliveryConfiguration, DomainQuerySort, MailboxType, MessageDeliveryType } from '@/types/domains'
import {
  EmailGatewayTooltip,
  HowToAllowlistingAnotherDomainWithTooltip,
  MailFlowRulesTooltip,
  StyledTooltipContent,
  TlsRequiredTooltip,
} from './AllowlistingTooltips'

const Allowlisting: FC = () => {
  const { t } = useTranslation()
  const [isNotConfiguredDomainsPopupOpen, setIsNotConfiguredDomainsPopupOpen] = useState(false)
  const [notConfiguredDomainsPopupMeta, setNotConfiguredDomainsPopupMeta] = useState({
    configuredDomain: '',
    totalDomains: 0,
    notConfiguredDomains: 0,
  })

  const initialValues = {
    _id: '',
    mailbox_type: '',
    message_delivery_type: '',
    mailbox_host: '',
    mailbox_port: '',
    tls_required: false,
    spam_filters: { email_gateway: false, mail_flow_rules: false },
    mailbox: '',
    verificationToken: '',
  }

  const validationSchema = Yup.object().shape({
    _id: Yup.string().required('Field required'),
    mailbox_type: Yup.string()
      .required('Field required')
      .oneOf([MailboxType.gmail, MailboxType.exchange_on_prem, MailboxType.office_365], 'Invalid value'),
    message_delivery_type: Yup.string()
      .required('Field required')
      .oneOf([MessageDeliveryType.sendgrid, MessageDeliveryType.bridged_delivery], 'Invalid value'),
    mailbox_host: Yup.string().when('message_delivery_type', {
      is: MessageDeliveryType.bridged_delivery,
      then: Yup.string().required('Field required').matches(DOMAIN_IP_REGEX, 'Invalid format'),
    }),
    mailbox_port: Yup.number().when('message_delivery_type', {
      is: MessageDeliveryType.bridged_delivery,
      then: Yup.number().typeError('Invalid format').required('Field required'),
    }),
    tls_required: Yup.boolean().when('message_delivery_type', {
      is: MessageDeliveryType.bridged_delivery,
      then: Yup.boolean(),
    }),
    spam_filters: Yup.object().shape({
      email_gateway: Yup.boolean(),
      mail_flow_rules: Yup.boolean(),
    }),
    mailbox: Yup.string().email('Invalid email').required('Field required'),
  })

  const { successToast, errorToast } = useToast()

  const { data: queryData } = useDomains({
    sort_by: DomainQuerySort.domain,
    sort_order: SortOrder.asc,
    skip: 0,
    limit: ALL_DATA_LIMIT.limit,
  })
  const { results: domains } = queryData || { results: [] }

  const { mutateAsync: updateDomain } = useUpdateDomain()
  const { mutateAsync: verifyDomainConfig } = useVerifyDomainConfig()

  const handleTest = async (values: typeof initialValues, { resetForm }: any) => {
    //TODO Improve this
    if (values.message_delivery_type === MessageDeliveryType.sendgrid) {
      delete values?.mailbox_port
      delete values?.mailbox_host
      delete values?.tls_required
    }

    try {
      await updateDomain(values)
      // setVerificationData({ domain_id: values._id, token: '', resetHandler: resetForm }) //Set domain id after successful submit
      successToast(t('onboarding.step3.messages.verificationSent'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('onboarding.step3.messages.verificationSentError'))
    }
  }

  const handleVerify = async (values: typeof initialValues, { resetForm }: any) => {
    if (values._id === '') {
      errorToast(t('onboarding.step3.messages.verificationMissingDomain'))
      return
    }

    const verificationData = { _id: values._id, token: values.verificationToken }

    try {
      await verifyDomainConfig(verificationData)
      successToast(t('onboarding.step3.messages.verificationSuccess'))

      // Reset form, domain id and handler
      resetForm()

      // Check if there is at least one domain that is not allowlisted
      const notConfiguredDomains =
        domains.length -
        domains.filter((d) => d.message_delivery_configurations[0]?.verified || d._id === values._id).length // Filler verified domain & current domain ot calculate not configured domains
      if (notConfiguredDomains) {
        setNotConfiguredDomainsPopupMeta({
          configuredDomain: domains.find((d) => d._id === verificationData._id)?.domain || '',
          totalDomains: domains.length,
          notConfiguredDomains: notConfiguredDomains,
        })
        setIsNotConfiguredDomainsPopupOpen(true)
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('onboarding.step3.messages.verificationError'))
    }
  }

  const handleDomainChange = (domain_id: string, setFieldValue: any, resetForm) => {
    const selectedDomain = domains.find((d: Domain) => d._id === domain_id)
    const domainConfig = selectedDomain?.message_delivery_configurations[0]

    resetForm()
    setFieldValue('_id', domain_id)
    setFieldValue('mailbox_type', domainConfig?.mailbox_type || '')
    setFieldValue('message_delivery_type', domainConfig?.message_delivery_type || '')
    setFieldValue('spam_filters.email_gateway', domainConfig?.spam_filters?.email_gateway || false)
    setFieldValue('spam_filters.mail_flow_rules', domainConfig?.spam_filters?.mail_flow_rules || false)
    setFieldValue('mailbox_host', domainConfig?.mailbox_host || '')
    setFieldValue('mailbox_port', domainConfig?.mailbox_port || '')
    setFieldValue('tls_required', domainConfig?.tls_required || false)
  }

  return (
    <Box>
      <NotConfiguredDomainsPopup
        open={isNotConfiguredDomainsPopupOpen}
        metaData={notConfiguredDomainsPopupMeta}
        onClose={() => setIsNotConfiguredDomainsPopupOpen(false)}
        onConfirm={() => {
          setIsNotConfiguredDomainsPopupOpen(false)
          handleContinue()
        }}
      />
      <Formik onSubmit={handleTest} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting, setFieldValue, values, resetForm }) => (
          <Form>
            <TwoColunmsLayout>
              <ColunmsItem>
                <FormSection>{t('onboarding.step3.descriptionTitle')}</FormSection>
                <Typography>
                  <Trans
                    i18nKey="onboarding.step3.descriptionLine1"
                    components={{
                      1: (
                        <Link
                          href="/settings/allowlisting"
                          style={{ color: theme.palette.blue[900] }}
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </Typography>
                <Typography>{t('onboarding.step3.descriptionLine2')}</Typography>
                <FormSection>{t('onboarding.step3.section2Title')}</FormSection>
                <Select
                  name="_id"
                  label={t('onboarding.step3.domain')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleDomainChange(e.target.value, setFieldValue, resetForm)
                  }
                  sx={{ minWidth: 4 }} // because of the Chips insider
                  options={domains.map((d) => ({
                    value: d._id,
                    label: (
                      <>
                        {d.domain}
                        {d.message_delivery_configurations[0]?.verified && (
                          <Chip size="small" label="Verified" color="success" variant="filter" />
                        )}
                      </>
                    ),
                  }))}
                />

                <RadioGroup
                  name="mailbox_type"
                  label={t('onboarding.step3.section2Title')}
                  options={[
                    {
                      value: MailboxType.gmail,
                      label: <RadioLabelWithIcon icon={GoogleWorkspace} label="Google Workspace" />,
                    },
                    {
                      value: MailboxType.exchange_on_prem,
                      label: <RadioLabelWithIcon icon={AdOnPremise} label="Exchange On premise" />,
                    },
                    {
                      value: MailboxType.office_365,
                      label: <RadioLabelWithIcon icon={Office365} label="Office 365" />,
                    },
                  ]}
                />

                <FormSection>{t('onboarding.step3.section3Title')}</FormSection>
                <Box display="flex" alignItems="center">
                  <Checkbox checked name="spam_filters.mail_flow_rules" label={t('onboarding.step3.mailFlowRules')} />
                  <MailFlowRulesTooltip />
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox checked name="spam_filters.email_gateway" label={t('onboarding.step3.emailGateway')} />
                  <EmailGatewayTooltip />
                </Box>

                <RadioGroup
                  name="message_delivery_type"
                  label={t('onboarding.step3.section4Title')}
                  onChange={(e) => {
                    if (e.target.value === MessageDeliveryType.sendgrid) {
                      setFieldValue('mailbox_host', '')
                      setFieldValue('mailbox_port', '')
                      setFieldValue('tls_required', false)
                    }
                  }}
                  options={[
                    {
                      value: MessageDeliveryType.sendgrid,
                      label: (
                        <RadioLabelWithTooltip
                          label={t('onboarding.step3.classicAllowlisting')}
                          tooltip={
                            <StyledTooltipContent>
                              <b>{t('onboarding.step3.classicAllowlistingTooltip.title')}</b>
                              <span>{t('onboarding.step3.classicAllowlistingTooltip.message')}</span>
                            </StyledTooltipContent>
                          }
                        />
                      ),
                    },
                    {
                      value: MessageDeliveryType.bridged_delivery,
                      label: (
                        <RadioLabelWithTooltip
                          label={t('onboarding.step3.bridgedDelivery')}
                          tooltip={
                            <StyledTooltipContent>
                              <b>{t('onboarding.step3.bridgedDeliveryTooltip.title')}</b>
                              <span>{t('onboarding.step3.bridgedDeliveryTooltip.message')}</span>
                            </StyledTooltipContent>
                          }
                          chip={t('onboarding.step3.bridgedDeliveryChip')}
                        />
                      ),
                    },
                  ]}
                />

                <Collapse in={values.message_delivery_type === MessageDeliveryType.sendgrid}>
                  <FormSection>{t('onboarding.step3.section41Title')}</FormSection>
                  <Typography>{t('onboarding.step3.section41Description')}</Typography>
                </Collapse>
                <Collapse in={values.message_delivery_type === MessageDeliveryType.bridged_delivery} sx={{ gap: 2 }}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <FormSection>{t('onboarding.step3.section42Title')}</FormSection>
                    <TextField name="mailbox_host" label={t('onboarding.step3.section42Host')} />
                    <TextField name="mailbox_port" label={t('onboarding.step3.section42Port')} />
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.tls_required}
                            onChange={(e) => {
                              setFieldValue('tls_required', e.target.checked)
                            }}
                          />
                        }
                        label={t('onboarding.step3.section42TLSRequired')}
                      />
                      <TlsRequiredTooltip />
                    </Box>
                  </Box>
                </Collapse>
              </ColunmsItem>

              <ColunmsItem>
                <OnboardingInstructions />
              </ColunmsItem>

              <ColunmsItem>
                <FormSection>{t('onboarding.step3.section5Title')}</FormSection>
                <TextField
                  name="mailbox"
                  label={t('onboarding.step3.section5Input')}
                  InputProps={{
                    endAdornment: (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Divider
                          sx={{
                            minHeight: 42,
                            alignSelf: 'center',
                            borderColor: theme.palette.grey[400],
                          }}
                          orientation="vertical"
                        />
                        <LoadingButton
                          loading={isSubmitting}
                          variant="text"
                          type="submit"
                          endIcon={<FontAwesomeIcon icon={faPaperPlane as IconProp} />}>
                          {t('onboarding.step3.section5Submit')}
                        </LoadingButton>
                      </Box>
                    ),
                  }}
                />
                <FormSection>{t('onboarding.step3.section6Title')}</FormSection>
                <TextField
                  name="verificationToken"
                  label={t('onboarding.step3.section6Input')}
                  InputProps={{
                    endAdornment: (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Divider
                          sx={{
                            minHeight: 42,
                            alignSelf: 'center',
                            borderColor: theme.palette.grey[400],
                          }}
                          orientation="vertical"
                        />
                        <LoadingButton
                          variant="text"
                          onClick={() => {
                            handleVerify(values, { resetForm })
                          }}
                          endIcon={<FontAwesomeIcon icon={faPaperPlane as IconProp} />}>
                          {t('onboarding.step3.section6Submit')}
                        </LoadingButton>
                      </Box>
                    ),
                  }}
                />
                <HowToAllowlistingAnotherDomainWithTooltip />
              </ColunmsItem>
              <ColunmsItem>
                <Box paddingTop={5}>
                  <Alert severity="info">
                    <AlertTitle>{t('onboarding.step3.verificationAlert.title')}</AlertTitle>
                    {t('onboarding.step3.verificationAlert.text')}
                  </Alert>
                </Box>
              </ColunmsItem>
            </TwoColunmsLayout>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

const RadioLabelWithIcon: FC<{ icon: string; label: string }> = ({ icon, label }) => (
  <Box display="flex" alignItems="center" gap={2}>
    <img src={icon} alt="label" width={theme.spacing(3)} height={theme.spacing(3)} />
    {label}
  </Box>
)

const RadioLabelWithTooltip: FC<{ label: string; tooltip: ReactElement; chip?: string }> = ({
  label,
  tooltip,
  chip,
}) => (
  <Box display="flex" alignItems="center" gap={2}>
    {label}
    <InfoTooltip title={tooltip} />
    {chip && <Chip size="small" label={chip} color="secondary" variant="filter" />}
  </Box>
)

const NotConfiguredDomainsPopup: FC<{ open: boolean; metaData: any; onClose: () => void; onConfirm: () => void }> = ({
  open,
  metaData,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <Popup
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      icon={domain}
      buttonLabels={{
        confirm: t('onboarding.step3.notConfiguredDomainsPopup.confirm'),
        cancel: t('onboarding.step3.notConfiguredDomainsPopup.cancel'),
      }}>
      <h4>{t('onboarding.step3.notConfiguredDomainsPopup.title')}</h4>
      <p>
        <Trans
          i18nKey="onboarding.step3.notConfiguredDomainsPopup.line1"
          values={{ configuredDomain: metaData.configuredDomain }}
          components={{ 1: <b /> }}
        />
      </p>
      <ol>
        <li>{t('onboarding.step3.notConfiguredDomainsPopup.title')}</li>
        <li>
          <Trans
            i18nKey="onboarding.step3.notConfiguredDomainsPopup.line3"
            values={{ totalDomains: metaData.totalDomains, notConfiguredDomains: metaData.notConfiguredDomains }}
            components={{ 1: <b /> }}
          />
        </li>
      </ol>
      <p>
        <b>{t('onboarding.step3.notConfiguredDomainsPopup.title2')}</b>
      </p>
      <ul>
        <li>{t('onboarding.step3.notConfiguredDomainsPopup.line4')}</li>
        <li>{t('onboarding.step3.notConfiguredDomainsPopup.line5')}</li>
      </ul>
    </Popup>
  )
}

const OnboardingInstructions: FC = () => {
  const { t } = useTranslation()
  const { values: formValues } = useFormikContext<DomainDeliveryConfiguration>()

  const IP_ADDRESSES = '149.72.52.80, 159.183.1.235, 159.183.22.243'
  const IP_ADDRESSES_BRIGED_DELIVERY = '18.157.126.54, 3.71.245.158, 52.59.114.139, 18.156.186.34'
  const DOMAINS = 'em4167.authenticationn.com, em1643.cywareness.io, authenticationn.com, cywareness.io'
  const URLS = 'phishtest.xyz, events.cywareness.io, authenticationn.com'

  const { successToast } = useToast()

  const [configurationsInstructions, setConfigurationsInstructions] = useState('')

  const { mutateAsync: sendInstructions } = useSendInstructions()

  const handleSendIstructions = async () => {
    try {
      await sendInstructions({ html_instructions: `<ul align="left">${configurationsInstructions}</ul>` })
      successToast(t('onboarding.step3.messages.instructionsSent'))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!formValues.message_delivery_type) {
      setConfigurationsInstructions('')
      return
    }

    if (formValues.mailbox_type === MailboxType.gmail) {
      // 3rd-party Gateway Filter
      if (formValues.spam_filters.email_gateway) {
        setConfigurationsInstructions(t('onboarding.step3.configurationMessages.gmailMailFlowFilters'))

        // No 3rd-party Gateway Filter
      } else {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.gmailNoMailFlowFilters', {
            ipAddresses: IP_ADDRESSES,
          })
        )
      }

      // and Bridged Delivery
      if (formValues.message_delivery_type === MessageDeliveryType.bridged_delivery) {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.gmailNoMailFlowFilters', {
            ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
          }) +
            t('onboarding.step3.configurationMessages.gmailBridgedDelivery', {
              ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            })
        )
      }
    } else if (formValues.mailbox_type === MailboxType.exchange_on_prem) {
      setConfigurationsInstructions(``)
    } else if (formValues.mailbox_type === MailboxType.office_365) {
      // No 3rd-party Gateway Filter - No Mail Flow 3rd-party Filter
      if ((formValues.spam_filters.email_gateway || formValues.spam_filters.mail_flow_rules) === false) {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.office365NoGatewayFilterNoMailFlow', {
            ipAddresses: IP_ADDRESSES,
            domains: DOMAINS,
            urls: URLS,
          }) +
            t('onboarding.step3.configurationMessages.office365NoGatewayFilterNoMailFlowBaypass', {
              ipAddresses: IP_ADDRESSES,
            })
        )
        // No 3rd-party Gateway Filter - Mail Flow 3rd-party Filter
      } else if (!!formValues.spam_filters.email_gateway === false && formValues.spam_filters.mail_flow_rules) {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.office365NoGatewayFilterMailFlow', {
            ipAddresses: IP_ADDRESSES,
            domains: DOMAINS,
            urls: URLS,
          }) +
            t('onboarding.step3.configurationMessages.office365NoGatewayFilterMailFlowBaypass', {
              ipAddresses: IP_ADDRESSES,
            })
        )
        // 3rd-party Filter - No Mail Flow 3rd-party Filter
      } else if (formValues.spam_filters.email_gateway && !!formValues.spam_filters.mail_flow_rules === false) {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.office365GatewayFilterNoMailFlow', { ipAddresses: IP_ADDRESSES }) +
            t('onboarding.step3.configurationMessages.office365GatewayFilterNoMailFlowBaypass', {
              ipAddresses: IP_ADDRESSES,
            })
        )

        // 3rd-party Filter - Mail Flow 3rd-party Filter
      } else {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.office365GatewayFilterMailFlow', { ipAddresses: IP_ADDRESSES }) +
            t('onboarding.step3.configurationMessages.office365GatewayFilterMailFlowBaypass', {
              ipAddresses: IP_ADDRESSES,
            })
        )
      }

      //Override instructions for Bridged Delivery
      //Bridged Delivery - Mail Flow 3rd-party Filter
      if (
        formValues.message_delivery_type === MessageDeliveryType.bridged_delivery &&
        formValues.spam_filters.mail_flow_rules
      ) {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.office365BridgedDeliveryMailFlow', {
            ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            domains: DOMAINS,
            urls: URLS,
          }) +
            t('onboarding.step3.configurationMessages.office365BridgedDeliveryMailFlowBaypass', {
              ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            }) +
            t('onboarding.step3.configurationMessages.office365BridgedDeliveryMailFlowConnector', {
              ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            })
        )
        //Bridged Delivery - No Mail Flow 3rd-party Filter
      } else if (
        formValues.message_delivery_type === MessageDeliveryType.bridged_delivery &&
        !!formValues.spam_filters.mail_flow_rules === false
      ) {
        setConfigurationsInstructions(
          t('onboarding.step3.configurationMessages.office365BridgedDeliveryNoMailFlow', {
            ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            domains: DOMAINS,
            urls: URLS,
          }) +
            t('onboarding.step3.configurationMessages.office365BridgedDeliveryNoMailFlowBaypass', {
              ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            }) +
            t('onboarding.step3.configurationMessages.office365BridgedDeliveryNoMailFlowConnector', {
              ipAddresses: IP_ADDRESSES_BRIGED_DELIVERY,
            })
        )
      }
    }
  }, [formValues])

  if (!configurationsInstructions) return null

  return (
    <InfoAlert
      severity="info"
      title={t('onboarding.step3.instructionsAlert.title')}
      customButtons={[
        {
          buttonText: t('onboarding.step3.instructionsAlert.button1'),
          onClick: handleSendIstructions,
        },
        // { buttonText: t('onboarding.step3.instructionsAlert.button2'), onClick: () => {} },
      ]}>
      <Typography dangerouslySetInnerHTML={{ __html: configurationsInstructions }} />
    </InfoAlert>
  )
}

export default Allowlisting
