export const ENV = import.meta.env.VITE_ENV
export const API_URL = import.meta.env.VITE_API_URL
export const EVENTS_API_URL = import.meta.env.VITE_EVENTS_API_URL
export const DASHBOARD_URL = import.meta.env.VITE_DASHBOARD_URL
export const CLOUDINARY_API_KEY = import.meta.env.VITE_CLOUDINARY_API_KEY
// export const OPENAI_API_KEY = import.meta.env.VITE_OPENAI_API_KEY
export const OPENAI_MODEL = import.meta.env.VITE_OPENAI_MODEL
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const HOTJAR_SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID
export const HOTJAR_VERSION = import.meta.env.VITE_HOTJAR_VERSION
export const RECAPTCHA_KEY = import.meta.env.VITE_RECAPTCHA_V3_KEY

export const NEW_LAUNCH_ANIMATION_DELAY_TIME = 30000 // 30 secconds
export const NEW_SAVE_ANIMATION_DELAY_TIME = 15000 // 15 secconds

export const AFTER_AI_SIMULATION_SAVE_ANIMATION_DELAY_TIME = 2900 // 2.9 second

export const LOWERCASE_REGEX = /[a-z]/
export const UPPERCASE_REGEX = /[A-Z]/
export const DIGITS_REGEX = /\d/
export const SPECIAL_CHARACTERS_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
export const HTML_CLEAN_REGEX = /<[^>]+>/
export const DOMAIN_IP_REGEX =
  /\b((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\b|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/
export const DOMAIN_REGEX =
  /((http?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const PHONE_NUMNER_REGEX = /^\+\d{7,17}$/
export const PHONE_NUMNER_E164_REGEX = /^\+\d{7,17}$/
export const VALID_CUSTOMIZE_MICRO_TRAINING_URL =
  /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/
export const ISO_DATE_TIME = 'YYYY-MM-DDTHH:mm'
export const ISO_DATE = 'YYYY-MM-DD'
export const US_DATE = 'MM-DD-YYYY'
export const SHORT_EU_DATE = 'DD/MM/YY'
export const US_DATE_WITH_MONTH_NAME = 'MMM DD, YYYY'
export const US_DATE_TIME_WITH_MONTH_NAME = 'MMM DD YYYY, HH:mm'
export const EU_DATE_TIME_12HR = 'DD/MM/YYYY hh:mm a'
export const EU_DATE_TIME_24HR = 'DD/MM/YYYY hh:mm'
export const MONTH_DAY = 'MMM DD'
export const HOUR_MINUTE_TIME = 'HH:mm'

export const ALL_DATA_LIMIT = { limit: 3000000 }
