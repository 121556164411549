import moment from 'moment'
import { FC, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

import { Activity } from '@/api/client-activity/client-activity'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import { Button, Card, Grid } from '@mui/material'
import ClientActivityTable from './ClientActivityTable'

const ClientActivityDashboard: FC = () => {
  const { t } = useTranslation()
  const [exportData, setExportData] = useState<Activity[]>([])

  const headers = [
    { label: t('clientActivityDashboard.action'), key: 'text' },
    { label: t('clientActivityDashboard.date'), key: 'date' },
  ]

  const parseData = (data: Activity[]) => {
    return data.map(({ text, timestamp }) => ({
      text,
      date: moment.unix(timestamp).format('DD/MM/YY HH:mm:ss'),
    }))
  }

  return (
    <LayoutPageContent>
      <PageHeader>
        <CSVLink
          filename={'Cywareness Activity Log'}
          data={parseData(exportData)}
          headers={headers}
          style={{ textDecoration: 'none' }}>
          <Button variant="outlined">{t('clientActivityDashboard.export')}</Button>
        </CSVLink>
      </PageHeader>
      <Grid item xs={12} xl={12}>
        <Card>
          <ClientActivityTable setExportData={setExportData} />
        </Card>
      </Grid>
    </LayoutPageContent>
  )
}

export default ClientActivityDashboard
