import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { string } from 'yup'

import { ALL_DATA_LIMIT, PHONE_NUMNER_REGEX } from '@/api/constants'
import { EditUserParams, useEditUser } from '@/api/users/edit-user'
import { NewUserParams, useNewUser } from '@/api/users/new-user'
import { User } from '@/api/users/users'
import { getErrorMessage } from '@/api/utils/get-error'
import useToast from '@/common/hooks/useToast'
import Form from '../Form/Form'
import useForm, { Field } from '../Form/useForm'
import { useOffices } from '@/api/offices/get'
import { useGroups } from '@/api/groups/get'

type CreateUserModalProps = {
  buttonClass?: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
  quoteErrorCallback?: () => void
  user?: User
}

const CreateUserModal: FC<CreateUserModalProps> = ({
  open,
  setOpen,
  user,
  onClose = () => {},
  quoteErrorCallback = () => {},
}) => {
  const { data: officesData, refetch } = useOffices(ALL_DATA_LIMIT)
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)

  const offices = officesData?.results || []
  const groups = groupsData?.results || []
  const { t } = useTranslation()
  const [createNewUser, status] = useNewUser()
  const [editUser] = useEditUser()

  const { errorToast, successToast } = useToast()

  const fields: Field[] = useMemo(
    () => [
      {
        name: 'first_name',
        initialValue: user?.first_name ?? '',
        label: 'createUserModal.firstName',
        validationRule: string().required('createUserModal.errors.missingFirstName'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'last_name',
        initialValue: user?.last_name ?? '',
        label: 'createUserModal.lastName',
        type: 'text',
        required: false,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'username',
        initialValue: user?.username ?? '',
        label: 'createUserModal.email',
        validationRule: string()
          .required('createUserModal.errors.missingEmail')
          .email('createUserModal.errors.invalidEmail'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'phone_number',
        initialValue: user?.phone_number ?? '',
        label: 'createUserModal.phone',
        placeholder: '+12345678901',
        validationRule: string().matches(PHONE_NUMNER_REGEX, {
          message: 'createUserModal.errors.invalidPhone',
          excludeEmptyString: true,
        }),
        type: 'text',
        required: false,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'office_id',
        initialValue: user?.office_id ?? '',
        label: 'createUserModal.office',
        validationRule: string().required('createUserModal.errors.missingOffice'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: offices.map((office) => ({
          label: office.name,
          value: office._id,
        })),
      },
      {
        name: 'member_of',
        initialValue: user?.member_of ?? [],
        label: groups.length > 0 ? 'createUserModal.groups' : 'createUserModal.noGroups',
        validationRule: string(),
        type: 'text',
        required: false,
        autoFocus: false,
        disabled: () => groups.length === 0,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
          SelectProps: {
            multiple: groups.length > 1,
          },
        },
        options: groups.map((group) => ({
          label: group.name,
          value: group._id,
        })),
      },
    ],
    [user, offices, groups]
  )

  function handleClose() {
    onClose()
    setOpen(false)
    clearForm()
  }

  const { formProps, handleSubmit, clearForm } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: false,
  })

  async function handleEditUser(params: EditUserParams) {
    try {
      await editUser(params)
      successToast(t('simulatorOnboarding.users.userEdited'))
      handleClose()
    } catch (e) {
      const errorMessage = getErrorMessage(e) ?? t('simulatorOnboarding.users.errors.faliedEditUser')

      errorToast(errorMessage)
    }
  }

  async function handleCreateUser(values: NewUserParams) {
    try {
      await createNewUser([values])
      successToast(t('users.newUserSuccess'))
      handleClose()
    } catch (e: any) {
      if (e.response.data.status_code === 115) {
        handleClose()
        quoteErrorCallback()
      } else {
        const errorMessage = getErrorMessage(e) ?? t('users.errors.faliedNewUser')
        errorToast(errorMessage)
      }
    }
  }

  function handleSumbit(values) {
    user ? handleEditUser(values) : handleCreateUser(values)
  }

  return (
    <>
      <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="user-dialog-title">
        <DialogTitle id="user-dialog-title">
          {user ? t('createUserModal.editUser') : t('createUserModal.newUser')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('createUserModal.instructions')}</DialogContentText>
          <Form {...formProps} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('createUserModal.cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={status.isLoading}>
            {t('createUserModal.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateUserModal
