export const MuiCard = {
  styleOverrides: {
    root: {
      border: 'none',
      padding: '16px 32px',
      borderRadius: '10px',
      height: '100%',
    },
  },
}
