import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import { theme } from '@/theme/theme'

export const EmailGatewayTooltip = () => {
  const { t } = useTranslation()
  return (
    <InfoTooltip
      title={
        <StyledTooltipContent>
          <b>{t('onboarding.step3.mailFlowRulesTooltip.title')}</b>
          <span>{t('onboarding.step3.mailFlowRulesTooltip.message')}</span>
        </StyledTooltipContent>
      }
    />
  )
}

export const MailFlowRulesTooltip = () => {
  const { t } = useTranslation()
  return (
    <InfoTooltip
      title={
        <StyledTooltipContent>
          <b>{t('onboarding.step3.emailGatewayTooltip.title')}</b>
          <span>{t('onboarding.step3.emailGatewayTooltip.message')}</span>
        </StyledTooltipContent>
      }
    />
  )
}

export const TlsRequiredTooltip = () => {
  const { t } = useTranslation()
  return (
    <InfoTooltip
      title={
        <StyledTooltipContent>
          <b>{t('onboarding.step3.tlsRequiredTooltip.title')}</b>
          <span>{t('onboarding.step3.tlsRequiredTooltip.message')}</span>
        </StyledTooltipContent>
      }
    />
  )
}

export const HowToAllowlistingAnotherDomainWithTooltip = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography color={theme.palette.grey[700]}>{t('onboarding.step3.HowToAllowlistingAnotherDomain')}</Typography>
      <InfoTooltip
        placement="top-start"
        title={
          <StyledTooltipContent>
            <b>{t('onboarding.step3.anotherDomainTooltip.title')}</b>
            <ul style={{ paddingLeft: theme.spacing(2) }}>
              <li>{t('onboarding.step3.anotherDomainTooltip.bullet1')}</li>
              <br />
              <li>{t('onboarding.step3.anotherDomainTooltip.bullet2')}</li>
            </ul>
          </StyledTooltipContent>
        }
      />
    </Box>
  )
}

export const StyledTooltipContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '152px',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))
