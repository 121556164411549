import { useState, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import VideoPageActions from './VideoPageActions'
import { EducationModule } from '@/api/education-modules/modules'
import { useDeleteEducationModule } from '@/api/education-modules/delete-module'
import { useAuth } from '@/context/Auth'
import { useToggleSavedContent } from '@/api/profile/toggle-saved-asset'
import useToast from '@/common/hooks/useToast'
import EmbeddedContent from '@/common/components/EmbeddedContent/EmbeddedContent'
import VideoPageQuizzesLink from './VideoPageQuizzesLink'
import VideoPageLessonPlanLink from './VideoPageContentLink'
import VideoPageTopics from './VideoPageTopics'
import { VideoDetails } from './VideoPage'
import Alert from '@/common/components/Alert/Alert'

const VideoPageDetails: FC<VideoDetails> = ({ video }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigateTo = useNavigate()
  const { user: { saved_assets = [] } = {} } = useAuth()

  const [deleteEducationModule] = useDeleteEducationModule()
  const [saveAsset] = useToggleSavedContent()
  const { errorToast, successToast } = useToast()

  const [moduleForDeletion, setModuleForDeletion] = useState<EducationModule | null>(null)

  const [deleteVideoAlertIsOpen, setDeleteVideoAlertIsOpen] = useState(false)

  function openDeleteVideoAlert(video: EducationModule) {
    setModuleForDeletion(video)
    setDeleteVideoAlertIsOpen(true)
  }

  function closeDeleteVideoAlert() {
    setModuleForDeletion(null)
    setDeleteVideoAlertIsOpen(false)
  }

  async function handleDeleteModule() {
    if (!moduleForDeletion) return
    try {
      await deleteEducationModule(moduleForDeletion._id)
      successToast(t('videoLibrary.deleted'))

      // navigate back to content library after successful deletion
      navigateTo('/content-library')
    } catch (error) {
      errorToast(t('videoLibrary.errors.failedToDeleteModule'))
      console.log(error)
    }
  }

  async function handleEditModule() {
    navigateTo(`/content-library/videos/${video._id}/edit`)
  }

  async function handleSaveModule(module: EducationModule) {
    try {
      await saveAsset(module._id)
    } catch (error) {
      errorToast(t('videoLibrary.errors.failedToSaveModule'))
      console.log(error)
    }
  }

  if (!video) {
    return <></>
  }

  const languagesToShow = video.languages

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <Typography variant="h6">{video.title}</Typography>
        <VideoPageActions
          deleteModule={openDeleteVideoAlert}
          editModule={handleEditModule}
          saveModule={handleSaveModule}
          isSaved={saved_assets?.includes(video._id)}
          video={video}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginRight: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}>
        <Typography>
          <b>Dubbing:</b> {languagesToShow.join(', ').toUpperCase()}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <VideoPageTopics video={video} />
        <VideoPageQuizzesLink video={video} />
        <VideoPageLessonPlanLink video={video} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme.spacing(2),
        }}>
        <Typography fontWeight={theme.typography.fontWeightBold}>{t('videoLibrary.summary')}</Typography>
        <Typography variant="body2">{video.description}</Typography>
        {video?.additional_resources?.embedded_content ? (
          <EmbeddedContent video={video.additional_resources.embedded_content} />
        ) : (
          <></>
        )}
      </div>
      <Alert
        handleClose={closeDeleteVideoAlert}
        open={deleteVideoAlertIsOpen}
        onConfirm={handleDeleteModule}
        cancelButton
        buttonLabel={t('videoLibrary.deleteAlert.delete')}
        dialogTitle={t('videoLibrary.deleteAlert.title')}
        dialogText={t('videoLibrary.deleteAlert.subtitle')}
        dialogContent={
          <Typography style={{ fontWeight: 'bold' }}>
            {t('videoLibrary.deleteAlert.message', {
              value: moduleForDeletion?.title,
            })}
          </Typography>
        }
      />
    </Box>
  )
}

export default VideoPageDetails
